import React, { useEffect } from 'react';
import './ViewCreatedThingsPreBriefing.css';
import { fetchThingsByThingType } from '../../../../../../api/things/thingsService';
import AddThingItemPreBriefing from './AddThingItemPreBriefing/AddThingItemPreBriefing';
import CreatedThingItemPreBriefing from './CreatedThingItemPreBriefing/CreatedThingItemPreBriefing';
import { ThingType } from '../../../../../../models/ThingType';

interface ViewCreatedThingsPreBriefingProps {
    thingtype: ThingType;
    onAddThingClick: () => void;
    onPlanThingClick: (thing: any) => void;
    refreshKey: number;
    things: any[];
    setThings: React.Dispatch<React.SetStateAction<any[]>>;
}

const ViewCreatedThingsPreBriefing: React.FC<ViewCreatedThingsPreBriefingProps> = ({ thingtype, onAddThingClick, refreshKey, onPlanThingClick, things, setThings }) => {
    

    return (
        <div id="view-created-things-container">
            <ul className="created-things-list">
                <AddThingItemPreBriefing thingtype={thingtype} onAddThingClick={onAddThingClick}/>
                {things.map((thing, index) => (<CreatedThingItemPreBriefing key={index} thing={thing} thingtype={thingtype} onPlanThingClick={onPlanThingClick} />))}
            </ul>
        </div>
    );
};

export default ViewCreatedThingsPreBriefing;