import api from "../auth/api";

export const createMollieCustomer = async (customerData: any) => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    try {
        const url = `/mollie_customers/`;
        const response = await api.post(url, customerData, config);
        return response.data;
    } catch (error) {
        console.error('Failed to create customer:', error);
        throw error;
    }
};

export const fetchCustomerByParentCompany = async (parentCompany: string) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/mollie_customers/get_mollie_customer_by_parent_company/${parentCompany}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
    }
};