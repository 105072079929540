import React, { useEffect, useRef } from 'react';
import PageLayout from '../../components/Site/PageLayout/PageLayout';
import heroimg from '../../assets/img/website-hero-img.png';
import './HomeView.css';
import ParralaxScrollView from './ParallaxScroll/ParallaxScroll';
import { motion, MotionValue, useAnimation, useScroll, useSpring, useTransform } from 'framer-motion';
import ContactView from '../ContactView/ContactView';
import appdashboard from '../../assets/img/tilted-left-10things.png';
import hoppelogo from '../../assets/img/hoppe-logo.png';
import pieperlogo from '../../assets/img/partners/pieper-logo.png';
import bagelsbeanslogo from '../../assets/img/partners/bagels&beanslogo.png';
import wouterlogo from '../../assets/img/partners/Wouter-Verkerk-logo-blauw.png';
import proeflokaal from '../../assets/img/partners/proeflokaal-logo-tekst-2023.png';
import focusImg from '../../assets/img/focus-op-10-dingen-per-dag.png';
import featureThings from '../../assets/img/10-things-feature-things.png';
import featureRapport from '../../assets/img/10-things-feature-rapport.png';
import featureDashboard from '../../assets/img/10-things-feature-dashboard.png';
import featureRandomize from '../../assets/img/10-things-feature-randomize.png';
import { Tab, Tabs } from '@nextui-org/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { Helmet } from 'react-helmet-async';
import BriefingSection from '../../components/Site/HomeView/BriefingSection/BriefingSection';
import canvas from '../../assets/img/imac2d.png';
import video from '../../assets/videos/pre-briefing.mp4';
import homeimghero from '../../assets/img/home-img-hero.png';
import GoogleAnalytics from "react-ga4";
import usePageTracking from '../../utils/usePageTracking';
import { fetchProductsWebsite } from '../../api/products/productsService';
import { Product } from '../../models/Product';
import { ProductType } from '../../enums/ProductType';
import CreditConverter from '../../components/Site/HomeView/CreditConverter/CreditConverter';

function useParallax(value: MotionValue<number>, distance: number) {
    return useTransform(value, [0, 1], [-distance, distance]);
}

function convertToEuro(price: number) {
    return price.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' });
}

const Section = ({ id, className, children }: { id: string, className?: string, children: React.ReactNode }) => {
    const controls = useAnimation();

    useEffect(() => {
        controls.start('visible');
    }, [controls]);

    return (
        <motion.div
            id={id}
            className={`section ${className}`}
            initial="hidden"
            animate={controls}
            variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0, transition: { duration: 2 } },
            }}
        >
            {children}
        </motion.div>
    );
};

function ScrollToHashElement() {
    const { hash } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                element.scrollIntoView();
                // Remove the hash from the URL
                navigate(window.location.pathname + window.location.search, { replace: true });
            }
        }
    }, [hash, navigate]);

    return null;
}

const HomeView: React.FC = () => {
    usePageTracking();

    const { width } = useWindowSize();
    const isMobile = width <= 768;


    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                delayChildren: 0.4,
                staggerChildren: 0.4
            }
        }
    };

    const stackContainer = {
        hidden: { opacity: 0, scale: 0.2 },
        show: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.4,
                staggerChildren: 0.4
            }
        }
    }

    const textchild = {
        hidden: { y: 100, opacity: 0 },
        show: { y: 0, opacity: 1, duration: 1, transition: { ease: "backInOut", when: "beforeChildren", } },
    };

    const heroImg = {
        hidden: { opacity: 0, scale: 0.2 },
        show: { opacity: 1, scale: 1 },
    };

    const heroImgMobile = {
        hidden: { opacity: 0, scale: 0.2 },
        show: { opacity: 1, scale: 1 },
    }

    const stackImg = {
        hidden: { opacity: 0, scale: 0.2 },
        show: { opacity: 1, scale: 1 },
    };

    const thingCard = {
        hidden: { opacity: 0, scale: 0.2 },
        show: { opacity: 1, scale: 1 },
    };

    const featureImg = {
        hidden: { opacity: 0, scale: 0.2 },
        show: { opacity: 1, scale: 1 },
    };

    const focusImgEff = {
        hidden: { opacity: 0, scale: 0.2 },
        show: { opacity: 1, scale: 1 },
    };

    const [subscriptionType, setSubscriptionType] = React.useState('10TH1M');
    const [subscriptionTypeTab, setSubscriptionTypeTab] = React.useState('per maand');

    const changeSubscriptionType = (type: string) => {
        console.log('Type:', type);
        setSubscriptionType(type);
        // setPrice(type === '10TH1M' ? 39.99 : 399.99);
        setActiveProduct(products.find(product => product.product_identifier === type) || {} as Product);
        setSubscriptionTypeTab(type === '10TH1M' ? 'per maand' : 'per jaar');
    };

    const [products, setProducts] = React.useState<Product[]>([]);
    const [activeProduct, setActiveProduct] = React.useState<Product>();
    const [creditProduct, setCreditProduct] = React.useState<Product>();

    useEffect(() => {
        const loadProducts = async () => {
            try {
                const response = await fetchProductsWebsite();
                console.log('Products:', response);
                setProducts(response);
                handleSetActiveProduct();
            } catch (error) {
                console.error('Failed to fetch products:', error);
            }
        }

        loadProducts();
    }, []);

    useEffect(() => {
        if (products.length > 0) {
            handleSetActiveProduct();
            handleSetCreditProduct();
        }
    }, [products]);

    const handleSetActiveProduct = () => {
        console.log('Subscription type:', subscriptionType);
        const foundProduct = products.find(product => product.product_identifier === subscriptionType);
        console.log('Found product:', foundProduct);
        setActiveProduct(foundProduct);
    };

    const handleSetCreditProduct = () => {
        const foundProduct = products.find(product => product.product_type === ProductType.PRODUCT);
        setCreditProduct(foundProduct);
    };


    // const [price, setPrice] = React.useState(subscriptionPrice);
    // const [priceInEuro, setPriceInEuro] = React.useState('39,99');

    // useEffect(() => {
    // setPriceInEuro();
    // }, [price]);

    const handleTrailButtonClick = () => {
        GoogleAnalytics.event({
            category: 'User',
            action: 'Clicked Button',
            label: 'Start trail button',
        });
    }


    return (

        <PageLayout>
            <ScrollToHashElement />
            <Helmet>
                <title>10-Things | Dé briefing app voor de horeca | Home</title>
            </Helmet>
            <section className="home-hero-section">
                <motion.div layout className="home-hero-container" variants={container} initial="hidden" whileInView="show" viewport={{ once: true }}>
                    {/* <motion.img src={heroimg} alt="10-Things platforms" className="home-hero-img-mobile" variants={heroImgMobile} /> */}
                    <div className="home-hero-text-sections-area">
                        <div className="home-hero-top-section">
                            <div className="home-hero-left-text-section">
                                <div className="home-hero-left-inner-text-container">
                                    <motion.h1 className="home-hero-title-h1" variants={textchild}>
                                        Dé briefing app voor de horeca
                                    </motion.h1>
                                    <motion.p className="home-hero-txt-p" variants={textchild}> Alles wat je nodig hebt voor het geven van je dagelijkse briefing. </motion.p>
                                    <motion.p className="home-hero-txt-p" variants={textchild}>
                                        Gebaseerd op de methodiek van <a className="link-to-wouter" href="https://wouterverkerk.nl/" target="_blank" rel="noopener noreferrer">Wouter Verkerk</a>.
                                    </motion.p>
                                    <Link to="/register" className='home-hero-cta-link' onClick={handleTrailButtonClick}>
                                        <motion.button className="home-hero-cta-btn" type='button' variants={textchild}>
                                            Probeer het gratis!
                                        </motion.button>
                                    </Link>
                                </div>
                            </div>
                            <div className="home-hero-model-area">
                                <div className="home-hero-model-wrapper">
                                    <div className="home-hero-display-mac">
                                        {/* <video autoPlay loop muted className="home-hero-display-mac-video">
                                            <source src={video} type="video/mp4" />
                                        </video> */}
                                        <img src={homeimghero} className="website-hero-img.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </section>

            <section className="review-section">
                <motion.h1 className="home-partners-title-h1" variants={textchild}>Wat zeggen onze klanten: </motion.h1>
                <div className="review-inner-div">
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        autoplay={{ delay: 5000 }}
                        loop={true}
                        modules={[Autoplay]}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper: any) => console.log(swiper)}
                    >
                        <SwiperSlide>
                            <div className="review-item-div">
                                <div className="left-quarter">
                                    {/* <img src="path-to-logo.png" alt="Company Logo"> </img>  */}
                                </div>
                                <div className="review-text-div">
                                    <p>“De totaal vernieuwde oplossing van 10-Things maakt het briefen en evalueren van een shift kinderlijk eenvoudig én zorgt voor de documentatie van de dagelijkse processen!”</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="review-item-div">
                                <div className="left-quarter">
                                    {/* <img src="path-to-logo.png" alt="Company Logo"> </img>  */}
                                </div>
                                <div className="review-text-div">
                                    <p>“Wij zijn zeer tevreden met de briefings die wij sinds april houden met de 10-Things app! Onze tablet hangt zichtbaar in de keuken zodat het hele team gedurende de dag weet wat het plan van de dag is.”</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="review-item-div">
                                <div className="left-quarter">
                                    {/* <img src="path-to-logo.png" alt="Company Logo"> </img>  */}
                                </div>
                                <div className="review-text-div">
                                    <p>“Door 10-Things weet ons hele team waar we die dag op focussen. Omdat we twee shifts per dag draaien weet de avond ploeg precies welke bijzonderheden er in de ochtend waren.”</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="review-item-div">
                                <div className="left-quarter">
                                    {/* <img src="path-to-logo.png" alt="Company Logo"> </img>  */}
                                </div>
                                <div className="review-text-div">
                                    <p>“Doordat wij meerdere locaties hebben door heel Nederland is het belangrijk dat we vanaf een punt ons concept bewaken. Dit kan perfect door dagelijks 10 focuspunten per vestiging door te voeren!”</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <motion.section className="home-partners-section" variants={container} initial='hidden' whileInView='show' viewport={{ once: true }}>

                <div className="home-partners-inner-div">
                    <motion.div className="home-partner-item-div" variants={textchild}>
                        <img src={wouterlogo} alt="Wouter Verkerk" className="home-partner-item-img" />
                    </motion.div>
                    <motion.div className="home-partner-item-div" variants={textchild}>
                        <img src={hoppelogo} alt="Hoppe" className="home-partner-item-img" />
                    </motion.div>
                    <motion.div className="home-partner-item-div" variants={textchild}>
                        <img src={proeflokaal} alt="Proeflokaal" className="home-partner-item-img" />
                    </motion.div>
                    <motion.div className="home-partner-item-div" variants={textchild}>
                        <img src={pieperlogo} alt="Pieper" className="home-partner-item-img" />
                    </motion.div>
                    <motion.div className="home-partner-item-div" variants={textchild}>
                        <img src={bagelsbeanslogo} alt="Bagels & Beans" className="home-partner-item-img" />
                    </motion.div>
                </div>
            </motion.section>

            <motion.section className="home-10things-section" variants={container} initial="hidden" whileInView="show" viewport={{ once: true }}>
                <motion.div >
                    {/* TODO plaatje met: Met 10-Things stel je eenvoudig het plan van de dag samen!  */}
                    <motion.img src={focusImg} alt="Focus op 10 dingen per dag" className="home-10things-focus-img" variants={focusImgEff} />

                    {/* <motion.p className="home-10things-txt-p" variants={textchild}>Begin iedere dag met een 10-Things briefing en eindig met een evaluatierapport in je mail.</motion.p> */}
                </motion.div>
                <motion.div className="home-10things-content-div">
                    <ul className="home-10things-content-listing-ul">
                        <motion.li className="home-10things-content-item-li" variants={focusImgEff}>
                            <motion.img src={featureThings} alt="Feature Things" className="home-10things-icon-img" />
                            <motion.h5 className="home-10things-title-h5">Focus op 10 dingen per dag</motion.h5>
                            <motion.p className="home-10things-inner-txt-p">Bepaal eenvoudig je belangrijkste focuspunten voor vandaag</motion.p>
                        </motion.li>

                        <motion.li className="home-10things-content-item-li" variants={focusImgEff}>
                            <motion.img src={featureRandomize} alt="Feature Inspireren" className="home-10things-icon-img" />
                            <motion.h5 className="home-10things-title-h5">Laat je inspireren</motion.h5>
                            <motion.p className="home-10things-inner-txt-p">Krijg toegang tot 500+ focuspunten van Wouter Verkerk </motion.p>
                        </motion.li>

                        <motion.li className="home-10things-content-item-li" variants={focusImgEff}>
                            <motion.img src={featureDashboard} alt="Feature Dashboard" className="home-10things-icon-img" />
                            <motion.h5 className="home-10things-title-h5">Overzichtelijk dashboard</motion.h5>
                            <motion.p className="home-10things-inner-txt-p">Plaats het dashboard op een zichtbare plek, zoals in de keuken of achter de bar </motion.p>
                        </motion.li>

                        <motion.li className="home-10things-content-item-li" variants={focusImgEff}>
                            <motion.img src={featureRapport} alt="Feature Rapport" className="home-10things-icon-img" />
                            <motion.h5 className="home-10things-title-h5">Dagelijkse rapportage</motion.h5>
                            <motion.p className="home-10things-inner-txt-p">Alle evaluaties samen in één dagrapport, rechtstreeks in je e-mail! </motion.p>
                        </motion.li>
                    </ul>
                </motion.div>
            </motion.section>
            <section className="home-briefing-section">

                <BriefingSection />

            </section>
            <section className="home-things-section" id='things'>


                <div className="home-things-stack-container">
                    <div className="home-things-title-container">
                        <motion.h1 className="home-things-title-h1" variants={textchild} initial='hidden' whileInView='show' viewport={{ once: true }}>De 5 categorieën van 10-Things </motion.h1>
                    </div>
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper: any) => console.log(swiper)}
                        autoplay={{ delay: 10000 }}
                        loop={true}
                        modules={[Autoplay, Pagination]}
                        pagination={{
                            clickable: true,
                        }}
                    >
                        <SwiperSlide>
                            {/* TODO insert voorbeeld things per caregorie  */}
                            <motion.div className="home-things-stack-card-inner-area signature" variants={stackContainer} initial='hidden' whileInView='show'>
                                <motion.h1 className="home-things-stack-card-inner-title-h1" variants={textchild} > Signaturen</motion.h1>
                                <div className="home-things-stack-card-container">
                                    <div className="home-things-stack-card-inner-thing-area">
                                        <motion.div className="home-things-thing-div signture-card" variants={thingCard}>
                                            <div className="home-things-thing-div-title-area">
                                                <div className="home-things-thing-div-fake-title-div">

                                                </div>
                                            </div>
                                            <div className="home-things-thing-div-txt-area">
                                                <div className="home-things-thing-div-fake-txt-long-div">

                                                </div>
                                                <div className="home-things-thing-div-fake-txt-short-div"></div>
                                            </div>
                                        </motion.div>
                                    </div>
                                    <div className="home-things-stack-card-inner-text-area right">
                                        <motion.p className="home-things-stack-card-txt-p" variants={textchild}>Een “signatuur” in de horeca is een unieke en kenmerkende service of element dat de gastervaring verrijkt, waardoor de zaak zich onderscheidt. Deze bijzondere kenmerken zijn de redenen die gasten aanzetten om terug te keren.</motion.p>
                                    </div>
                                </div>
                            </motion.div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <motion.div className="home-things-stack-card-inner-area suggestion" variants={stackContainer} initial='hidden' whileInView='show'>
                                <motion.h1 className="home-things-stack-card-inner-title-h1" variants={textchild}>Suggesties</motion.h1>
                                <div className="home-things-stack-card-container">
                                    <div className="home-things-stack-card-inner-text-area left">
                                        <motion.p className="home-things-stack-card-txt-p" variants={textchild}>Een “suggestie” is een tactiek waarbij teamleden specifieke items uit het menu actief aanbevelen aan gasten, gericht op het bevorderen van verkoop van bepaalde items of het verrijken van de gastbeleving.</motion.p>
                                    </div>
                                    <div className="home-things-stack-card-inner-thing-area">
                                        <motion.div className="home-things-thing-div suggestion-card" variants={thingCard}>
                                            <div className="home-things-thing-div-title-area">
                                                <div className="home-things-thing-div-fake-title-div">

                                                </div>
                                            </div>
                                            <div className="home-things-thing-div-txt-area">
                                                <div className="home-things-thing-div-fake-txt-long-div">

                                                </div>
                                                <div className="home-things-thing-div-fake-txt-short-div"></div>
                                            </div>
                                        </motion.div>
                                    </div>

                                </div>
                            </motion.div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <motion.div className="home-things-stack-card-inner-area task" variants={stackContainer} initial='hidden' whileInView='show'>
                                <motion.h1 className="home-things-stack-card-inner-title-h1" variants={textchild}>Taken</motion.h1>
                                <div className="home-things-stack-card-container">
                                    <div className="home-things-stack-card-inner-thing-area">
                                        <motion.div className="home-things-thing-div task-card" variants={thingCard}>
                                            <div className="home-things-thing-div-title-area">
                                                <div className="home-things-thing-div-fake-title-div">

                                                </div>
                                            </div>
                                            <div className="home-things-thing-div-txt-area">
                                                <div className="home-things-thing-div-fake-txt-long-div">

                                                </div>
                                                <div className="home-things-thing-div-fake-txt-short-div"></div>
                                            </div>
                                        </motion.div>
                                    </div>
                                    <div className="home-things-stack-card-inner-text-area right">
                                        <motion.p className="home-things-stack-card-txt-p" variants={textchild}>Een "taak" binnen 10-Things verwijst naar actiepunten voor teamleden om zelf actie te ondernemen op belangrijke punten in de bedrijfsvoering, zonder deze via algemene groepscommunicatie te hoeven delen.</motion.p>
                                    </div>
                                </div>
                            </motion.div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <motion.div className="home-things-stack-card-inner-area process" variants={stackContainer} initial='hidden' whileInView='show'>
                                <motion.h1 className="home-things-stack-card-inner-title-h1" variants={textchild}>Processen</motion.h1>
                                <div className="home-things-stack-card-container">
                                    <div className="home-things-stack-card-inner-text-area left">
                                        <motion.p className="home-things-stack-card-txt-p" variants={textchild}>Een “proces” binnen 10-Things dient als een tool voor het vastleggen en herhalen van alle werkafspraken zoals vakkundigheid en upselling strategieën ter verbetering van de efficiëntie.</motion.p>
                                    </div>
                                    <div className="home-things-stack-card-inner-thing-area">
                                        <motion.div className="home-things-thing-div process-card" variants={thingCard}>
                                            <div className="home-things-thing-div-title-area">
                                                <div className="home-things-thing-div-fake-title-div">

                                                </div>
                                            </div>
                                            <div className="home-things-thing-div-txt-area">
                                                <div className="home-things-thing-div-fake-txt-long-div">

                                                </div>
                                                <div className="home-things-thing-div-fake-txt-short-div"></div>
                                            </div>
                                        </motion.div>
                                    </div>
                                </div>
                            </motion.div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <motion.div className="home-things-stack-card-inner-area thing" variants={stackContainer} initial='hidden' whileInView='show'>
                                <motion.h1 className="home-things-stack-card-inner-title-h1" variants={textchild}>Things</motion.h1>
                                <div className="home-things-stack-card-container">
                                    <div className="home-things-stack-card-inner-thing-area">
                                        <motion.div className="home-things-thing-div thing-card" variants={thingCard}>
                                            <div className="home-things-thing-div-title-area">
                                                <div className="home-things-thing-div-fake-title-div">

                                                </div>
                                            </div>
                                            <div className="home-things-thing-div-txt-area">
                                                <div className="home-things-thing-div-fake-txt-long-div">

                                                </div>
                                                <div className="home-things-thing-div-fake-txt-short-div"></div>
                                            </div>
                                        </motion.div>
                                    </div>
                                    <div className="home-things-stack-card-inner-text-area right">
                                        <motion.p className="home-things-stack-card-txt-p" variants={textchild}>Een “thing” in 10-Things is een categorie voor zaken die regelmatig aandacht vereisen zoals sfeer, persoonlijke hygiëne, werkafspraken, en gedragskwesties, om een positieve en professionele werkomgeving te handhaven.</motion.p>
                                    </div>
                                </div>
                            </motion.div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            <section className="home-features-section">
                <div className="inner-home-features-area">
                    <motion.h1 className="home-features-title-h1" variants={textchild} initial='hidden' whileInView='show' viewport={{ once: true }}>Features</motion.h1>
                    <motion.div className="inner-home-features-text-area" variants={container} initial='hidden' whileInView='show' viewport={{ once: true }}>
                        <div className="inner-home-feature-bar-area">
                            <motion.h2 className="inner-home-feature-title-h2" variants={textchild}>500+ focuspunten</motion.h2>
                            <motion.p className="inner-home-feature-txt-p" variants={textchild}>Laat je inspireren door 500+ focuspunten van Wouter Verkerk</motion.p>
                        </div>
                        <div className="inner-home-feature-bar-area">
                            <motion.h2 className="inner-home-feature-title-h2" variants={textchild}>150+ events</motion.h2>
                            <motion.p className="inner-home-feature-txt-p" variants={textchild}>Events in jouw horecakalendar om op in te haken</motion.p>
                        </div>
                        <div className="inner-home-feature-bar-area">
                            <motion.h2 className="inner-home-feature-title-h2" variants={textchild}>1-4 shifts</motion.h2>
                            <motion.p className="inner-home-feature-txt-p" variants={textchild}>Geef dagelijks meerdere briefings: Ontbijt, Lunch, Diner en Nacht </motion.p>
                        </div>
                        <div className="inner-home-feature-bar-area">
                            <motion.h2 className="inner-home-feature-title-h2" variants={textchild}>Rapportage</motion.h2>
                            <motion.p className="inner-home-feature-txt-p" variants={textchild}>Met 10-Things ontvang je elke dag alle evaluatierapporten gedigitaliseerd en gedocumenteerd in je email</motion.p>
                        </div>
                    </motion.div>
                    <motion.div className="inner-home-features-mockup-area" variants={container} initial='hidden' whileInView='show' viewport={{ once: true }}>
                        {/* TODO nieuw plaatje drie mockups */}
                        <motion.img src={appdashboard} alt="10-Things Dashboard" className="inner-home-features-mockup-img" variants={featureImg} />
                    </motion.div>
                </div>
            </section>
            <motion.section id='pricing' className="home-pricing-section" variants={container} initial='hidden' whileInView='show' viewport={{ once: true }}>
                <motion.h1 className='home-pricing-title-h1' variants={textchild}>Krijg volledig toegang</motion.h1>
                <motion.p className="home-pricing-txt-p" variants={textchild}>Haal alles uit je horecazaak met 10-Things!</motion.p>
                <motion.div variants={textchild}>
                    <Tabs color='primary' aria-label="Tabs colors" radius="full" className='subscription-type-tabs' onSelectionChange={(key) => changeSubscriptionType(key.toString())} selectedKey={subscriptionType}>
                        <Tab key="10TH1M" title="Maandelijks" />
                        <Tab key="10TH1Y" title="Jaarlijks" />
                    </Tabs>
                </motion.div>
                <div className="home-pricing-container">

                    <motion.div className="home-pricing-card" variants={textchild}>
                        <div className="home-pricing-top-area">
                            <h2 className="home-pricing-title-h2">Standaard</h2>
                            <p className="home-pricing-inner-txt-p">Haal alles uit 10-Things met het standaard abonnement.</p>
                        </div>
                        <hr className="home-pricing-divider" />
                        <div className="home-pricing-bottom-area">
                            <div className="home-pricing-price-area">
                                <p className="home-pricing-price-p">
                                    {activeProduct && activeProduct.is_discount_active === true && (
                                        <span
                                            className="home-pricing-original-price-span"
                                            style={{ textDecoration: 'line-through red', marginRight: '8px' }}>
                                            {convertToEuro(activeProduct.price)}
                                        </span>
                                    )}
                                    <span className="home-pricing-price-span">
                                        {activeProduct && convertToEuro(
                                            activeProduct.price * (1 - (activeProduct.discount_amount ?? 0) / 100)
                                        )}</span> {subscriptionTypeTab}
                                </p>
                            </div>
                            <div className="home-pricing-product-percs">
                                <ul className="home-pricing-percs-listing-ul">
                                    <li className="home-pricing-percs-item-li">
                                        <span className="home-pricing-percs-icon-span material-symbols-outlined">check</span>
                                        <p className="home-pricing-percs-txt-p">Toegang tot alle functionaliteiten</p>
                                    </li>
                                    <li className="home-pricing-percs-item-li">
                                        <span className="home-pricing-percs-icon-span material-symbols-outlined">check</span>
                                        <p className="home-pricing-percs-txt-p">Onbeperkt credits</p>
                                    </li>
                                    <li className="home-pricing-percs-item-li">
                                        <span className="home-pricing-percs-icon-span material-symbols-outlined">check</span>
                                        <p className="home-pricing-percs-txt-p">7 dagen proefperiode</p>
                                    </li>
                                    <li className="home-pricing-percs-item-li">
                                        <span className="home-pricing-percs-icon-span material-symbols-outlined">check</span>
                                        <p className="home-pricing-percs-txt-p">1-4 shifts</p>
                                    </li>
                                    <li className="home-pricing-percs-item-li">
                                        <span className="home-pricing-percs-icon-span material-symbols-outlined">check</span>
                                        <p className="home-pricing-percs-txt-p">150+ events</p>
                                    </li>
                                    <li className="home-pricing-percs-item-li">
                                        <span className="home-pricing-percs-icon-span material-symbols-outlined">check</span>
                                        <p className="home-pricing-percs-txt-p">Dagelijks evaluatierapport  </p>
                                    </li>
                                    <li className="home-pricing-percs-item-li">
                                        <span className="home-pricing-percs-icon-span material-symbols-outlined">check</span>
                                        <p className="home-pricing-percs-txt-p">Laat je inspireren door 500+ focuspunten van Wouter Verkerk </p>
                                    </li>

                                </ul>
                            </div>
                            <Link to="/register">
                                <button className="home-pricing-cta-btn" type='button'>
                                    Probeer het gratis!
                                </button>
                            </Link>
                        </div>
                    </motion.div>


                </div>
                <div className="home-pricing-credit-area">
                    <h1 className="home-pricing-credit-title-h1">
                        Of koop credits
                    </h1>
                    <div className="home-pricing-container">
                        {creditProduct && <CreditConverter dynamicPrices={creditProduct.dynamic_prices!} />}
                    </div>
                </div>


            </motion.section>

        </PageLayout>
    );
};

export default HomeView;