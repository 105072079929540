import React from 'react';
import './InspireMeThingItem.css';
import { ThingType } from '../../../../../../../models/ThingType';

interface InspireMeThingItemProps {
    thing: { id: number; title: string; description: string;};
    thingtype: ThingType;
    onPlanThingClick: (thing: any) => void;
}

const InspireMeThingItem: React.FC<InspireMeThingItemProps> = (props) => {
    return (
        <div className="created-thing-item" style={{backgroundColor: props.thingtype.color}} onClick={() => props.onPlanThingClick(props.thing)}>
            <h2 className='created-thing-title-h2'>{props.thing.title}</h2>
            <hr className="created-thing-divder" />
            <p className='created-thing-txt-p'>{props.thing.description}</p>
        </div>
    );
};

export default InspireMeThingItem;