import { RegisterUser } from "../../models/Auth";
import { RegisterResponse, UpdateUser } from "../../models/Users";
import api from "../auth/api";
import { getUserInfo } from "../auth/user-info";

function applyPatch<T>(original: T, updates: Partial<T>): T {
    return { ...original, ...updates };
}

export const updateUser = async (id: string, data: Partial<UpdateUser>) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {

        console.log('Updating User');

        const userInfo = getUserInfo();

        const orginalUser = {
            firstname: userInfo.firstname,
            lastname: userInfo.lastname,
            email: userInfo.email,
            phone_number: userInfo.phone_number,
            device_id: userInfo.device_id,
            role: userInfo.role,
            parent_company_id: userInfo.parent_company_id,
            is_active: userInfo.is_active
        }

        const updatedUser = applyPatch(orginalUser, data);

        console.log(updatedUser);

        const url = `/users/${id}`;
        const response = await api.put(url, updatedUser, config);
        console.log(response);
        return response;
    } catch (error) {
        console.error('Failed to update user:', error);
        throw error;
    }
}

export const registerUser = async (registeruser: RegisterUser): Promise<RegisterResponse> => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = '/auth/signup/';
        const response = await api.post(url, registeruser, config);
        return response.data;
    } catch (error) {
        console.error('Failed to register user:', error);
        throw error;
    }
}

export const verifyUser = async (username: string, confirmcode: string) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/auth/confirm_account/${username}/${confirmcode}/`;
        const response = await api.post(url, config);
        return response;
    } catch (error) {
        console.error('Failed to verify user:', error);
        throw error;
    }
}

export const resendConfirmationCode = async (username: string) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/auth/resend_confirmation_code/${username}/`;
        const response = await api.post(url, config);
        return response;
    } catch (error) {
        console.error('Failed to resend confirmation code:', error);
        throw error;
    }
}

export const addUserToSubsidiary = async (subsidiaryId: number, userId: number) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/users/add_subsidiary_to_user/${userId}/${subsidiaryId}/`;
        const response = await api.post(url, config);
        return response;
    } catch (error) {
        console.error('Failed to add user to subsidiary:', error);
        throw error;
    }
}

export const checkUserExists = async (email: string) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/users/check_user_by_email/${email}/`;
        const response = await api.get(url, config);
        return response;
    } catch (error) {
        console.error('Failed to check user exists:', error);
        throw error;
    }
}

export const forgotPassword = async (email: string) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/auth/forgot_password/${email}/`;
        const response = await api.post(url, config);
        return response;
    } catch (error) {
        return 'no user found';
    }

}

export const resetPassword = async (email: string, resetPassword: any) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/auth/reset_password/${email}/`;
        const response = await api.post(url, resetPassword, config);
        return response;
    } catch (error) {
        console.error('Failed to reset password:', error);
        throw error;
    }
}

export const getUsernameByEmail = async (email: string) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/users/get_user_by_email/${email}/`;
        const response = await api.get(url, config);
        return response;
    } catch (error) {
        console.error('Failed to get username by email:', error);
        throw error;
    }
}