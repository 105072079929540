import React, { useState } from 'react';

interface ChangePasswordProps {
    newPassword: string;
    setNewPassword: (newPassword: string) => void;
    errorPassword: string;
    confirmPassword: string;
    setConfirmPassword: (confirmPassword: string) => void;
    errorConfirmPassword: string;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ newPassword, setNewPassword, errorPassword, confirmPassword, setConfirmPassword, errorConfirmPassword }) => {

    return (
        <div>
            <h2 className="forgot-password-title">Wachtwoord vergeten?</h2>
            <div className="forgot-password-form-input-bar">
                <label htmlFor="newPassword" className="forgot-password-label">Nieuw wachtwoord</label>
                <input
                    type="password"
                    id="newPassword"
                    className="forgot-password-input"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                {errorPassword && <div className="forgot-password-error-field">
                    <p className="forgot-password-error-txt">{errorPassword}</p>
                </div>}
            </div>
            <div className="forgot-password-form-input-bar">
                <label htmlFor="confirmPassword" className="forgot-password-label">Bevestig wachtwoord</label>
                <input
                    type="password"
                    id="confirmPassword"
                    className="forgot-password-input"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {errorConfirmPassword && <div className="forgot-password-error-field">
                    <p className="forgot-password-error-txt">{errorConfirmPassword}</p>
                </div>}
            </div>
            <div className="forgot-password-form-input-bar">
                <button type="submit" className="forgot-password-button">
                    <div className="forgot-password-txt-div">Verander wachtwoord</div>
                </button>
            </div>
        </div>
    );
};

export default ChangePassword;