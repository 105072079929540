import React, { useEffect, useRef, useState } from 'react';
import './ReportReceivers.css';
import { deleteReportMaillingList, fetchReportMaillingList } from '../../../../../api/report_mailinglist/reportmaillinglistService';
import { ReportMailinglist } from '../../../../../models/ReportMailinglist';
import DeletePopup from '../../../../../components/Portaal/DeletePopup/DeletePopup';
import { Helmet } from 'react-helmet-async';

interface ReportReceiversProps {
    setSelectedEditOverlay: (value: string) => void;
    setModalIsOpen: (value: boolean) => void;
    setModalTitle: (value: string) => void;
    setSelectedEditingObject: (value: any) => void;
    selectedMenu: Boolean;
    setSelectedMenu: React.Dispatch<React.SetStateAction<Boolean>>;
    reportReceivers: ReportMailinglist[];
    deleteReceiver: (receiver: ReportMailinglist) => void;
}

const ReportReceivers: React.FC<ReportReceiversProps> = (props) => {
    // Add your component logic here

    const handleActionClick = (action: string) => {
        props.setSelectedEditOverlay(action);
        props.setModalIsOpen(true);
    };

    const [dropdownVisible, setDropdownVisible] = useState<{ [key: number]: boolean }>({});

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownVisible({});
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const toggleDropdown = (index: number) => {
        setDropdownVisible(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleEditClick = (receiver: any) => {
        props.setSelectedEditOverlay('editmailer');
        props.setModalIsOpen(true);
        props.setSelectedEditingObject(receiver);
    };

    const handleCloseBtnClick = () => {
        props.setSelectedMenu(!props.selectedMenu);
    };

    const [deletingReceiver, setDeletingReceiver] = useState<ReportMailinglist>();
    const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);

    const handleDeleteReceiver = async () => {
        const response = await deleteReportMaillingList(deletingReceiver!.id);
        props.deleteReceiver(deletingReceiver!);
        setOpenDeletePopup(false);
    }

    const clickDelete = (receiver: ReportMailinglist) => {
        setDeletingReceiver(receiver);
        setOpenDeletePopup(true);
    }

    const handleClose = () => {
        console.log('Close');
        setOpenDeletePopup(false);
    }

    return (
        <div className='reportreceivers-container'>
            <Helmet>
                <title>10-Things | Portaal | Rapport Ontvangers</title>
            </Helmet>
            {openDeletePopup && <DeletePopup title={'Weet je het zeker?'} text={'Als je de rapportontvanger verwijderd kan je dat niet meer terugdraaien'} onDelete={handleDeleteReceiver} onClose={handleClose} />}
            <div className="settings-top-bar">
                <h2 className="settings-title-h2">Rapport ontvangers</h2>
                <button className="settings-mobile-close-btn" type='button' onClick={handleCloseBtnClick}>
                    <span className="material-symbols-rounded settings-mobile-close-icon-span">close</span>
                </button>
            </div>
            <button className="reportreceivers-add-receiver-btn" type='button' onClick={() => handleActionClick('mailer')}>
                <span className="material-symbols-rounded reportreceivers-icon-span">add</span>
                <span className="reportreceivers-txt-span">Toevoegen</span>
            </button>
            <table className="styled-table">
                <thead>
                    <tr>
                        <th className='reportreceiver-name-th'>Naam</th>
                        <th className='reportreceiver-email-th'>E-mail</th>
                        <th className='reportreceiver-phone-th'>Telefoon</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {props.reportReceivers.map((receiver, index) => {
                        return (
                            <tr key={index}>
                                <td className='reportreceiver-name-td'>
                                    <div className="profile-icon">{`${receiver.first_name.charAt(0)}${receiver.last_name.charAt(0)}`.toUpperCase()}</div>
                                    <p className="table-txt-p">{receiver.first_name} {receiver.last_name}</p>
                                </td>
                                <td className='reportreceiver-email-td'>{receiver.email}</td>
                                <td className='reportreceiver-phone-td'>{receiver.phone_number}</td>
                                <td className='reportreceiver-options-td'>
                                    <button className="table-options-btn" type='button' onClick={() => toggleDropdown(index)}>
                                        <span className="material-symbols-outlined table-options-icon">more_vert</span>
                                    </button>
                                    {dropdownVisible[index] && (
                                        <div className="dropdown-menu" ref={dropdownRef}>
                                            <ul className="dropdown-menu-listing">
                                                <li className="dropdown-menu-item" onClick={() => handleEditClick(receiver)}>Bewerken</li>
                                                <li className="dropdown-menu-item" onClick={() => clickDelete(receiver)}>Verwijderen</li>
                                            </ul>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        );
                    })}

                </tbody>
            </table>
        </div>
    );
};

export default ReportReceivers;