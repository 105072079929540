import React from 'react';
import './NoShift.css';
import { checkHasCredits } from '../../../../utils/creditUtils';

interface NoShiftProps {
    setOpenPreBriefingModal: (open: boolean) => void;
    handleOpenNoCreditsModal?: () => void;
}

const NoShift: React.FC<NoShiftProps> = ({ setOpenPreBriefingModal, handleOpenNoCreditsModal }) => {

    const handlePreBriefingClick = async () => {
        const hasCredits = await checkHasCredits();
        if (hasCredits) {
            setOpenPreBriefingModal(true);
            return;
        } else {
            handleOpenNoCreditsModal!();
        }
    };

    return (
        <div className='noshift-wrapper'>
            <h1 className='noshift-title-h1'>Geen actieve shift</h1>
            <p className="noshift-txt-p"></p>
            <button className="start-shift-button" onClick={handlePreBriefingClick}>Start briefing</button>
            <ul className="noshift-listing-ul">
                <li className="no-shift-list-item-li">
                    <span className="no-shift-list-item-span material-symbols-outlined">Check </span>
                    <p className="no-shift-list-item-p"> Evalueer je teamprestaties </p>
                </li>
                <li className="no-shift-list-item-li">
                    <span className="no-shift-list-item-span material-symbols-outlined">Check </span>
                    <p className="no-shift-list-item-p"> Bepaal je focuspunten</p>
                </li>
                <li className="no-shift-list-item-li">
                    <span className="no-shift-list-item-span material-symbols-outlined">Check </span>
                    <p className="no-shift-list-item-p"> Selecteer je vips</p>
                </li>
            </ul>
        </div>
    );
};

export default NoShift;