import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './UploadLogo.css';

interface UploadLogoProps {
    // Add any props you need for the component here
    file: any;
    setFile: (file: any) => void;
    preview: string | null;
    setPreview: (preview: string | null) => void;
    logoError: string;
}

const UploadLogo: React.FC<UploadLogoProps> = ({ file, setFile, preview, setPreview, logoError }) => {

    const onDrop = (acceptedFiles: any) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setFile(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
        },
        multiple: false,
        noClick: true,
        noKeyboard: true
    });

    useEffect(() => {
        // Revoke the data uri when component unmounts
        return () => {
            if (preview) {
                URL.revokeObjectURL(preview);
            }
        };
    }, [preview]);

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const handleSubmit = async () => {
        // Handle the file upload
        if (!file) {
            return;
        }
        try {

            // const response = await UploadLogoDB(uploadLogo);
            // console.log('Uploaded logo:', response);
            // props.setHasLogo(true);
            // props.setLogo(response.location);
            // const patchSubsidiary = await patchSubsidiaryDB({ logo_id: response.id });
            // console.log('Patched subsidiary', patchSubsidiary);        
            // updateSubsidiaryInfo({ logo_id: patchSubsidiary.logo_id, logo: patchSubsidiary.logo, last_update_at: patchSubsidiary.last_update_at, updated_by: patchSubsidiary.updated_by });    
        } catch (e) {
            console.error('Failed to upload logo:', e);
        } finally {
        }
    };

    return (
        <div>
            <h1 className="add-subsidiary-form-title-h1">Upload jullie logo</h1>
            <div className="logo-upload-modal-upload-container">
                <div className="logo-upload-modal-inner-upload-div">
                    <div {...getRootProps()} className={`logo-upload-dropzone ${isDragActive ? 'active' : ''}`}>
                        <input {...getInputProps()} />
                        {
                            file ? (
                                <div className="logo-upload-preview">
                                    <img src={preview ?? ''} alt="Preview" className="logo-upload-preview-img" />
                                    <button className="subsidiary-logo-change-btn" type='button' onClick={open}>
                                        <span className="subsidiary-logo-change-icon-span material-symbols-outlined">edit</span>
                                    </button>
                                </div>
                            ) : (
                                isDragActive ?
                                    <div className="logo-upload-drop-area">
                                        <p className="logo-upload-drop-txt-p">Drop it like it's hot</p>
                                    </div> :
                                    <div className="logo-upload-add-file-inner-area">
                                        <span className="logo-upload-file-icon-span material-symbols-outlined">upload</span>
                                        <p className="logo-upload-add-file-txt-p">Sleep je logo hier of <a onClick={open} className='files-browse-a'>blader hier</a></p>
                                    </div>
                            )
                        }
                    </div>

                    {logoError && <p className="error-msg">{logoError}</p>}
                </div>
            </div>
        </div>
    );
};

export default UploadLogo;