import React from 'react';
import PageLayout from '../../components/Site/PageLayout/PageLayout';
import './PrivacyPolicyView.css';
import { Helmet } from 'react-helmet-async';
import usePageTracking from '../../utils/usePageTracking';

const PrivacyPolicyView: React.FC = () => {
    usePageTracking();
    return (
        <PageLayout>
            <Helmet>
                <title>10-Things | Privacy beleid</title>
            </Helmet>
            <div className="inner-div">
                <header>
                    <h1 className='privacy-h1-title'>Privacy Overeenkomst 10Things</h1>
                </header>
                <section>
                    <div className="text-section"></div>
                    <h3 className='privacy-h3-title'>Inhoudsopgave</h3>
                    <ul id='number-list'>
                        <li className='privacy-txt-li'>Wanneer pas je deze privacyverklaring toe?</li>
                        <li className='privacy-txt-li'>Wie gebruikt je gegevens</li>
                        <li className='privacy-txt-li'>Van wie gebruiken we gegevens?</li>
                        <li className='privacy-txt-li'>Hoe komen we aan je gegevens?</li>
                        <li className='privacy-txt-li'>Welke gegevens van je gebruiken we?</li>
                        <li className='privacy-txt-li'>Waarvoor gebruiken we je gegevens?</li>
                        <li className='privacy-txt-li'>Hoelang bewaren we je gegevens?</li>
                        <li className='privacy-txt-li'>Met wie delen we je gegevens?</li>
                        <li className='privacy-txt-li'>Waar slaan we je gegevens op?</li>
                        <li className='privacy-txt-li'>Hoe veilig zijn je gegevens bij ons?</li>
                        <li className='privacy-txt-li'>Wat mag je van ons vragen?</li>
                        <li className='privacy-txt-li'>Welke regels gelden voor deze privacyverklaring?</li>
                        <li className='privacy-txt-li'>Welke cookies gebruiken we?</li>
                        <li className='privacy-txt-li'>Wat doen we met gegevens van minderjarigen?</li>
                        <li className='privacy-txt-li'>Heb je een vraag over deze privacyverklaring?</li>
                    </ul>
                </section>
                <section>
                    <h3 className='privacy-h3-title'>1. Wanneer pas je deze privacyverklaring toe?</h3>
                    <p className='privacy-txt-p'>Deze privacyverklaring is van toepassing op alle persoonsgegevens die we verwerken en op alle domeinen die aan ons gerelateerd zijn. Het gaat hier om de persoonsgegevens van iedereen die weleens contact met ons heeft gehad of onze website heeft bezocht, zoals bezoekers, klanten en zakelijke contactpersonen. Persoonsgegevens zijn alle gegevens die herleidbaar zijn tot jou als individu, zoals je naam, telefoonnummer, IP-adres, klantnummer of surfgedrag. Wil je meer weten over persoonsgegevens, kijk dan op de website van de Autoriteit Persoonsgegevens.</p>
                    <h3 className='privacy-h3-title'>2. Wie gebruikt mijn gegevens?</h3>
                    <p className='privacy-txt-p'>MOGEE B.V. is verantwoordelijk voor de website www.10-things.nl en daarmee de verantwoordelijke organisatie voor het gebruik van je persoonsgegevens zoals beschreven in deze privacyverklaring. De volledige gegevens zijn: MOGEE B.V., Columbusplein 54, 1057VC Amsterdam, 85393401.</p>
                    <h3 className='privacy-h3-title'>3. Van wie gebruiken we gegevens?</h3>
                    <p className='privacy-txt-p'>We verwerken de persoonsgegevens van iedereen die contact met ons heeft gehad of onze website heeft bezocht. Dit zijn onder meer bezoekers, particuliere klanten, zakelijke klanten en contactpersonen van onze partners.</p>
                    <h3 className='privacy-h3-title'>4. Hoe komen we aan je gegevens?</h3>
                    <p className='privacy-txt-p'>We krijgen de gegevens rechtstreeks van jou zodra je:</p>
                    <ul className='privacy-listing-ul'>
                        <li className='privacy-txt-li'>onze website bezoekt</li>
                        <li className='privacy-txt-li'>een account aanmaakt</li>
                        <li className='privacy-txt-li'>gegevens invult op onze website</li>
                    </ul>
                    <h3 className='privacy-h3-title'>5. Welke gegevens gebruiken we van je?</h3>
                    <ul className='privacy-listing-ul'>
                        <li className='privacy-txt-li'>naam</li>
                        <li className='privacy-txt-li'>geslacht</li>
                        <li className='privacy-txt-li'>woonadres of vestigingsadres</li>
                        <li className='privacy-txt-li'>e-mailadres</li>
                        <li className='privacy-txt-li'>telefoonnummer</li>
                        <li className='privacy-txt-li'>betaalgegevens</li>
                        <li className='privacy-txt-li'>geboortedatum</li>
                        <li className='privacy-txt-li'>leeftijd</li>
                    </ul>
                </section>
                <section>
                    <h3 className='privacy-h3-title'>6. Waarvoor gebruiken we je gegevens?</h3>
                    <p className='privacy-txt-p'>We gebruiken je persoonsgegevens alleen voor het doel waar we die voor mogen gebruiken:</p>
                    <ul className='privacy-listing-ul'>
                        <li className='privacy-txt-li'>we hebben van jou je toestemming gekregen om je persoonsgegevens te gebruiken</li>
                        <li className='privacy-txt-li'>we gebruiken je gegevens omdat we je als klant de beste service willen geven en we dat zonder die informatie niet kunnen doen</li>
                        <li className='privacy-txt-li'>we gebruiken je gegevens omdat we een centrale administratie bijhouden, marketingactiviteiten verrichten, fraude willen voorkomen en bestrijden en de gegevens gebruiken voor risicobeheersing</li>
                        <li className='privacy-txt-li'>we gebruiken je gegevens om ons te houden aan de wet, zoals gegevens bewaren voor de belastingdienst</li>
                    </ul>
                    <h3 className='privacy-h3-title'>7. Hoelang bewaren we je gegevens?</h3>
                    <p className='privacy-txt-p'>We bewaren je persoonsgegevens zo lang als we dat volgens de wet moeten doen en zo lang als nodig is voor het doel waarvoor we je gegevens gebruiken. Zolang je bijvoorbeeld klant bij ons bent, bewaren we je gegevens volgens de wettelijke bewaartermijn van zeven jaar. Daarna bewaren we je gegevens alleen voor statistische doeleinden en om eventuele klachten of juridische zaken af te handelen. Wil je meer weten over hoelang we specifieke gegevens van je bewaren, neem dan contact met ons op.</p>
                    <h3 className='privacy-h3-title'>8. Met wie delen we je gegevens?</h3>
                    <p className='privacy-txt-p'>Je persoonsgegevens worden alleen door ons gebruikt. We zullen je persoonsgegevens nooit met anderen delen.</p>
                    <h3 className='privacy-h3-title'>9. Waar slaan we je gegevens op?</h3>
                    <p className='privacy-txt-p'>We verwerken je gegevens binnen de Europese Economische Ruimte (EER). Dit houdt in dat we ook je gegevens binnen de EER opslaan. Heb je hierover vragen, neem dan gerust contact met ons op.</p>
                    <h3 className='privacy-h3-title'>10. Hoe veilig zijn je gegevens bij ons?</h3>
                    <p className='privacy-txt-p'>We hebben er veel aan gedaan om je gegevens zowel organisatorisch als technisch zo goed mogelijk te beveiligen. We hebben onze systemen en verschillende communicatiemiddelen beveiligd om ervoor te zorgen dat je gegevens niet in de handen van anderen terechtkomen. Je gegevens zijn dus veilig bij ons. Ook zorgen we ervoor dat je gegevens alleen worden gebruikt door mensen die daar van ons toestemming voor hebben gekregen. Heb je vragen over de specifieke manier van beveiligen, neem dan contact met ons op.</p>
                </section>
                <section>
                    <h3 className='privacy-h3-title'>11. Wat mag je van ons vragen?</h3>
                    <p className='privacy-txt-p'>Omdat we persoonsgegevens van je gebruiken, heb je verschillende rechten. Deze rechten zetten we hieronder voor je op een rijtje.</p>
                    <ul className='privacy-listing-ul'>
                        <li className='privacy-txt-li'>Recht op informatie</li>
                        <li className='privacy-txt-li'>Recht op inzage</li>
                        <li className='privacy-txt-li'>Recht op correctie</li>
                        <li className='privacy-txt-li'>Recht om bezwaar te maken</li>
                        <li className='privacy-txt-li'>Recht op dataportabiliteit</li>
                        <li className='privacy-txt-li'>Recht op beperking</li>
                        <li className='privacy-txt-li'>Recht om vergeten te worden</li>
                        <li className='privacy-txt-li'>Recht om een klacht in te dienen</li>
                    </ul>
                    <p className='privacy-txt-p'>Hoe dien ik een aanvraag of klacht in?</p>
                    <p className='privacy-txt-p'>Stuur je aanvraag of klacht naar ons via info@10-things.nl. We verwerken je aanvraag of klacht binnen 30 dagen. Gaat het om meerdere aanvragen of klachten of is je aanvraag of klacht complex, dan kan dit meer tijd met zich meebrengen. In dat geval nemen we uiterlijk binnen 60 dagen contact met je op. We kunnen je vragen om je te identificeren. In dat geval vragen we bij je gegevens op om er zeker van te zijn dat je de juiste persoon bent van wie de persoonsgegevens zijn.</p>
                    <h3 className='privacy-h3-title'>12. Welke regels gelden voor deze privacyverklaring?</h3>
                    <p className='privacy-txt-p'>Onze privacyverklaring moet aan verschillende voorwaarden voldoen. Deze voorwaarden vind je met name terug in de Algemene Verordening Gegevensbescherming. Daarnaast de algemene regels die volgens de Nederlandse wet gelden van toepassing op onze privacyverklaring.</p>
                    <h3 className='privacy-h3-title'>13. Welke cookies gebruiken we?</h3>
                    <p className='privacy-txt-p'>Je kunt zelf aangeven welke gegevens we van je mogen gebruiken. Heb je ons toestemming gegeven voor het personaliseren van je profiel op basis van je surf- en zoekgedrag, dan kunnen we onze website speciaal op jou instellen zodat het gebruik ervan makkelijker en persoonlijker wordt. We doen dit met behulp van cookies. Een cookie is een klein tekstbestand dat bij je bezoek aan onze website geplaatst wordt op je hardware.</p>
                    <p className='privacy-txt-p'>We gebruiken de volgende soorten cookies op onze website:</p>
                    <ul className='privacy-listing-ul'>
                        <li className='privacy-txt-li'>functionele cookies, zoals sessie- en login cookies voor het bijhouden van sessie- en inloginformatie</li>
                        <li className='privacy-txt-li'>geanonimiseerde analytische cookies, om inzicht te krijgen in het bezoek aan onze website op basis van informatie over bezoekersaantallen, populaire pagina’s en populaire onderwerpen</li>
                        <li className='privacy-txt-li'>niet-geanonimiseerde analytische cookies, om inzicht te krijgen in het bezoek aan onze website op basis van informatie over bezoekersaantallen, populaire pagina’s en populaire onderwerpen</li>
                        <li className='privacy-txt-li'>tracking cookies zoals advertentiecookies die zijn bedoeld voor het tonen van relevante advertenties</li>
                    </ul>
                    <p className='privacy-txt-p'>Meer specifiek gebruiken we de volgende cookies:</p>
                    <ul className='privacy-listing-ul'>
                        <li className='privacy-txt-li'>Google Analytics geanonimiseerd (analytische cookies)</li>
                        <li className='privacy-txt-li'>Facebook (tracking cookie)</li>
                        <li className='privacy-txt-li'>Google Adwords (tracking cookie)</li>
                    </ul>
                    <h3 className='privacy-h3-title'>14. Wat doen we met gegevens van minderjarigen?</h3>
                    <p className='privacy-txt-p'>We richten ons met onze website of als organisatie niet specifiek op minderjarigen. Dit houdt in dat als je jonger bent dan 18 jaar, je toestemming nodig hebt van een ouder of voogd om gebruik te maken van onze website. Ben je bij je bezoek aan onze website of webshop minderjarig, dan gaan we ervan uit dat je voor je bezoek deze toestemming hebt gekregen.</p>
                    <h3 className='privacy-h3-title'>15. Heb je een vraag over deze privacyverklaring?</h3>
                    <p className='privacy-txt-p'>Heb je een vraag over onze privacyverklaring? Neem dan gerust contact op met ons door een mail te sturen naar info@10-things.nl. We helpen je graag.</p>
                </section>
            </div>
        </PageLayout>
    );
};

export default PrivacyPolicyView;