import React, { useEffect } from 'react';
import './ViewCreatedThings.css';
import CreatedThingItem from './CreatedThingItem/CreatedThingItem';
import AddThingItem from './AddThingItem/AddThingItem';
import { fetchThingsByThingType } from '../../../../api/things/thingsService';
import { ThingType } from '../../../../models/ThingType';
import { Thing } from '../../../../models/Thing';

interface ViewCreatedThingsProps {
    thingtype: ThingType;
    onAddThingClick: () => void;
    onPlanThingClick: (thing: any) => void;
    refreshKey: number;
    onClickThingOptions: (event: React.MouseEvent, thing: Thing) => void;
}

const ViewCreatedThings: React.FC<ViewCreatedThingsProps> = ({ thingtype, onAddThingClick, refreshKey, onPlanThingClick, onClickThingOptions }) => {
    const [things, setThings] = React.useState<Thing[]>([]);

    useEffect(() => {
        const loadThings = async () => {
            try {
                const things = await fetchThingsByThingType(thingtype.id);
                setThings(things);
            } catch (err) {
                console.error('Failed to fetch things:', err);
            } finally {
                console.log('Done fetching things');
            }
        };
        loadThings();
    }, [refreshKey]);

    return (
        <div id="view-created-things-container">
            <ul className="created-things-list">
                <AddThingItem thingtype={thingtype} onAddThingClick={onAddThingClick}/>
                {things.map((thing, index) => (<CreatedThingItem key={index} thing={thing} thingtype={thingtype} onPlanThingClick={onPlanThingClick} onClickThingOptions={onClickThingOptions} />))}
            </ul>
        </div>
    );
};

export default ViewCreatedThings;