import React, { ReactNode } from 'react';
import './ModalLayout.css';

interface ModalLayoutProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    children: ReactNode;
    zIndex?: number;
}

const ModalLayout: React.FC<ModalLayoutProps> = ({ isOpen, onClose, title, children, zIndex }) => {
    if (!isOpen) {
        return null;
    }

    const stopPropagation = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    return (
        <div className="modal-overlay" onClick={onClose} style={{zIndex: zIndex}}>
            <div className="modal-wrapper">
                <div className="modal-content" onClick={stopPropagation}>
                    <div className="modal-top-area">
                        {title && (<p className="modal-title-p">{title}</p>)}
                        <button className="modal-close" onClick={onClose}>
                            <span className="modal-close-icon material-symbols-outlined">close</span>
                        </button>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ModalLayout;