import React, { useState } from 'react';
import './PageLayout.css';
import NavBar from '../NavBar/NavBar';
import TopNavBar from '../TopNavBar/TopNavBar';
import { Shift } from '../../../models/Shift';
import BuyCreditsModal from '../BuyCreditsModal/BuyCreditsModal';
import { Subscription } from '../../../models/Subscription';

interface PortaalPageLayoutProps {
    activePage: string;
    children: React.ReactNode;
    onBackButtonClick?: () => void;
    enableBackButton?: boolean;
    toggleNav: () => void;
    isNavOpen: boolean;
    activeShift?: Shift | null;
    handleOpenBuyCreditsModal?: () => void;
    openBuyCreditsModal: boolean;
    setOpenBuyCreditsModal: React.Dispatch<React.SetStateAction<boolean>>;
    activeSubscription: Subscription | null | undefined;
}

const PortaalPageLayout: React.FC<PortaalPageLayoutProps> = ({ activePage, children, onBackButtonClick = () => { },
    enableBackButton = false, toggleNav, isNavOpen, activeShift, handleOpenBuyCreditsModal, openBuyCreditsModal, setOpenBuyCreditsModal, activeSubscription }) => {

    return (
        <div id='main-pagelayout'>
            {openBuyCreditsModal && <BuyCreditsModal onClose={() => setOpenBuyCreditsModal(false)}/>}
            <NavBar activePage={activePage} isNavOpen={isNavOpen} toggleNav={toggleNav} />
            <main id='main-content' className={`${isNavOpen ? 'nav-open' : ''}`}>
                <TopNavBar onBackButtonClick={onBackButtonClick} enableBackButton={enableBackButton} toggleNav={toggleNav} activeShift={activeShift} handleOpenBuyCreditsModal={handleOpenBuyCreditsModal} activeSubscription={activeSubscription} />
                {children}
            </main>
        </div>
    );
};

export default PortaalPageLayout;