import React, { useState } from 'react';
import './CreditBuyer.css';
import euflag from '../../../../assets/img/eu-flag.png';
import logo from '../../../../assets/img/website-10things-logo-v2-outlined.png';
import { Link } from 'react-router-dom';
import { Product } from '../../../../models/Product';

interface CreditBuyerProps {
    product: Product;
}

const CreditBuyer: React.FC<CreditBuyerProps> = ({ product }) => {
    const [credits, setCredits] = useState<number>(5);
    const [currency, setCurrency] = useState<number>(5);

    const getConversionRate = (amount: number): number => {
        const sortedPrices = [...product.dynamic_prices].sort((a, b) => a.price - b.price);
        for (let i = 0; i < sortedPrices.length; i++) {
            if (amount <= sortedPrices[i].price) {
                return sortedPrices[i].price_per_credit;
            }
        }
        return sortedPrices[sortedPrices.length - 1].price_per_credit;
    };

    const handleCreditsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newCredits = parseFloat(e.target.value);
        setCredits(newCredits);
        const conversionRate = getConversionRate(newCredits);
        setCurrency(newCredits * conversionRate);
    };

    const handleCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newCurrency = parseFloat(e.target.value);
        setCurrency(newCurrency);
        const conversionRate = getConversionRate(newCurrency);
        setCredits(Math.floor(newCurrency / conversionRate));
    };

    const incrementCurrency = () => {
        const newCurrency = currency + 5;
        setCurrency(newCurrency);
        const conversionRate = getConversionRate(newCurrency);
        setCredits(Math.floor(newCurrency / conversionRate));
    };

    const decrementCurrency = () => {
        if (currency > 5) {
            const newCurrency = currency - 5;
            setCurrency(newCurrency);
            const conversionRate = getConversionRate(newCurrency);
            setCredits(Math.floor(newCurrency / conversionRate));
        }
    };

    return (
        <div className='credit-converter-div'>
            <div>
                <label>
                    <div className="credit-converter-top-area">
                        <img src={euflag} alt="Euro" className="credit-converter-img" />
                        <p className="credit-converter-type-txt-p">EUR - Euro</p>
                    </div>
                    <div className="credit-converter-input-container">
                        <button className='credit-converter-counter' onClick={decrementCurrency}>-</button>
                        <input
                            className='credit-converter-input'
                            type="number"
                            value={currency}
                            onChange={handleCurrencyChange}
                            disabled={true}
                        />
                        <button className='credit-converter-counter' onClick={incrementCurrency}>+</button>
                    </div>

                </label>
            </div>
            <hr className='credit-converter-hr' />
            <div>
                <label>
                    <div className="credit-converter-top-area">
                        <img src={logo} alt="Euro" className="credit-converter-img tenthings" />
                        <p className="credit-converter-type-txt-p">Credits</p>
                    </div>
                    <div className="credit-converter-input-container">
                        <input
                            className='credit-converter-input'
                            type="number"
                            value={credits}
                            onChange={handleCreditsChange}
                            disabled={true}
                        />
                    </div>


                </label>

            </div>
            <hr className='credit-converter-hr' />
            <Link to={`/betalen?p=${product.product_identifier}&a=${credits}`}>
                <button className="home-pricing-cta-btn" type='button'>
                    Koop credits
                </button>
            </Link>
        </div>
    );
};

export default CreditBuyer;