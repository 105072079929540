import React from 'react';
import './ActionItem.css';
import { Action } from '../../../models/Action';

interface ActionItemProps {
    action: Action;
    backgroundColor: string;
    onActionOptionsClick: (event: React.MouseEvent, action: Action) => void;
    onClickAction: (action: any) => void;
}

const ActionItem: React.FC<ActionItemProps> = (props) => {
    // Implement the ActionItem component logic here

    return (
        <li className="thinglist-listing-li" style={{ backgroundColor: props.backgroundColor }}>
            <div className="thinglist-title-area-div">
                <h2 className="thinglist-title-h2">{props.action.title}</h2>
            </div>
            <hr />
            <div className="thinglist-info-area-div">
                <p className="thinglist-info-p">{props.action.description}</p>
            </div>
            {/* <div className="thinglist-item-control-bar">
                <div className="thingslist-item-users-area">
                    {props.action.is_pinned && props.action.is_planned ? <span className="things-item-icon-span material-symbols-outlined">keep</span> : null}
                </div>
                <div className="thingslist-item-options-area">
                    <button className="thingslist-item-options-btn" onClick={(event) => props.onActionOptionsClick(event, props.action)}>
                        <span className="material-symbols-outlined thingslist-item-icon-span">more_vert</span>
                    </button>
                </div>
            </div> */}
        </li>
    );
};

export default ActionItem;