import React from 'react';
import './ActiveShiftBar.css';
import { Shift } from '../../../../models/Shift';


interface ActiveShiftBarProps {
    // Define the props for the ActiveShiftBar component here
    shiftType: number;
    shift: Shift;
}

const ActiveShiftBar: React.FC<ActiveShiftBarProps> = (props) => {
    // Implement the logic for the ActiveShiftBar component here

    let shiftTitle = ' ';

    if (props.shiftType === 1) {
        shiftTitle = 'Ochtendshift';
        console.log('Ochtendshift');
    }

    if (props.shiftType === 2) {
        shiftTitle = 'Middagshift';
        console.log('Middagshift');
    }

    if (props.shiftType === 3) {
        shiftTitle = 'Avondshift';
        console.log('Avondshift');
    }

    if (props.shiftType === 4) {
        shiftTitle = 'Nachtshift';
        console.log('Nachtshift');
    }

    // console.log("downloadtest", props.shift.briefing_src);
    return (
        <div className='active-shift-bar'>
            <p className='active-shift-bar-title'>
                {shiftTitle} 
                </p>
                
                <a href={props.shift.briefing_src} download>
                    <button className="download-button">
                        <span className="material-symbols-outlined">
                            download
                        </span>
                    </button>
                </a>

        </div>
    );
};

export default ActiveShiftBar;