import api from "../auth/api";

export const fetchLabels = async () => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/labels/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch labels:', error);
        throw error;
    }
};

export const fetchGeneralLabels = async () => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/labels/get_general_labels/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch general labels:', error);
        throw error;
    }
};

export const fetchLabelsByBranch = async (branchId: number) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/labels/get_labels_by_branch/${branchId}`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch labels by branch:', error);
        throw error;
    }
};