import React from 'react';
import './AddThingItemPreBriefing.css';
import { ThingType } from '../../../../../../../models/ThingType';

interface AddThingItemPreBriefingProps {
    thingtype: ThingType;
    onAddThingClick: () => void;
}

const AddThingItemPreBriefing: React.FC<AddThingItemPreBriefingProps> = ({thingtype, onAddThingClick}) => {
    // Add your component logic here

    return (
        <div className="add-thing-item" style={{ backgroundColor: thingtype.color }} onClick={onAddThingClick}>
            <span className="add-thing-icon-span material-symbols-outlined">add</span>
        </div>
    );
};

export default AddThingItemPreBriefing;