import axios from "axios";
import { apiURL } from "../../devEnv";
import { EmailBlacklist, EmailBlacklistCreate } from "../../models/EmailBlacklist";

export const addEmailToBlacklist = async (emailBlacklistCreate: EmailBlacklistCreate) => {
    try {
        const response = await axios.post(`${apiURL}/email_blacklists/`, emailBlacklistCreate);
        return response.data;
    } catch (error) {
        console.error('Error adding email to blacklist:', error);
        throw error;
    }
};