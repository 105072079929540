import React, { useEffect, useState } from 'react';
import './NoticesStep.css';
import { CreateEvent } from '../../../../../../models/Event';
import AddVIPBriefingModal from '../../AddVIPBriefingModal/AddVIPBriefingModal';
import { Vip, VipBriefing } from '../../../../../../models/Vip';
import AddEventBriefingModal from '../../AddEventBriefingModal/AddEventBriefingModal';
import VipWidgetItem from '../../../../VIPWidget/VipWidgetItem/VipWidgetItem';

interface NoticesStepProps {
    shift: string;
    vips: VipBriefing[];
    addVip: (vip: VipBriefing) => void;
    todayEvents: CreateEvent[];
    addEvent: (event: CreateEvent) => void;
    onVipOptionsClick: (event: React.MouseEvent, vip: VipBriefing | Vip) => void;
}

const NoticesStep: React.FC<NoticesStepProps> = (props) => {
    
    const [openAddVIPModal, setOpenAddVIPModal] = useState(false);
    const handleAddVIPClick = () => {
        setOpenAddVIPModal(true);
    };

    const [openAddEventModal, setOpenAddEventModal] = useState(false);

    return (
        <div className='pre-briefing-notices-step'>
            {openAddVIPModal && <AddVIPBriefingModal addVip={props.addVip} onClose={() => setOpenAddVIPModal(false)} shift={props.shift} />}
            {openAddEventModal && <AddEventBriefingModal onClose={() => setOpenAddEventModal(false)} addEvent={props.addEvent} />}
            <div className="pre-briefing-notices-top">
                <div className="pre-briefing-notices-top-text-area">
                    <h3 className="pre-briefing-notices-title-h3">Evenementen voor vandaag</h3>
                </div>
                <div className="pre-briefing-notices-top-butts-area">
                    <button className="pre-briefing-notices-add-btn" type='button' onClick={() => setOpenAddEventModal(true)}>
                        <span className="pre-briefing-notices-add-btn-icon-span material-symbols-outlined">add</span>
                        <span className="pre-briefing-notices-add-btn-txt-span">Voeg evenement toe</span>
                    </button>
                </div>
            </div>
            <div className="pre-briefing-notices-events-area">
                <ul>
                    {props.todayEvents.length === 0 ? (
                        <p className='pre-briefing-notices-no-data-txt-p'>Geen evenementen voor vandaag!</p>
                    ) : (
                        props.todayEvents.map((event, index) => {
                            let eventtypeclass;

                            if (event.event_type === 1) {
                                eventtypeclass = 'holidays';
                            } else if (event.event_type === 2) {
                                eventtypeclass = 'wouter_events';
                            } else if (event.event_type === 3) {
                                eventtypeclass = 'vacations';
                            } else if (event.event_type === 4) {
                                eventtypeclass = 'sports';
                            } else if (event.event_type === 5) {
                                eventtypeclass = 'created_events';
                            }

                            return (
                                <li key={index} className={`event-item ${eventtypeclass}`}>
                                    <div className="event-content-inner">
                                        <div className="event-info-area">
                                            {event.name}
                                        </div>
                                        <div className="event-date-info-area">
                                            <p className="event-startdate-info-txt-p">
                                                <strong>
                                                    {new Date(event.date!).toLocaleDateString("nl-NL", { day: "numeric" })} {new Date(event.date!).toLocaleDateString("nl-NL", { month: "long" })}
                                                </strong>
                                            </p>
                                            {event.end_date && (
                                                <p className="event-enddate-info-txt-p">
                                                    t/m {new Date(event.end_date).toLocaleDateString("nl-NL", { day: "numeric" })} {new Date(event.end_date).toLocaleDateString("nl-NL", { month: "long" })}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            );
                        })
                    )}
                </ul>
            </div>
            <div className="pre-briefing-notices-top">
                <div className="pre-briefing-notices-top-text-area">
                    <h3 className="pre-briefing-notices-title-h3">VIP's voor vandaag</h3>
                </div>
                <div className="pre-briefing-notices-top-butts-area">
                    <button className="pre-briefing-notices-add-btn" type='button' onClick={handleAddVIPClick}>
                        <span className="pre-briefing-notices-add-btn-icon-span material-symbols-outlined">add</span>
                        <span className="pre-briefing-notices-add-btn-txt-span">Voeg VIP toe</span>
                    </button>
                </div>
            </div>
            <div className="pre-briefing-notices-vips-area">
                <ul className="pre-briefing-notices-vips-listing-ul">
                    {props.vips.length === 0 ? (
                        <p className='pre-briefing-notices-no-data-txt-p'>Geen VIP's voor vandaag!</p>
                    ) : (
                        props.vips.map((vip, index) => {
                            return (
                                <VipWidgetItem key={index} vip={vip} onVipOptionsClick={props.onVipOptionsClick} />
                                )
                        }))}
                </ul>
            </div>
        </div>
    );
};

export default NoticesStep;