import React, { useEffect, useState } from 'react';
import './AddSubsidiaryView.css';
import { checkLocalStorageItems, logoutUser } from '../../../api/auth/user.auth';
import { useNavigate } from 'react-router-dom';
import Stepper from '../../../components/Portaal/AddSubsidiary/Stepper/Stepper';
import { fetchBranches } from '../../../api/branches/branchesService';
import { Branch } from '../../../models/Branch';
import SelectBranch from './InnerViews/SelectBranch/SelectBranch';
import SelectSubBranch from './InnerViews/SelectSubBranch/SelectSubBranch';
import { Label } from '../../../models/Label';
import { fetchGeneralLabels, fetchLabelsByBranch } from '../../../api/labels/labelsService';
import SelectLabels from './InnerViews/SelectLabels/SelectLabels';
import UploadLogo from './InnerViews/UploadLogo/UploadLogo';
import { MenuType } from '../../../models/MenuType';
import UploadMenu from './InnerViews/UploadMenu/UploadMenu';
import { fetchMenuTypes } from '../../../api/MenuType/MenuTypeServices';
import { CreateSubsidiary } from '../../../models/Subsidiary';
import { getUserInfo } from '../../../api/auth/user-info';
import { UploadLogoModel } from '../../../models/UploadLogo';
import { UploadLogoDB } from '../../../backend/UploadLogo/UploadLogoService';
import { addSubsidiary } from '../../../api/subsidiary/subsidiaryService';
import { MenuCreate } from '../../../models/Menu';
import { Currency } from '../../../enums/Currency';
import { createMenu } from '../../../api/menu/menuService';
import { UploadMenuFile } from '../../../models/UploadMenu';
import { uploadMenu } from '../../../backend/UploadMenu/UploadMenuService';
import { menu } from '@nextui-org/react';
import { addUserToSubsidiary } from '../../../api/users/usersService';
import { Helmet } from 'react-helmet-async';
import usePageTracking from '../../../utils/usePageTracking';

interface AddSubsidiaryViewProps {
    // Define the props for the component here
    setRefreshSubsidiaryKey: React.Dispatch<React.SetStateAction<number>>;
}

const AddSubsidiaryView: React.FC<AddSubsidiaryViewProps> = ({ setRefreshSubsidiaryKey }) => {
    // Implement the component logic here
    usePageTracking();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await logoutUser();
        checkLocalStorageItems().then((result) => {
            console.log(result);
            if (!result) {
                navigate('/login');
            }
        }
        );
    };

    const [step, setStep] = React.useState(1);
    const steps = ['Selecteer branche', 'Selecteer subbranche', 'Selecteer labels', 'Upload logo', 'Upload menu'];
    const [retrievedBranches, setRetrievedBranches] = useState<Branch[]>([]);
    const [branches, setBranches] = React.useState<Branch[]>([]);
    const [subBranches, setSubBranches] = React.useState<Branch[]>([]);

    useEffect(() => {
        const loadBranches = async () => {
            try {
                const branches = await fetchBranches();
                setRetrievedBranches(branches);
                setBranches(branches.filter((branch) => branch.parent_id === null));
            } catch (err) {
                console.error('Failed to fetch branches:', err);
            } finally {
                console.log('Done fetching branches');
            }
        };

        loadBranches();
    }, []);

    const [selectedBranch, setSelectedBranch] = React.useState<string>();
    const [errorSelectedBranch, setErrorSelectedBranch] = React.useState<string>('');

    React.useEffect(() => {
        if (selectedBranch) {
            const parent_id = retrievedBranches.find((branch) => branch.id === Number(selectedBranch!))?.id;
            setSubBranches(retrievedBranches.filter((branch) => branch.parent_id === parent_id));
            console.log('Subbranches:', subBranches);
        }
    }, [selectedBranch]);

    const [selectedSubBranch, setSelectedSubBranch] = React.useState<string>();
    const [errorSelectedSubBranch, setErrorSelectedSubBranch] = React.useState<string>('');

    React.useEffect(() => {
        if (selectedSubBranch) {
            setErrorSelectedSubBranch('');
        }
    }, [selectedSubBranch]);

    const [labels, setLabels] = React.useState<Label[]>([]);
    const [selectedLabelIds, setSelectedLabelIds] = useState<number[]>([]);

    React.useEffect(() => {
        const loadLabels = async () => {
            try {
                const [generalLabels, labelsByBranch] = await Promise.all([
                    fetchGeneralLabels(),
                    fetchLabelsByBranch(Number(selectedSubBranch)),
                ]);
                setLabels([...generalLabels, ...labelsByBranch]);
            } catch (err) {
                console.error('Failed to fetch labels:', err);
            } finally {
                console.log('Done fetching labels');
            }
        };

        if (selectedSubBranch) {
            loadLabels();
        }
    }, [selectedSubBranch]);

    const toggleLabelSelection = (labelId: number) => {
        setSelectedLabelIds(prev => {
            const index = prev.indexOf(labelId);
            if (index > -1) {
                return prev.filter(id => id !== labelId);
            } else {
                return [...prev, labelId];
            }
        });
    };

    const selectedLabels = React.useMemo(() => new Set(selectedLabelIds), [selectedLabelIds]);
    const [errorSelectedLabels, setErrorSelectedLabels] = React.useState<string>('');

    React.useEffect(() => {
        setSelectedLabelIds([]);
    }, [selectedSubBranch]);

    React.useEffect(() => {
        if (selectedLabels.size > 0) {
            setErrorSelectedLabels('');
        }
    }, [selectedLabels]);

    const [file, setFile] = useState<any>(null);
    const [preview, setPreview] = useState<string | null>(null);

    const [logoError, setLogoError] = useState<string>('');

    const [menuTypes, setMenuTypes] = React.useState<MenuType[]>([]);
    const [selectedMenuType, setSelectedMenuType] = React.useState<string>('');
    const [menuFile, setMenuFile] = React.useState<any>(null);
    const [pdfDataUrl, setPdfDataUrl] = React.useState<string | null>(null);

    React.useEffect(() => {
        const loadMenuTypes = async () => {
            try {
                const response = await fetchMenuTypes();
                setMenuTypes(response);
            } catch (err) {
                console.error('Failed to fetch menu types:', err);
            }
        }
        loadMenuTypes();
    }, []);

    const [errorSelectedMenuType, setErrorSelectedMenuType] = React.useState<string>('');

    useEffect(() => {
        if (menuFile !== null) {
            setErrorSelectedMenuType('');
        }
    }, [menuFile]);

    const validateStep = (currentStep: number): boolean => {
        switch (currentStep) {
            case 1:
                let noErrorBranch = true;

                if (!selectedBranch) {
                    setErrorSelectedBranch('Selecteer een branch!');
                    noErrorBranch = false;
                } else {
                    setErrorSelectedBranch('');
                }

                return noErrorBranch;
            case 2:
                let noErrorSubBranch = true;

                if (!selectedSubBranch) {
                    setErrorSelectedSubBranch('Selecteer een subbranch!');
                    noErrorSubBranch = false;
                } else {
                    setErrorSelectedSubBranch('');
                }

                return noErrorSubBranch;
            case 3:
                let noErrorLabels = true;

                if (selectedLabels.size === 0) {
                    setErrorSelectedLabels('Selecteer minstens één label!');
                    noErrorLabels = false;
                } else {
                    setErrorSelectedLabels('');
                }

                return noErrorLabels;
            case 4:
                let noErrorLogo = true;

                if (file === null) {
                    setLogoError('Upload een logo!');
                    noErrorLogo = false;
                } else {
                    setLogoError('');
                }

                return noErrorLogo;
            case 5:
                let noErrorMenu = true;

                if (menuFile !== null) {
                    if (!selectedMenuType) {
                        setErrorSelectedMenuType('Selecteer een menu type!');
                        noErrorMenu = false;
                    } else {
                        setErrorSelectedMenuType('');
                    }
                } else {
                    noErrorMenu = false;
                }
                return noErrorMenu;
            default:
                return false;
        }
    }

    const handleNextStep = () => {
        if (validateStep(step)) {
            setStep(step + 1);
        }
    };

    const handlePreviousStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    let handellingSubmit = false;

    const handleSubmit = async () => {
        if (handellingSubmit) {
            return;
        }
        try {
            handellingSubmit = true;
            const user = getUserInfo();
            const uploadLogo: UploadLogoModel = {
                user_id: user.id,
                file: file
            }
            const response = await UploadLogoDB(uploadLogo);
            const subsidiary: CreateSubsidiary = {
                parent_company_id: user.parent_company.id,
                branch_id: Number(selectedSubBranch),
                logo_id: response.id,
                name: user.parent_company.name,
                street: user.parent_company.street,
                house_number: user.parent_company.house_number,
                postal_code: user.parent_company.postal_code,
                city: user.parent_company.city,
                province: user.parent_company.province,
                country: user.parent_company.country,
                selected_labels: JSON.stringify(Array.from(selectedLabels)),
                opening_hours: {},
                credits: 30
            };
            const addedSubsidiary = await addSubsidiary(subsidiary);
            window.localStorage.setItem('subsidiary', JSON.stringify(addedSubsidiary));
            user.parent_company.subsidiaries.push(addedSubsidiary);
            user.subsidiaries.push(addedSubsidiary);
            window.localStorage.setItem('user_info', JSON.stringify(user));
            if (addedSubsidiary.id && menuFile !== null) {
                const menuCreate: MenuCreate = {
                    subsidiary_id: addedSubsidiary.id,
                    menu_type_id: Number(selectedMenuType),
                    currency: Currency.EUROS,
                    is_complete: false
                };
                const addMenu = await createMenu(menuCreate);
                const uploadingMenu: UploadMenuFile = {
                    subsidiary_id: addedSubsidiary.id,
                    user_id: user.id,
                    menu_id: addMenu.id,
                    file: menuFile
                };
                await uploadMenu(uploadingMenu);
                handellingSubmit = false;
            }

            await addUserToSubsidiary(addedSubsidiary.id, user.id);
        } catch (e) {
            console.error('Failed creating subsidiary: ', e);
        } finally {
            setRefreshSubsidiaryKey((prev: number) => prev + 1);
            navigate('/portaal/dashboard');
        }

    }

    return (
        <div className='add-subsidiary-view'>
            <Helmet>
                <title>10-Things | Portaal | Voeg vestiging toe</title>
            </Helmet>
            <nav className="add-subsidiary-nav">
                <ul className="add-subsidiary-nav-listing-ul">
                    <li className="add-subsidiary-left-li">
                        <button className="add-subsidiary-exit-btn" onClick={handleLogout}>
                            <span className="add-subsidiary-exit-icon-span material-symbols-rounded">arrow_back</span>
                        </button>
                    </li>
                    <li className="add-subsidiary-center-li">
                        <Stepper steps={steps} currentStep={step} />
                    </li>
                    <li className="add-subsidiary-right-li">
                    </li>
                </ul>
            </nav>
            <div className="add-subsidiary-content-area">
                {step === 1 && (<SelectBranch branches={branches} selectedBranch={selectedBranch} setSelectedBranch={setSelectedBranch} errorSelectedBranch={errorSelectedBranch} />)}
                {step === 2 && (<SelectSubBranch subBranches={subBranches} selectedSubBranch={selectedSubBranch} setSelectedSubBranch={setSelectedSubBranch} errorSelectedSubBranch={errorSelectedSubBranch} />)}
                {step === 3 && (<SelectLabels labels={labels} selectedLabels={selectedLabels} toggleLabelSelection={toggleLabelSelection} errorSelectedLabels={errorSelectedLabels} />)}
                {step === 4 && (<UploadLogo file={file} setFile={setFile} preview={preview} setPreview={setPreview} logoError={logoError} />)}
                {step === 5 && (<UploadMenu menuTypes={menuTypes} setMenuTypes={setMenuTypes} selectedMenuType={selectedMenuType} setSelectedMenuType={setSelectedMenuType} file={menuFile} setFile={setMenuFile} pdfDataUrl={pdfDataUrl} setPdfDataUrl={setPdfDataUrl} errorSelectedMenuType={errorSelectedMenuType} />)}
                <div className="add-subsidiary-controls-area">
                    <div className="form-control-buttons">
                        {step > 1 && <button className='half-submit-buttons' onClick={handlePreviousStep} type='button'><span className="material-symbols-rounded submit-icon" translate='no'>arrow_back</span></button>}
                        {step < 5 && <button className='half-submit-buttons' onClick={handleNextStep} type='button'><span className="material-symbols-rounded submit-icon" translate='no'>arrow_forward</span></button>}
                        {step == 5 && <button className='half-submit-buttons' onClick={handleSubmit} type='button'>Voltooien</button>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSubsidiaryView;