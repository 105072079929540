import React, { useEffect, useRef, useCallback, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';
import { RenderParameters } from 'pdfjs-dist/types/src/display/api';

pdfjsLib.GlobalWorkerOptions.workerSrc = `/assets/pdfLib/pdf.worker.min.mjs`;

interface PDFViewerProps {
  url: string;
  scale?: number;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ url, scale = 1 }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const renderTaskRef = useRef<pdfjsLib.RenderTask | null>(null);
  const [pdfDocument, setPdfDocument] = useState<pdfjsLib.PDFDocumentProxy | null>(null);

  const renderPDF = useCallback(async () => {
    const container = containerRef.current;
    if (!container) return;

    // Remove any existing canvas
    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }

    // Create a new canvas element
    const canvas = document.createElement('canvas');
    container.appendChild(canvas);

    // Cancel any ongoing render task
    if (renderTaskRef.current) {
      try {
        await renderTaskRef.current.cancel();
      } catch (error) {
        console.error('Error during cancellation:', error);
      }
      renderTaskRef.current = null;
    }

    try {
      const pdf = pdfDocument || await pdfjsLib.getDocument(url).promise;
      if (!pdfDocument) {
        setPdfDocument(pdf);
      }
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale });

      const context = canvas.getContext('2d');
      const parentWidth = container.clientWidth || viewport.width;
      const ratio = viewport.height / viewport.width;
      canvas.width = parentWidth;
      canvas.height = parentWidth * ratio;

      const renderContext = {
        canvasContext: context,
        viewport: page.getViewport({ scale: canvas.width / viewport.width }),
      };

      console.log('Rendering PDF with the following parameters:');
      console.log('Viewport:', viewport);
      console.log('Canvas width:', canvas.width);
      console.log('Canvas height:', canvas.height);

      if (context) {
        const renderTask = page.render(renderContext as RenderParameters);
        renderTaskRef.current = renderTask;
        await renderTask.promise;
        renderTaskRef.current = null;
        console.log('Rendering completed successfully');
      }
    } catch (error) {
      if ((error as Error).name === 'RenderingCancelledException') {
        console.log('Rendering cancelled');
      } else {
        console.error('Rendering error:', error);
      }
    }
  }, [url, scale, pdfDocument]);

  useEffect(() => {
    let cancelled = false;

    const loadPDF = async () => {
      if (!cancelled) {
        await renderPDF();
      }
    };

    loadPDF();

    const handleResize = () => {
      renderPDF();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      cancelled = true;
      window.removeEventListener('resize', handleResize);
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }
    };
  }, [url, scale, renderPDF]);

  useEffect(() => {
    // Reset the PDF document if the URL changes
    setPdfDocument(null);
  }, [url]);

  return <div ref={containerRef}></div>;
};

export default PDFViewer;