import React from 'react';
import './ThingsList.css';
import ActionItem from '../ActionItem/ActionItem';
import { Action } from '../../../models/Action';
import { ThingType } from '../../../models/ThingType';

interface Things {
    onActionOptionsClick: (event: React.MouseEvent, action: Action) => void;
    thingtypes: { id: number; plural: string, singular: string, color: string, }[];
    actions: Action[];
    onClickAddButton: (thingType: ThingType) => void;
    onClickAction: (action: Action) => void;
}

const ThingsList: React.FC<Things> = (props) => (
    <div id='thingslist-container'>
        {props.thingtypes.map(thingtype => (
            <div key={thingtype.id} className='thinglist-type-column'>
                <div className="thinglist-inner-container">
                    <h1 className='thingtype-title-h1'>{thingtype.plural}</h1>
                    {/* <span className="thingtype-info-icon-span material-symbols-outlined">info</span> */}
                </div>
                <ul className="thinglist-listing-ul">
                    {props.actions.filter((action, index) => action.thing_type_id === thingtype.id && action.completed_on === null).map((action, index) => (
                        <ActionItem key={index} action={action} backgroundColor={thingtype.color} onActionOptionsClick={props.onActionOptionsClick} onClickAction={props.onClickAction} />))}
                    {/* {props.actions.filter((action, index) => action.thing_type_id === thingtype.id && action.completed_on === null).length < 2 && (
                        <div className="add-action-btn-wrapper">
                            <button className="add-action-btn" onClick={() => props.onClickAddButton(thingtype)}>
                                <span className="add-action-icon-span material-symbols-outlined">add</span>
                            </button>
                        </div>
                    )} */}
                </ul>
            </div>
        ))}
    </div>
);

export default ThingsList;