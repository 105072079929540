import React from 'react';
import './NotesListV2.css';
import { deleteNote } from '../../../api/notes/notesService';
import NoteItem from './NoteItem/NoteItem';
import { Shift } from '../../../models/Shift';


interface NoteListV2props {
    onClickAddNoteButton: () => void;
    onClickNote: (note: any) => void;
    onDeleteNote: (note_id: number) => void;
    notes: any[];
    activeShift: Shift | null;
}

const NotesListV2: React.FC<NoteListV2props> = (props, activeShift) => {

    const onClickNoteDone = (event: React.MouseEvent, id: number) => {
        event.stopPropagation();
        console.log('Note done:', id);
        props.onDeleteNote(id);
        deleteNote(id);
    }

    return (
        <div id='notes-list-container-div'>
            <div id="notes-list-title-area-div">
                <div id="notes-list-inner-title-area">
                    <h2 id='notes-list-title-h2'>Notities</h2>
                </div>
            </div>
            <ul id='notes-list-listing-ul'>
                {activeShift !== null ? (props.notes.map((note: any, index: number) => (
                    <NoteItem key={index} note={note} onClickNote={props.onClickNote} onClickNoteDone={onClickNoteDone} />
                ))) : (<p className='no-notes-found-txt-p'>

                    Start je briefing in het dashboard. 


                </p>)}
                
            </ul>
        </div>
    );
};

export default NotesListV2;