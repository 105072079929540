import React from 'react';
import PageLayout from '../../components/Site/PageLayout/PageLayout';
import './GeneralTermsView.css';
import usePageTracking from '../../utils/usePageTracking';
import { Helmet } from 'react-helmet-async';

const GeneralTermsView: React.FC = () => {
    usePageTracking();
    return (
        <PageLayout>
            <Helmet>
                <title>10-Things | Algemene Voorwaarden</title>
            </Helmet>
            <div className="inner-div">
                <header>
                    <h1 className='general-terms-title-h1'>Algemene voorwaarden - MOGEE B.V.</h1>
                </header>
                <section>
                    <p className='general-terms-txt-p'>Deze algemene voorwaarden zijn van toepassing op alle aanbiedingen, offertes en diensten van
                        MOGEE B.V. en maken integraal onderdeel uit van iedere overeenkomst tussen u en MOGEE B.V. Mocht
                        u na het lezen van deze algemene voorwaarden vragen of opmerkingen hebben, neem dan gerust
                        contact met ons op.</p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Contactgegevens</h3>
                    <p className='general-terms-txt-p'>Statutaire naam: MOGEE B.V.</p>
                    <p className='general-terms-txt-p'>KVK: 85393401</p>
                    <p className='general-terms-txt-p'>Vestigingsadres: Columbusplein 54 H, 1057 VC Amsterdam, Nederland</p>
                    <p className='general-terms-txt-p'>Kantooradres: Columbusplein 54 H, 1057 VC Amsterdam, Nederland</p>
                    <p className='general-terms-txt-p'>Website: <a href="http://www.10-things.nl">www.10-things.nl</a></p>
                    <p className='general-terms-txt-p'>E-mail: <a href="mailto:info@10-things.nl">info@10-things.nl</a></p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 1 - Definities</h3>
                    <p className='general-terms-txt-p'>De volgende definities worden gebruikt in deze voorwaarden:</p>
                    <p className='general-terms-txt-p'>1.1 De begrippen en definities die in de Overeenkomst met een hoofdletter zijn geschreven hebben
                        de betekenis die daaraan hieronder is gegeven:</p>
                    <ul className='general-terms-listing-ul'>
                        <li className="general-terms-item-li"><b>Aanbod:</b> Enig aanbod (ongeacht de vorm) van 10-THINGS in het kader van leveren van
                            diensten, waaronder onder meer begrepen een aanbod in de vorm van een offerte of een
                            algemeen aanbod dat online wordt gedaan en aldaar kan worden aanvaard.</li>
                        <li className="general-terms-item-li"><b>Account:</b> Een combinatie van een inlognaam en wachtwoord voor het persoonlijke account
                            van Afnemer dan wel diens Eindgebruiker waarmee hij of zij toegang krijgt tot de Applicatie.
                        </li>
                        <li className="general-terms-item-li"><b>Afnemer:</b> De natuurlijke persoon of rechtspersoon met wie 10-THINGS een Overeenkomst
                            heeft gesloten.</li>
                        <li className="general-terms-item-li"><b>Algemene Voorwaarden:</b> De onderhavige algemene voorwaarden.</li>
                        <li className="general-terms-item-li"><b>Eindgebruiker:</b> De natuurlijke persoon die door de Afnemer geautoriseerd is de
                            Applicatie te gebruiken.</li>
                        <li className="general-terms-item-li"><b>10-THINGS:</b> De onderneming MOGEE B.V., gevestigd aan Columbusplein 54 H te Wageningen
                            en ingeschreven van de Kamer van Koophandel onder nummer 85393401.</li>
                        <li className="general-terms-item-li"><b>Intellectuele Eigendomsrechten:</b> Alle rechten van intellectuele eigendom en
                            aanverwante rechten, waaronder begrepen maar niet beperkt tot auteursrechten,
                            databankrechten, domeinnamen, handelsnaamrechten, merkrechten, modelrechten, naburige
                            rechten, octrooirechten, alsmede rechten op knowhow.</li>
                        <li className="general-terms-item-li"><b>Kantooruren:</b> De uren op een werkdag tussen 08:00 tot 17:00 uur (Nederlandse tijd).
                        </li>
                        <li className="general-terms-item-li"><b>Licentie:</b> Een beperkt gebruiksrecht voor de Afnemer voor het gebruik van de
                            Applicatie.</li>
                        <li className="general-terms-item-li"><b>Module:</b> Een Eindgebruiker van Afnemer die gebruik maakt van de extra betaalde
                            functionaliteit die de Applicatie biedt waarvoor Verbruikskosten in rekening worden
                            gebracht.</li>
                        <li className="general-terms-item-li"><b>Overeenkomst:</b> De overeenkomst die tot stand komt na aanvaarding van een Aanbod door
                            de Afnemer, waar de onderhavige Algemene Voorwaarden een onlosmakelijk onderdeel van
                            uitmaken en op basis waarvan 10-THINGS diensten zal verlenen aan de Afnemer.</li>
                        <li className="general-terms-item-li"><b>Partij:</b> Een partij bij de Overeenkomst.</li>
                        <li className="general-terms-item-li"><b>Applicatie:</b> De applicatie dat online benaderbaar is via 10-THINGS.nl en waartoe
                            toegang verkregen kan worden door in te loggen op een Account.</li>
                        <li className="general-terms-item-li"><b>Schriftelijk:</b> Op papier alsmede in elektronische vorm (bijvoorbeeld via de
                            Applicatie) mits de identiteit van de afzender en integriteit van het bericht voldoende
                            vaststaat. Waar het begrip Schriftelijk in de context van ingebrekestelling en ontbinding
                            van de Overeenkomst wordt gebezigd, wordt daarmee ten aanzien van de Afnemer uitsluitend op
                            papier bedoeld.</li>
                        <li className="general-terms-item-li"><b>Verbruikskosten:</b> Het aantal keer dat een Module is gebruikt die in aanmerking komt
                            voor een vergoeding.</li>
                        <li className="general-terms-item-li"><b>Vertrouwelijke Informatie:</b> Alle niet-publieke informatie die gerelateerd is aan een
                            of beide Partijen en waarvan een Partij aangeeft dat deze informatie vertrouwelijk is, of
                            die, naar de aard van de informatie of onder de omstandigheden waaronder de bekendmaking
                            plaatsvindt, als vertrouwelijk dient te worden behandeld of die als vertrouwelijk is
                            gemarkeerd.</li>
                        <li className="general-terms-item-li"><b>Werkdag:</b> maandag tot en met vrijdag, met uitzondering van de volgens de Nederlandse
                            Algemene termijnenwet erkende feestdagen en de dagen waarvan 10-THINGS vooraf, al dan niet
                            via haar website of e-mail, heeft aangegeven gesloten te zijn.</li>
                    </ul>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 2 - Toepasselijkheid</h3>
                    <p className='general-terms-txt-p'>2.1 De Algemene Voorwaarden zijn van toepassing op ieder Aanbod van 10-THINGS en de uitvoering
                        van (toekomstige) Overeenkomsten.</p>
                    <p className='general-terms-txt-p'><b>2.2</b> De toepasselijkheid van eventuele inkoop- of andere voorwaarden van de Afnemer
                        wordt uitdrukkelijk uitgesloten. Dergelijke voorwaarden zijn uitsluitend van toepassing
                        indien deze middels ondertekening door 10-THINGS zijn aanvaard.</p>
                    <p className='general-terms-txt-p'><b>2.3</b> In geval van onderlinge strijdigheid tussen de toepasselijke documenten,
                        bepalingen en definities opgenomen in de verschillende onderdelen van de Overeenkomst, geldt
                        de volgende rangorde, waarbij het eerder genoemde document voorrang heeft op het later
                        genoemde document: </p>
                    <ul className='general-terms-listing-ul'>
                        <li className="general-terms-item-li"><b>2.3.1</b> Aanbod</li>
                        <li className="general-terms-item-li"><b>2.3.2</b> Eventuele bijlagen bij de Overeenkomst</li>
                        <li className="general-terms-item-li"><b>2.3.3</b> De Algemene Voorwaarden</li>
                        <li className="general-terms-item-li"><b>2.3.4</b> Eventuele door 10-THINGS ondertekende inkoop- of andere voorwaarden van
                            de Afnemer.</li>
                    </ul>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 3 - Totstandkoming Overeenkomst</h3>
                    <p className='general-terms-txt-p'><b>3.1</b> Ieder Aanbod dat gedaan wordt in de vorm van een offerte van 10-THINGS is geheel
                        vrijblijvend en geldig voor de duur van dertig (30) dagen na dagtekening, tenzij op de
                        offerte een andere geldigheidsduur staat vermeld. Indien dagtekening ontbreekt vangt
                        voornoemde periode aan op de dag dat 10-THINGS de offerte aan de Afnemer heeft toegezonden.
                    </p>
                    <p className='general-terms-txt-p'><b>3.2</b> De Overeenkomst komt tot stand door aanvaarding van een Aanbod door de Afnemer.
                    </p>
                    <p className='general-terms-txt-p'><b>3.3</b> Een Overeenkomst komt tevens tot stand door het aanmaken van een Account op de
                        Applicatie van 10-THINGS.</p>
                    <p className='general-terms-txt-p'><b>3.4</b> 10-THINGS is alleen gebonden aan een door de Afnemer gedane, al dan niet op
                        ondergeschikte punten, afwijkende aanvaarding van een Aanbod, indien 10-THINGS de afwijkende
                        aanvaarding Schriftelijk uitdrukkelijk accepteert. Het voorgaande doet geen afbreuk aan wat
                        in Artikel 2.2 is bepaald.</p>
                    <p className='general-terms-txt-p'><b>3.5</b> Alle prijzen in ieder Aanbod zijn onder voorbehoud van programmeer- en
                        typefouten. Indien een prijs in een Aanbod is gebaseerd op door de Afnemer verstrekte
                        gegevens en deze gegevens blijken onjuist te zijn, dan heeft 10-THINGS het recht de prijzen
                        hierop aan te passen, ook nadat de Overeenkomst reeds tot stand is gekomen.</p>
                    <p className='general-terms-txt-p'><b>3.6</b> De toepasselijkheid van artikel 6:227b lid 1 en 6:227c van het Burgerlijk Wetboek
                        is uitgesloten.</p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 4 - Uitvoering van de Overeenkomst</h3>
                    <p className='general-terms-txt-p'><b>4.1</b> Na totstandkoming van de Overeenkomst, zal 10-THINGS zich inspannen om zo spoedig
                        mogelijk te starten met de uitvoering van de Overeenkomst en zal daarbij de zorg van een
                        goed opdrachtnemer in acht nemen. Door 10-THINGS gecommuniceerde of tussen Partijen
                        overeengekomen (oplever)termijnen zijn indicatief en gelden nimmer als fatale termijnen.
                    </p>
                    <p className='general-terms-txt-p'><b>4.2</b> De Afnemer zal aan 10-THINGS alle ondersteuning bieden die nodig en wenselijk is
                        om een juiste uitvoering van de Overeenkomst mogelijk te maken. In ieder geval zal de
                        Afnemer aan 10-THINGS alle informatie verstrekken waarvan 10-THINGS aangeeft dat deze
                        noodzakelijk is, of waarvan de Afnemer redelijkerwijs moet begrijpen dat deze noodzakelijk
                        is voor de correcte uitvoering van de Overeenkomst. 10-THINGS heeft het recht, maar niet de
                        plicht, om deze informatie op juistheid en volledigheid te controleren.</p>
                    <p className='general-terms-txt-p'><b>4.3</b> Indien de Afnemer niet de hiervoor beschreven medewerking verleent, dan wel
                        indien blijkt dat de door de Afnemer verstrekte informatie onjuist of onvolledig is, heeft
                        10-THINGS het recht om de Overeenkomst op te schorten totdat de Afnemer alsnog de gevraagde
                        medewerking heeft verleend of de benodigde informatie heeft verstrekt. Eventuele door de
                        Afnemer periodieke vergoedingen blijven gedurende deze periode onverminderd verschuldigd.
                    </p>
                    <p className='general-terms-txt-p'><b>4.4</b> 10-THINGS heeft het recht om derden in te schakelen bij de uitvoering van de
                        Overeenkomst. Eventuele hiermee verband houdende kosten komen alleen voor rekening van de
                        Afnemer als Partijen dat zijn overeengekomen.</p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 5 - Toegang en gebruik van de Applicatie</h3>
                    <p className='general-terms-txt-p'><b>5.1</b> Om toegang tot de Applicatie te verkrijgen heeft elke Eindgebruiker van Afnemer
                        een Licentie nodig. Een Licentie geeft de Afnemer het niet-exclusieve recht om de Applicatie
                        als online-dienst (Software-as-a-Service) te gebruiken voor de duur van de Licentie en
                        uitsluitend voor het onder de Overeenkomst beoogde gebruik.</p>
                    <p className='general-terms-txt-p'><b>5.2</b> De Licentie mag uitsluitend gebruikt worden voor eigen doeleinden binnen de eigen
                        organisatie van de Afnemer. Licenties mogen niet en kunnen vanuit goederenrechtelijk
                        perspectief niet worden overgedragen aan derden, behoudens Schriftelijke uitdrukkelijke
                        toestemming van 10-THINGS.</p>
                    <p className='general-terms-txt-p'><b>5.3</b> Tenzij Schriftelijk anders overeengekomen geldt het volgende:
                        <ul>
                            <li className="general-terms-item-li"><b>5.3.1</b> een Licentie vangt aan op de dag dat 10-THINGS toegang tot de
                                Applicatie verstrekt aan Afnemer (bijv. middels het verstrekken van inloggegevens
                                voor een Account en/of de mededeling dat Afnemer zelf een Account kan gaan
                                aanmaken);</li>
                            <li className="general-terms-item-li"><b>5.3.2</b> een Licentie heeft een looptijd van minimaal van één (1) maand;</li>
                            <li className="general-terms-item-li"><b>5.3.3</b> na afloop van de overeengekomen looptijd van de Licentie wordt de
                                Licentie automatisch en stilzwijgend verlengd met één maand;</li>
                            <li className="general-terms-item-li"><b>5.3.4</b> een Licentie kan tegen het einde van de dan lopende periode door beide
                                Partijen Schriftelijk worden opgezegd met inachtneming van een opzegtermijn van drie
                                (3) maanden (waarmee eventuele stilzwijgende verlenging wordt voorkomen).</li>
                        </ul>
                    </p>
                    <p className='general-terms-txt-p'><b>5.4</b> Naast Licenties kan Afnemer kiezen om gebruik te maken van Modules waarvoor
                        Verbruikskosten in rekening worden gebracht.</p>
                    <p className='general-terms-txt-p'><b>5.5</b> De Overeenkomst alsmede daaronder verstrekte Licenties kunnen door de Afnemer
                        niet tussentijds worden opgezegd, behoudens Schriftelijke instemming van 10-THINGS. Artikel
                        7:408 lid 1 BW is niet van toepassing op de Overeenkomst.</p>
                    <p className='general-terms-txt-p'><b>5.6</b> Na einde van de Licentie zal 10-THINGS de toegang tot de Applicatie beëindigen en
                        gerechtigd zijn alle aan Afnemer gerelateerde data die binnen de Applicatie aanwezig is, te
                        verwijderen, behoudens andersluidende afspraken.</p>
                    <p className='general-terms-txt-p'><b>5.7</b> Voor elke Licentie zal 10-THINGS aan de Afnemer logingegevens voor een
                        (administrator-) Account ter beschikking stellen, dan wel aan de Afnemer de mogelijkheid
                        bieden om zelfstandig een Account aan te maken. Indien overeengekomen kan de Afnemer met
                        diens Account sub-Accounts voor andere Eindgebruikers aanmaken. Voor dergelijke sub-Accounts
                        gelden dezelfde voorwaarden als voor gewone Accounts.</p>
                    <p className='general-terms-txt-p'><b>5.8</b> Een Account is strikt persoonlijk en mag niet worden gedeeld met derden.
                        Logingegevens dienen te allen tijde geheim gehouden te worden. De Afnemer is verplicht voor
                        ieder Account een voldoende sterk wachtwoord te hanteren, en een eventueel door 10-THINGS
                        aangeleverd standaardwachtwoord onverwijld te wijzigen.</p>
                    <p className='general-terms-txt-p'><b>5.9</b> 10-THINGS is niet verantwoordelijk voor misbruik van Accounts en mag ervan
                        uitgaan dat de persoon die inlogt op een Account ook daadwerkelijk een namens de Afnemer
                        geautoriseerde Eindgebruiker betreft. 10-THINGS mag erop vertrouwen dat alle handelingen die
                        vanuit een Account worden verricht, worden uitgevoerd onder leiding, toezicht en met
                        goedkeuring van de Afnemer.</p>
                    <p className='general-terms-txt-p'><b>5.10</b> De Afnemer is, tenzij anders overeengekomen, zelf verantwoordelijk voor het
                        eindgebruikersbeheer, het toekennen of intrekken van rechten en het aanmaken of verwijderen
                        van Accounts – een en ander voor zover deze mogelijkheden inbegrepen zijn onder de
                        Overeenkomst.</p>
                    <p className='general-terms-txt-p'><b>5.11</b> Als logingegevens van een Account (vermoedelijk) zijn kwijtgeraakt of uitgelekt,
                        zal de Afnemer direct alle maatregelen nemen die redelijkerwijs nodig, wenselijk en mogelijk
                        zijn om misbruik van het Account te voorkomen. Deze maatregelen kunnen bijvoorbeeld bestaan
                        uit het wijzigen van het bijbehorende wachtwoord. In ieder geval zal de Afnemer hiervan
                        direct melding maken bij 10-THINGS, zodat er eventueel aanvullende maatregelen genomen
                        kunnen worden ter voorkoming van misbruik van het Account.</p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 6 - Gebruiksregels</h3>
                    <p className='general-terms-txt-p'><b>6.1</b> Het is de Afnemer niet toegestaan om de onder de Overeenkomst geleverde
                        diensten, in het bijzonder de Applicatie, te gebruiken of in te zetten in strijd is met
                        toepasselijke wet- of regelgeving of de Overeenkomst.</p>

                    <p className='general-terms-txt-p'><b>6.2</b> Het is de Afnemer niet toegestaan om via de Applicatie materialen aan te
                        bieden of te verspreiden die onrechtmatig van aard zijn, inbreuk maken op rechten van derden
                        zoals Intellectuele Eigendomsrechten, dan wel lasterlijk, beledigend, discriminerend of
                        haatzaaiend zijn, een schending van de persoonlijke levenssfeer van derden opleveren, waaronder
                        in ieder geval maar niet uitsluitend begrepen het zonder toestemming of noodzaak verspreiden van
                        persoonsgegevens van derden.</p>

                    <p className='general-terms-txt-p'><b>6.3</b> De Afnemer onthoudt zich ervan andere klanten van 10-THINGS, waaronder
                        andere gebruikers van de Applicatie, of andere internetgebruikers in het algemeen te hinderen,
                        of schade toe te brengen aan systemen of netwerken van (de toeleveranciers van) 10-THINGS.
                        Indien handelingen van de Afnemer naar het oordeel van 10-THINGS hinder, schade of een ander
                        gevaar opleveren voor het functioneren van de systemen of netwerken van 10-THINGS, in het
                        bijzonder als gevolg van het overmatig verzenden van gegevens, (distributed)
                        denial-of-service-aanvallen, slecht beveiligde systemen of activiteiten van virussen, Trojans of
                        vergelijkbare software, is 10-THINGS gerechtigd alle maatregelen te nemen die zij redelijkerwijs
                        nodig acht om dit gevaar af te wenden dan wel te voorkomen.</p>

                    <p className='general-terms-txt-p'><b>6.4</b> Indien een derde 10-THINGS erop wijst dat, via de systemen die onderdeel
                        uitmaken van de geleverde diensten, door of namens de Afnemer materialen of andere informatie
                        wordt opgeslagen of verspreid waarmee volgens die derde inbreuk wordt gemaakt op rechten van die
                        derde of waardoor anderszins onrechtmatig wordt gehandeld, dan zal 10-THINGS de Afnemer op de
                        hoogte stellen van desbetreffende melding. De Afnemer dient vervolgens zo spoedig mogelijk, maar
                        uiterlijk binnen achtenveertig (48) uur, een gemotiveerde Schriftelijke reactie te geven
                        strekkende tot weerlegging van de melding of klacht, waarna 10-THINGS zelfstandig zal besluiten
                        welke maatregelen er genomen worden. Maatregelen kunnen onder meer bestaan uit het permanent
                        verwijderen van, of toegang beperken tot de materialen of informatie waar de klacht betrekking
                        op heeft. In gevallen die naar het oordeel van 10-THINGS spoedeisend zijn kan 10-THINGS direct
                        ingrijpen zonder de Afnemer voorafgaand op de hoogte te hoeven stellen. Echter zal 10-THINGS
                        zich dan alsnog inspannen de Afnemer zo spoedig mogelijk achteraf in te lichten over de
                        getroffen maatregelen en de aanleiding daartoe.</p>

                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 7 - Adviezen</h3>
                    <p className='general-terms-txt-p'><b>7.1</b> 10-THINGS kan indien daartoe opdracht is gegeven een advies, plan van
                        aanpak, ontwerp, rapportage, planning en/of verslaglegging ten behoeve van de dienstverlening
                        opstellen. De inhoud hiervan is niet bindend en slechts adviserend van aard, doch zal 10-THINGS
                        de op haar rustende zorgplichten in acht nemen. Afnemer beslist zelf en op eigen
                        verantwoordelijkheid of zij de adviezen opvolgt.</p>

                    <p className='general-terms-txt-p'><b>7.2</b> Afnemer is op eerste verzoek van 10-THINGS verplicht om door haar verstrekte
                        voorstellen te beoordelen. Indien 10-THINGS wordt vertraagd in haar werkzaamheden, doordat
                        Afnemer niet of niet tijdig een beoordeling geeft op een door 10-THINGS gedaan voorstel, is
                        Afnemer te allen tijde zelf verantwoordelijk voor de hierdoor ontstane gevolgen, zoals
                        vertraging.</p>

                    <p className='general-terms-txt-p'><b>7.3</b> De aard van de dienstverlening brengt met zich mee dat het resultaat te
                        allen tijde afhankelijk is van externe factoren die de rapportages en adviezen van 10-THINGS
                        kunnen beïnvloeden, zoals de kwaliteit, juistheid en tijdige aanlevering van benodigde
                        informatie en gegevens van Afnemer en diens medewerkers. Afnemer staat in voor de kwaliteit en
                        voor de tijdige en juiste aanlevering van de benodigde gegevens en informatie.</p>

                    <p className='general-terms-txt-p'><b>7.4</b> Afnemer zal 10-THINGS schriftelijk voorafgaand aan de aanvang van de
                        werkzaamheden alle omstandigheden melden die van belang zijn of kunnen zijn met inbegrip van
                        enige punten en prioriteiten waarvoor Afnemer aandacht wenst.</p>

                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 8 - Onderhoud</h3>
                    <p className='general-terms-txt-p'><b>8.1</b> 10-THINGS behoudt zich het recht voor de Applicatie tijdelijk buiten gebruik
                        te stellen ten behoeve van onderhoud. 10-THINGS zal zich inspannen een dergelijke
                        buitengebruikstelling zoveel mogelijk buiten Kantooruren te laten plaatsvinden en de Afnemer
                        tijdig van tevoren op de hoogte stellen van de geplande buitengebruikstelling. In het geval dat
                        10-THINGS van mening is dat een buitengebruikstelling van de Applicatie – al dan niet tijdens
                        Kantooruren – noodzakelijk is voor de veilige werking ervan, dan is zij gerechtigd de Applicatie
                        per direct buiten gebruik te stellen zonder voorafgaande kennisgeving aan de Afnemer zodat
                        10-THINGS zo spoedig mogelijk passende maatregelen kan en zal treffen. 10-THINGS zal wegens
                        buitengebruikstelling als bedoeld in dit lid, nimmer tot enige schadevergoeding jegens de
                        Afnemer gehouden zijn.</p>

                    <p className='general-terms-txt-p'><b>8.2</b> 10-THINGS heeft het recht de Applicatie van tijd tot tijd aan te passen
                        onder meer om de functionaliteit te verbeteren en om fouten te herstellen, of om aspecten van
                        een Applicatie niet langer aan te bieden. Als voorgaande leidt tot een aanzienlijke vermindering
                        in de functionaliteit, zal 10-THINGS de Afnemer daarvan voor doorvoering van de wijziging
                        Schriftelijk of via de Applicatie op de hoogte stellen. Omdat de Applicatie aan meerdere
                        afnemers wordt geleverd, is het niet mogelijk om alleen voor de Afnemer van een bepaalde
                        aanpassing af te zien. Indien een wijziging leidt tot verlies van een voor de Afnemer essentiële
                        functionaliteit, dan verkrijgt de Afnemer het recht om de Overeenkomst Schriftelijk op te zeggen
                        tegen het moment waarop de wijziging in werking treedt op voorwaarde dat de Afnemer 10-THINGS
                        vooraf Schriftelijk op de hoogte heeft gesteld voornemens te zijn gebruik te willen maken van
                        dit opzegrecht.</p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 9 - Beschikbaarheid van de Applicatie</h3>
                    <p className='general-terms-txt-p'><b>9.1</b> 10-THINGS zal zich inspannen om een ononderbroken beschikbaarheid (7 dagen
                        per week, 24 uur per dag) van de Applicatie te realiseren.</p>

                    <p className='general-terms-txt-p'><b>9.2</b> 10-THINGS zal zich inspannen om in geval van het niet beschikbaar zijn van
                        de Applicatie, door storingen, onderhoud of andere oorzaken, de Afnemer te informeren over de
                        aard en de verwachte duur van de onderbreking.</p>

                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 10 - Ondersteuning</h3>
                    <p className='general-terms-txt-p'><b>10.1</b> Uitsluitend indien overeengekomen, zal 10-THINGS gehouden zijn de Afnemer
                        en diens Eindgebruikers middels een helpdesk op afstand te voorzien van ondersteuning in het
                        kader van het gebruik van de Applicatie. Enige vorm van ondersteuning wordt verleend middels de
                        daarvoor door 10-THINGS ingezette communicatiemiddelen welke van tijd tot tijd kunnen wijzigen.
                        10-THINGS zal zich inspannen om eventuele verzoeken adequaat en binnen een redelijke termijn te
                        behandelen.</p>

                    <p className='general-terms-txt-p'><b>10.2</b> 10-THINGS kan redelijke beperkingen stellen aan het gebruik van de
                        aangeboden vormen van ondersteuning. Daarnaast staat het 10-THINGS vrij om op elk moment de
                        beschikbaarheid en reactietijden van de helpdesk nader vast te stellen en/of te wijzigen.</p>

                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 11 - Vergoeding</h3>
                    <p className='general-terms-txt-p'><b>11.1</b> Tenzij uitdrukkelijk anders vermeld, zijn alle door 10-THINGS genoemde
                        prijzen in euro en exclusief omzetbelasting en andere heffingen welke van overheidswege worden
                        opgelegd.</p>

                    <p className='general-terms-txt-p'><b>11.2</b> 10-THINGS is gerechtigd de door haar gehanteerde tarieven:</p>
                    <ol type="a">
                        <li className="general-terms-item-li">eenmaal per jaar te indexeren conform de meest toepasselijke dienstenprijsindex (DPI) van
                            het CBS, mits 10-THINGS de Afnemer ten minste een (1) maand voor inwerkingtreding van de
                            tarievenwijziging daarvan Schriftelijk op de hoogte stelt;</li>
                        <li className="general-terms-item-li">tussentijds te wijzigen als de tarieven van haar toeleveranciers van bijvoorbeeld stroom,
                            datacenter, software en (public) cloud-oplossingen daar redelijkerwijs aanleiding toe geven,
                            mits 10-THINGS de Afnemer ten minste een (1) maand voor inwerkingtreding van de
                            tarievenwijziging daarvan Schriftelijk op de hoogte stelt;</li>
                        <li className="general-terms-item-li">tussentijds, waaronder op ieder verlengmoment van een Licentie te wijzigen, ongeacht de
                            reden daartoe, mits 10-THINGS de Afnemer ten minste twee (2) maanden voor inwerkingtreding
                            van de tarievenwijziging daarvan Schriftelijk op de hoogte stelt.</li>
                    </ol>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 12 - Facturatie en betaling</h3>
                    <p className='general-terms-txt-p'><b>12.1</b> De vergoeding voor Licenties (voor zover toepasselijk) wordt omstreeks en
                        voorafgaand aan de ingangsdatum van de Licentie in rekening gebracht, alsmede voorafgaand aan
                        ieder moment waarop de Licentie wordt verlengd, tenzij anders afgesproken.</p>

                    <p className='general-terms-txt-p'><b>12.2</b> De vergoeding voor Verbruikskosten worden per maand/kwartaal/per jaar
                        achteraf in rekening gebracht.</p>

                    <p className='general-terms-txt-p'><b>12.3</b> 10-THINGS zal voor alle verschuldigde bedragen een factuur sturen aan de
                        Afnemer en heeft daarbij het recht om elektronisch te factureren.</p>

                    <p className='general-terms-txt-p'><b>12.4</b> Voor alle door 10-THINGS uitgebrachte facturen geldt een betalingstermijn
                        van dertig (30) dagen gerekend vanaf de factuurdatum, tenzij Schriftelijk anders overeengekomen.
                    </p>

                    <p className='general-terms-txt-p'><b>12.5</b> Als de Afnemer bezwaar maakt tegen de hoogte van een factuur, schort dit
                        diens betalingsverplichting niet op, maar zullen Partijen in overleg treden om tot een
                        minnelijke oplossing te komen.</p>

                    <p className='general-terms-txt-p'><b>12.6</b> Afnemer is bij gebreke van betaling vanaf de vervaldatum van de factuur van
                        rechtswege in verzuim, zonder dat een voorafgaande ingebrekestelling vereist is. 10-THINGS is
                        dan gerechtigd de Afnemer het gehele verschuldigde bedrag, alsmede de vanaf de vervaldag over
                        het verschuldigde bedrag berekende rente ad 1,0% per maand, dan wel, indien hoger, de wettelijke
                        handelsrente, in rekening te brengen.</p>

                    <p className='general-terms-txt-p'><b>12.7</b> Onverminderd het bovenstaande zullen alle kosten die samenhangen met het
                        incasseren van openstaande vorderingen, zowel de gerechtelijke als de buitengerechtelijke
                        (daaronder begrepen de kosten voor advocaten, deurwaarders en incassobureaus), voor rekening van
                        de Afnemer zijn. 10-THINGS is in ieder geval gerechtigd direct, dus desgewenst bij de eerste
                        (vrijwillige) aanmaning, een bedrag aan buitengerechtelijke kosten in rekening te brengen ter
                        grootte van 15% van het openstaande bedrag, met een minimum van tweehonderdvijftig euro.</p>

                    <p className='general-terms-txt-p'><b>12.8</b> 10-THINGS is gerechtigd om de Overeenkomst op te schorten als:</p>
                    <ol type="i">
                        <li className="general-terms-item-li">de Afnemer een factuur niet binnen de vervaltermijn betaalt, of betaling alsnog uitblijft na
                            (vrijwillige) aanmaning door 10-THINGS,</li>
                        <li className="general-terms-item-li">er een verslechtering in de solvabiliteit van de Afnemer intreedt die gerede aanleiding
                            geeft tot twijfel over het betalingsvermogen en de kredietwaardigheid van de Afnemer.</li>
                    </ol>
                    <p className='general-terms-txt-p'>Tijdens opschorting blijft de Afnemer eventueel (periodiek) verschuldigde bedragen verschuldigd.
                    </p>

                    <p className='general-terms-txt-p'><b>12.9</b> De Afnemer is niet gerechtigd om enige op de Afnemer rustende
                        betalingsverplichting op te schorten, te verrekenen of af te trekken op enige vordering op
                        10-THINGS uit welken hoofde dan ook.</p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 13 - Intellectuele Eigendomsrechten</h3>
                    <p className='general-terms-txt-p'><b>13.1</b> Alle Intellectuele Eigendomsrechten op de geleverde diensten, waaronder de
                        Applicatie, documentatie en andere materialen berusten uitsluitend bij 10-THINGS of diens
                        licentiegevers. De Afnemer verkrijgt uitsluitend een beperkt, niet-overdraagbaar gebruiksrecht
                        voor de overeengekomen bepaalde duur onder toepassing van de bevoegdheden en overige beperkingen
                        die in de Overeenkomst zijn opgenomen.</p>
                    <p className='general-terms-txt-p'><b>13.2</b> Alle gegevens die Afnemer tijdens diens gebruik van de Applicatie aan de
                        Applicatie toevoegt, of laat toevoegen door 10-THINGS, blijven eigendom van de Afnemer en
                        10-THINGS en of diens licentiegevers. Ten aanzien van deze gegevens verstrekt de Afnemer aan
                        10-THINGS een beperkt gebruiksrecht om de betreffende gegevens te gebruiken voor zover
                        redelijkerwijs nodig is voor de uitvoering van de Overeenkomst alsmede een gebruiksrecht voor
                        onbepaalde duur voor het gebruik van de gegevens voor de verbetering van de door 10-THINGS
                        geleverde, of in de toekomst te leveren, diensten.</p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 14 - Aansprakelijkheid</h3>
                    <p className='general-terms-txt-p'><b>14.1</b> De aansprakelijkheid van 10-THINGS wegens een toerekenbare tekortkoming in
                        de nakoming van haar verplichtingen onder de Overeenkomst, uit hoofde van onrechtmatige daad
                        en/of uit enige andere hoofde, is per gebeurtenis (waarbij een reeks samenhangende
                        gebeurtenissen wordt gezien als één gebeurtenis) beperkt tot hetgeen de Afnemer verschuldigd is
                        over een periode van drie (3) maanden voorafgaand aan de schadeveroorzakende gebeurtenis
                        (exclusief btw). In geen geval zal de totale aansprakelijkheid van 10-THINGS voor schade, uit
                        welke hoofde dan ook, op kalenderjaarbasis meer bedragen dan de bedongen vaste vergoeding voor
                        het betreffende kalenderjaar.</p>
                    <p className='general-terms-txt-p'><b>14.2</b> De beperking van aansprakelijkheid als bedoeld in voorgaand lid geldt
                        nadrukkelijk ook voor de door 10-THINGS in de Overeenkomst of anderszins verstrekte garanties.
                    </p>
                    <p className='general-terms-txt-p'><b>14.3</b> Enige in de Overeenkomst opgenomen beperking van aansprakelijkheid komt te
                        vervallen indien en voor zover de schade het gevolg is van opzet of bewuste roekeloosheid van de
                        bedrijfsleiding van 10-THINGS.</p>
                    <p className='general-terms-txt-p'><b>14.4</b> De aansprakelijkheid van 10-THINGS wegens een toerekenbare tekortkoming in
                        de nakoming van een Overeenkomst ontstaat slechts indien de Afnemer 10-THINGS onverwijld en
                        deugdelijk Schriftelijk in gebreke stelt, stellende daarbij een redelijke termijn ter zuivering
                        van de tekortkoming, en 10-THINGS ook na die termijn toerekenbaar in de nakoming van haar
                        verplichtingen tekort blijft schieten. De ingebrekestelling dient een zo gedetailleerd mogelijke
                        omschrijving van de tekortkoming te bevatten, zodat 10-THINGS in staat is adequaat te reageren.
                        Iedere vordering tot schadevergoeding door Afnemer vervalt door het enkele verloop van zes
                        maanden na het ontstaan van de vordering.</p>
                    <p className='general-terms-txt-p'><b>14.5</b> De inhoud van het opgeleverde advies van 10-THINGS of Applicatie is niet
                        bindend en slechts adviserend van aard. Afnemer beslist zelf en op eigen verantwoordelijkheid of
                        zij de voorstellen en hierin genoemde adviezen van 10-THINGS opvolgt. Alle gevolgen
                        voortvloeiend uit de opvolging van het advies komen voor rekening en risico van Afnemer. Afnemer
                        is te allen tijde vrij om haar eigen keuzes te maken die afwijken van het (op)geleverde advies
                        van 10-THINGS. 10-THINGS is niet gehouden aan enige vorm van restitutie indien dit het geval is.
                    </p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 15 - Vrijwaring en juistheid van informatie</h3>
                    <p className='general-terms-txt-p'><b>15.1</b> Afnemer is zelf verantwoordelijk voor de juistheid, betrouwbaarheid en
                        volledigheid van alle gegevens, informatie, documenten en/of bescheiden, in welke vorm dan ook
                        die zij aan 10-THINGS verstrekt in het kader van een Overeenkomst, alsmede voor de gegevens die
                        zij van derden heeft verkregen en welke aan 10-THINGS zijn verstrekt ten behoeve van de
                        uitvoering van de Dienst.</p>
                    <p className='general-terms-txt-p'><b>15.2</b> Afnemer vrijwaart 10-THINGS van elke aansprakelijkheid ingevolge het niet
                        of niet tijdig nakomen van de verplichtingen met betrekking tot het tijdig verstrekken van alle
                        juiste, betrouwbare en volledige gegevens, informatie, documenten en/of bescheiden.</p>
                    <p className='general-terms-txt-p'><b>15.3</b> Afnemer vrijwaart 10-THINGS voor alle aanspraken van Afnemer en door hem
                        ingeschakelde of onder hem werkzame derden, alsmede van klanten van Afnemer, gebaseerd op het
                        niet (tijdig) verkrijgen van eventuele subsidies en/of toestemmingen benodigd in het kader van
                        de uitvoering van de Overeenkomst.</p>
                    <p className='general-terms-txt-p'><b>15.4</b> Afnemer vrijwaart 10-THINGS voor alle aanspraken van derden welke
                        voortvloeien uit de werkzaamheden verricht ten behoeve van Afnemer, waaronder tevens verstaan
                        maar niet beperkt tot intellectuele eigendomsrechten op de door Afnemer verstrekte gegevens en
                        informatie die bij het uitvoeren van de Overeenkomst gebruikt kunnen worden en/of het handelen
                        of nalaten van Afnemer jegens derde(n).</p>
                    <p className='general-terms-txt-p'><b>15.5</b> Indien Afnemer elektronische bestanden, software of informatiedragers aan
                        10-THINGS verstrekt, garandeert Afnemer dat deze vrij zijn van virussen en defecten.</p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 16 - Overmacht</h3>
                    <p className='general-terms-txt-p'><b>16.1</b> Geen van de Partijen kan tot nakoming van enige verplichting worden
                        gehouden indien een omstandigheid die buiten de macht van Partijen ligt (overmacht) en die bij
                        het sluiten van de Overeenkomst niet reeds kon of had moeten worden voorzien, iedere redelijke
                        mogelijkheid tot nakoming tenietdoet. Onder overmacht worden mede (maar niet uitsluitend)
                        verstaan:</p>
                    <p className='general-terms-txt-p'>a. storingen van publieke infrastructuur die normaliter voor 10-THINGS beschikbaar is, en waar de
                        levering van de diensten (zoals de Applicatie) van afhankelijk is, maar waarover 10-THINGS geen
                        feitelijke macht kan uitoefenen of nakoming ervan kan vorderen;</p>
                    <p className='general-terms-txt-p'>b. storingen die zijn veroorzaakt door kwaadaardige software, netwerkaanvallen zoals
                        (D)DOS-aanvallen of al dan niet succesvolle pogingen om de netwerkbeveiliging of
                        systeembeveiliging te omzeilen;</p>
                    <p className='general-terms-txt-p'>c. tekortkomingen van toeleveranciers van 10-THINGS, die 10-THINGS niet kon voorzien en waar
                        10-THINGS diens toeleverancier niet voor aansprakelijk kan stellen, bijvoorbeeld doordat bij de
                        betreffende leverancier (eveneens) sprake was van overmacht;</p>
                    <p className='general-terms-txt-p'>d. binnenlandse onlusten, mobilisatie, pandemieën, oorlog, terreur, stakingen, brand en
                        overstromingen;</p>
                    <p className='general-terms-txt-p'>e. gebrekkigheid van zaken, apparatuur, of ander materiaal waarvan de Afnemer het gebruik heeft
                        voorgeschreven; en</p>
                    <p className='general-terms-txt-p'>f. langdurige onbeschikbaarheid van werknemers van 10-THINGS of door haar ingeschakelde derden
                        als gevolg van ziekte.</p>
                    <p className='general-terms-txt-p'><b>16.2</b> Indien een overmachtssituatie langer dan negentig (90) dagen heeft geduurd,
                        heeft elk der Partijen het recht om de Overeenkomst te ontbinden, zonder dat dit tot enige
                        verplichting tot schadevergoeding of ongedaanmaking leidt.</p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 17 - Promotie</h3>
                    <p className='general-terms-txt-p'><b>17.1</b> Het is 10-THINGS toegestaan om, gedurende de looptijd en na afloop van de
                        Overeenkomst, voor promotionele doeleinden in de door 10-THINGS gebruikte materialen en kanalen
                        (zoals haar website) de klantcase aangaande de door 10-THINGS aan Afnemer geleverde diensten te
                        beschrijven, waarbij ter illustratie gebruik gemaakt mag worden van de handelsnaam, beeld- en
                        woordmerk van de Afnemer. Indien de Afnemer bezwaar heeft tegen de wijze waarop 10-THINGS
                        gebruik heeft gemaakt van voornoemd recht, kan de Afnemer dit Schriftelijk kenbaar maken aan
                        10-THINGS. 10-THINGS zal het bezwaar in alle redelijkheid in overweging nemen en waar wenselijk
                        tot aanpassing overgaan.</p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 18 - Wijziging van de Overeenkomst</h3>
                    <p className='general-terms-txt-p'><b>18.1</b> Indien de Afnemer een wijziging van de Overeenkomst wenst, kan de Afnemer
                        daartoe een aanvraag bij 10-THINGS indienen. Dergelijke wijzigingen zijn uitsluitend van
                        toepassing indien deze uitdrukkelijk Schriftelijk door 10-THINGS zijn aanvaard.</p>
                    <p className='general-terms-txt-p'><b>18.2</b> 10-THINGS behoudt zich het recht voor de Algemene Voorwaarden te wijzigen
                        of aan te vullen, ook ten aanzien van reeds bestaande Overeenkomsten.</p>
                    <p className='general-terms-txt-p'><b>18.3</b> Wijzigingen van ondergeschikt belang, wijzigingen op grond van de wet en
                        wijzigingen in het voordeel van de Afnemer, kunnen te allen tijde met onmiddellijke ingang
                        worden doorgevoerd en behoeven geen kennisgeving aan de Afnemer.</p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 19 - Duur en einde van de Overeenkomst</h3>
                    <p className='general-terms-txt-p'><b>19.1</b> Voor zover de Overeenkomst niet anders bepaalt geldt het volgende:</p>
                    <p className='general-terms-txt-p'><b>19.1.1</b> de looptijd van de Licentie(s) is vastgelegd in de Overeenkomst;</p>
                    <p className='general-terms-txt-p'><b>19.1.2</b> de eerste 7 dagen na het aangaan van de Overeenkomst wordt gezien als
                        proeftijd.</p>
                    <p className='general-terms-txt-p'><b>19.1.3</b> de Overeenkomst is maandelijks opzegbaar met een opzegtermijn van 1
                        maand.</p>
                    <p className='general-terms-txt-p'><b>19.1.4</b> artikel 7:408 lid 1 van het Burgerlijk Wetboek is niet van toepassing op
                        de Overeenkomst;</p>
                    <p className='general-terms-txt-p'><b>19.1.5</b> opzegging door de Afnemer resulteert nimmer in verval van verplichting
                        tot betaling van reeds verschuldigde (Licentie)vergoedingen of Verbruikskosten, noch tot een
                        verplichting tot terugbetaling van reeds betaalde (Licentie)vergoedingen of Verbruikskosten;</p>
                    <p className='general-terms-txt-p'><b>19.1.6</b> indien de Afnemer geen vergoeding aan 10-THINGS is verschuldigd onder de
                        Overeenkomst, dan is 10-THINGS gerechtigd de Overeenkomst op ieder moment en met onmiddellijke
                        ingang te beëindigen zonder dat daarvoor enige voorafgaande kennisgeving aan de Afnemer voor is
                        vereist.</p>
                    <p className='general-terms-txt-p'><b>19.2</b> 10-THINGS mag de Overeenkomst op elk moment opschorten, ontbinden of
                        opzeggen zonder dat daartoe een ingebrekestelling is vereist, indien (i) de Afnemer failliet is
                        verklaard of zelf diens faillissement heeft aangevraagd, (ii) aan de Afnemer surseance van
                        betaling is verleend, (iii) de onderneming van de Afnemer is of wordt ontbonden of geliquideerd,
                        (iv) er beslag is gelegd op (een deel van) het vermogen van de Afnemer.</p>
                    <p className='general-terms-txt-p'><b>19.3</b> Indien Partijen op het moment van ontbinding van de Overeenkomst reeds
                        prestaties ter uitvoering daarvan hebben verricht respectievelijk ontvangen, dan zullen deze
                        prestaties en daarmee samenhangende betalingsverplichtingen geen voorwerp van ongedaanmaking
                        zijn. Artikel 6:271 e.v. van het Burgerlijk Wetboek is niet van toepassing op de Overeenkomst.
                    </p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 20 - Klachten</h3>
                    <p className='general-terms-txt-p'><b>20.1</b> Indien Afnemer niet tevreden is over de service van 10-THINGS of anderszins
                        klachten heeft over de uitvoering van zijn opdracht, is Afnemer verplicht om deze klachten zo
                        spoedig mogelijk, maar uiterlijk binnen 7 kalenderdagen na de betreffende aanleiding die tot de
                        klacht heeft geleid te melden. Klachten kunnen schriftelijk gemeld worden met als onderwerp
                        “Klacht”.</p>
                    <p className='general-terms-txt-p'><b>20.2</b> De klacht moet door Afnemer voldoende onderbouwd en/of toegelicht zijn, wil
                        10-THINGS de klacht in behandeling kunnen nemen.</p>
                    <p className='general-terms-txt-p'><b>20.3</b> 10-THINGS zal zo spoedig mogelijk, doch uiterlijk binnen 21 kalenderdagen
                        na ontvangst van de klacht inhoudelijk reageren op de klacht.</p>
                    <p className='general-terms-txt-p'><b>20.4</b> Partijen zullen proberen om gezamenlijk tot een oplossing te komen.</p>
                </section>
                <section>
                    <h3 className='general-terms-title-h3'>Artikel 21 - Slotbepalingen</h3>
                    <p className='general-terms-txt-p'><b>21.1</b> De Overeenkomst wordt beheerst door Nederlands recht.</p>
                    <p className='general-terms-txt-p'><b>21.2</b> Alle geschillen die voortvloeien uit, dan wel samenhangen met de
                        Overeenkomst,
                        zullen bij uitsluiting aanhangig gemaakt worden bij de bevoegde rechter in het arrondissement
                        waar
                        10-THINGS gevestigd is, tenzij door de regels van dwingend recht anders wordt voorgeschreven.
                    </p>
                    <p className='general-terms-txt-p'><b>21.3</b> 10-THINGS heeft het recht om de Overeenkomst geheel of gedeeltelijk aan een
                        tot
                        de groep behorende vennootschap zoals bedoeld in artikel 2:24b van het Burgerlijk Wetboek over
                        te
                        dragen, dan wel aan een derde partij die de betreffende bedrijfsactiviteit(en) van 10-THINGS
                        overneemt,
                        zonder dat daarvoor enige nadere toestemming of medewerking van de Afnemer vereist voor zal
                        zijn.</p>
                    <p className='general-terms-txt-p'><b>21.4</b> De door 10-THINGS ontvangen of opgeslagen versie van communicatie,
                        metingen, of
                        overige informatie wordt geacht juist te zijn, behoudens tegenbewijs te leveren door de Afnemer.
                    </p>
                    <p className='general-terms-txt-p'><b>21.5</b> Indien enige bepaling uit de Overeenkomst nietig of vernietigbaar blijkt te
                        zijn dan
                        wel om enige andere reden geheel of gedeeltelijk ongeldig wordt of is, blijven de overige
                        bepalingen uit
                        de Overeenkomst onverminderd van kracht. 10-THINGS zal het ongeldige beding vervangen door een
                        beding
                        dat wel geldig is en waarvan de rechtsgevolgen, gelet op de inhoud en de strekking van de
                        Overeenkomst,
                        zoveel mogelijk overeenstemt met die van het ongeldige beding.</p>
                </section>
            </div>
        </PageLayout>
    );
};

export default GeneralTermsView;