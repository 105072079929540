import { Select, SelectItem } from '@nextui-org/react';
import React, { useState } from 'react';
import { Branch } from '../../../../../models/Branch';

interface SelectSubBranchProps {
    subBranches: Branch[];
    selectedSubBranch: string | undefined;
    setSelectedSubBranch: (branchId: string) => void;
    errorSelectedSubBranch: string;
}

const SelectSubBranch: React.FC<SelectSubBranchProps> = ({ subBranches, selectedSubBranch, setSelectedSubBranch, errorSelectedSubBranch }) => {

    const onChangeSubBranch = (e: any) => {
        setSelectedSubBranch(e.target.value);
    }

    return (
        <div>
            <label htmlFor="branch"><h1 className="add-subsidiary-form-title-h1">Selecteer jouw subbranch</h1></label>
            <p className="add-subsidiary-form-txt-p">Om specifieke kenmerken van jouw horecazaak te kunnen begrijpen willen wij weten in welke subbranch jouw zaak fungeert.</p>
            <Select
                variant='bordered'
                label="Selecteer je subbranch"
                className="max-w bg-white"
                value={selectedSubBranch}
                onChange={onChangeSubBranch}
                selectedKeys={[selectedSubBranch!]}
                isInvalid={errorSelectedSubBranch !== ''}
                errorMessage={errorSelectedSubBranch}
            >
                {subBranches.map((subbranch) => (
                    <SelectItem key={subbranch.id} value={subbranch.id} >
                        {subbranch.title}
                    </SelectItem>
                ))}
            </Select>
        </div>
    );
};

export default SelectSubBranch;