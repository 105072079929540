import { Input, Textarea } from '@nextui-org/react';
import React, { useState } from 'react';
import './EditThingModal.css';
import { addThing, updateThing } from '../../../../api/things/thingsService';
import { getSubsidiary } from '../../../../api/auth/user-info';
import { Thing } from '../../../../models/Thing';
import { ThingType } from '../../../../models/ThingType';

interface EditThingModalProps {
    thing: Thing
    thingtype: ThingType;
    closeEditThingModal: () => void;
    refreshThings: () => void;
}

const EditThingModal: React.FC<EditThingModalProps> = (props) => {
    const [title, setTitle] = useState(props.thing.title);

    const [titleError, setTitleError] = useState(false);
    const [titleErrorMSG, setTitleErrorMSG] = useState('');

    const [description, setDescription] = useState(props.thing.description);

    const [descriptionError, setDescriptionError] = useState(false);
    const [descriptionErrorMSG, setDescriptionErrorMSG] = useState('');

    const handleThingTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleThingDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        let formError = false;
        
        if (title === '') {
            setTitleError(true);
            setTitleErrorMSG('Titel mag niet leeg zijn');
            formError = true;
        }

        if (description === '') {
            setDescriptionError(true);
            setDescriptionErrorMSG('Beschrijving mag niet leeg zijn');
            formError = true;
        }

        if (!formError) {
            await updateThing(props.thing.id, {
                title: title,
                description: description,
            });
            props.refreshThings();
            props.closeEditThingModal();
        }

    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Input className='title-input' type="title" label="Titel" value={title} variant='bordered' isInvalid={titleError} errorMessage={titleErrorMSG} onChange={handleThingTitleChange}/>
                <Textarea
                    className='description-input'
                    variant='bordered'
                    label="Beschrijving"
                    value={description}
                    isInvalid={descriptionError} 
                    errorMessage={descriptionErrorMSG}
                    onChange={handleThingDescriptionChange}
                />
                <div className="thingtype-showcase-div">
                    <span className="thingtype-showcase-color-span" style={{ backgroundColor: props.thingtype.color }}>

                    </span>
                    <p className="thingtype-showcase-type-txt-p">{props.thingtype.singular}</p>
                </div>
                <div className="editthing-controls-div">
                    <button className='editthing-submit-control-btn' type="submit">Wijzig {props.thingtype.singular}</button>
                    <button onClick={props.closeEditThingModal} className='editthing-cancel-control-btn' type="button">Annuleren</button>
                </div>
            </form>
        </div>
    );
};

export default EditThingModal;