import React from 'react';
import './CreatedThingsList.css';
import { Thing } from '../../../../models/Thing';

interface CreatedThingsListProps {
    thingtypes: { id: number; plural: string, singular: string, color: string, }[];
    onThingTypeClick: (thingType: any) => void;
    onThingOptionsClick?: (event: React.MouseEvent, thing: Thing) => void;
}

const thingTypesIcons = ['border_color', 'local_cafe', 'checklist', 'developer_guide', 'chat'];
const thingTypesDescs = ['Kenmerken van je zaak', 'Upsale je producten', 'Taken voor je team', 'Techniek van je werknemers', 'Communicatie en verzorging'];

const CreatedThingsList: React.FC<CreatedThingsListProps> = ({ thingtypes, onThingTypeClick }) => {
    return (
        <div id='created-things-list-container'>
            <div id='created-things-list-inner-container'>
                {thingtypes.map((thingtype, index) => (
                    <div key={index} className='created-thing-item-div' style={{ backgroundColor: thingtype.color }} onClick={() => onThingTypeClick(thingtype)}>
                        <div className="created-thing-inner-area">
                            <div className="created-thing-icon-bubble">
                                <span className="created-thing-icon-span material-symbols-outlined" style={{ color: thingtype.color }}>{thingTypesIcons[thingtype.id - 1]}</span>
                            </div>
                            <div className="created-thing-info-bubble">
                                <h1 className='created-thing-title-h1'>{thingtype.plural}</h1>
                                <p className='created-thing-desc-p'>{thingTypesDescs[thingtype.id - 1]}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CreatedThingsList;