import React, { useEffect } from 'react';
import './PageLayout.css';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';

const PageLayout = ({ children }: { children: React.ReactNode }) => {
    return (
        <div id='main-pagelayout'>
            <main id='main-site-content'>
                <NavBar />
                {children}
                <Footer />
            </main>
        </div>
    );
};

export default PageLayout;