import React, { useEffect, useMemo, useState } from 'react';
import './FocusPointsStep.css';
import { getSubsidiary } from '../../../../../../api/auth/user-info';
import { fetchGeneralLabels, fetchLabelsByBranch } from '../../../../../../api/labels/labelsService';

interface FocusPointsStepProps {
    selectedLabelIds: number[];
    setSelectedLabelIds: React.Dispatch<React.SetStateAction<number[]>>;
    selectedThingIdsError: string;
    labels: any[];
}

const FocusPointsStep: React.FC<FocusPointsStepProps> = ({selectedLabelIds, setSelectedLabelIds, selectedThingIdsError, labels}) => {
    

    const toggleLabelSelection = (labelId: number) => {
        setSelectedLabelIds(prev => {
            const index = prev.indexOf(labelId);
            if (index > -1) {
                return prev.filter(id => id !== labelId);
            } else {
                return [...prev, labelId];
            }
        });
    };

    const selectedLabels = useMemo(() => new Set(selectedLabelIds), [selectedLabelIds]);

    return (
        <div className='pre-briefing-focuspoints-step'>
            <h2 className="pre-briefing-focuspoints-title-h2">Selecteer de focuspunten voor vandaag!</h2>
            <div className="pre-briefing-focuspoints-selection-area">
                {labels.map(label => (
                    <div key={label.id} className="selectable-label-btn-wrapper">
                        <button
                            className={`selectable-label-btn ${selectedLabels.has(label.id) ? 'selected' : ''}`}
                            type='button'
                            onClick={() => toggleLabelSelection(label.id)}
                        >
                            {label.value}
                        </button>
                    </div>
                ))}
                {selectedThingIdsError && <div className="pre-briefing-error-div">
                    {selectedThingIdsError}
                </div>}
            </div>
        </div>
    );
};

export default FocusPointsStep;