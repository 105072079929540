import React, { useEffect, useRef, useState } from 'react';
import { ModalOption } from '../../../models/ModalOption';
import './OptionsModal.css';

interface OptionsModalProps {
    onClose: () => void;
    x: number;
    y: number;
    options: ModalOption[];
}

const OptionsModal: React.FC<OptionsModalProps> = ({ onClose, x, y, options  }) => {
    const [adjustedX, setAdjustedX] = useState(-1000); // Initially off-screen
    const [adjustedY, setAdjustedY] = useState(-1000); // Initially off-screen
    const optionsRef = useRef<HTMLDivElement | null>(null);
    
    // Adjust the position if it's too close to the edge of the screen
    useEffect(() => {
        const optionsWidth = optionsRef.current?.offsetWidth || 0;
        const optionsHeight = optionsRef.current?.offsetHeight || 0;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const margin = 40; // Margin value

        if (x + optionsWidth + margin > windowWidth) {
            setAdjustedX(windowWidth - optionsWidth - margin); // Add margin to the right
        } else if (x - margin < 0) {
            setAdjustedX(margin); // Add margin to the left
        } else {
            setAdjustedX(x);
        }

        if (y + optionsHeight + margin > windowHeight) {
            setAdjustedY(y - optionsHeight - margin); // Add margin to the bottom
        } else if (y - margin < 0) {
            setAdjustedY(margin); // Add margin to the top
        } else {
            setAdjustedY(y);
        }
    }, [x, y]);

    return (
        <div className="options-overlay" onClick={onClose}>
            <div ref={optionsRef} className="options" style={{ left: adjustedX, top: adjustedY }} onClick={(event) => event.stopPropagation()}>
                <ul className="options-listing-ul">
                    {options.map((option, index) => (
                        <li key={index} className={`options-listing-li ${option.class}`} onClick={option.action}>
                            <span className='options-li-icon-span material-symbols-outlined'>{option.icon}</span>
                            <span className="options-li-txt-span">{option.label}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default OptionsModal;