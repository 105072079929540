import React, { useState } from 'react';
import { Input, Select, SelectItem, Tab, Tabs } from '@nextui-org/react';
import { Shifts } from '../../../../../../enums/Shifts';
import './OverviewStep.css';
import * as pdfjsLib from 'pdfjs-dist';
import PDFThumbnail from '../../../../PDFThumbnail/PDFThumbnail';
import PDFViewer from '../../../../PDFViewer/PDFViewer';
pdfjsLib.GlobalWorkerOptions.workerSrc = `/assets/pdfLib/pdf.worker.min.mjs`;

interface OverviewStepProps {
    // Add any props you need for the component here
    setPdfModalOpen: (value: boolean) => void;
    manager: string;
    setManager: (value: string) => void;
    managerError: string;
    shift: string;
    setShift: (value: string) => void;
    shiftError: string;
    pdfUrl: string;
}

const OverviewStep: React.FC<OverviewStepProps> = (props) => {
    // Add your component logic here

    const shiftTypes = Object.values(Shifts).filter(value => typeof value === 'string');

    // const pdfUrl = 'https://10-things-files.s3.eu-west-3.amazonaws.com/reports/126/test.pdf'; // Replace with your PDF URL

    const handleThumbnailClick = () => {
        props.setPdfModalOpen(true);
    };

    const onChangeShift = (e: any) => {
        const value = Number(e.target.value) + 1;
        console.log(value);
        props.setShift(value.toString());
    }
    return (
        <div className="pre-briefing-overview-step">
            <div className="pre-briefing-input-area">
                <Input type="text" variant='bordered' value={props.manager} onChange={(e) => props.setManager(e.target.value)} label="Naam leidinggevende" errorMessage={props.managerError} isInvalid={props.managerError !== ''} />
            </div>
            <div className="pre-briefing-input-area">
                <Select
                    variant='bordered'
                    value={Number(props.shift) - 1}
                    onChange={onChangeShift}
                    label="Selecteer je shift"
                    className="pre-briefing-select"
                    errorMessage={props.shiftError}
                    isInvalid={props.shiftError !== ''}
                >
                    {shiftTypes.map((shiftType, index) => {
                        let shiftValue;
                        let shiftIndex;
                        if (shiftType === 'MORNING') {
                            shiftValue = 'Ochtend';
                            shiftIndex = '1';
                        } else if (shiftType === 'AFTERNOON') {
                            shiftValue = 'Middag';
                            shiftIndex = '2';
                        } else if (shiftType === 'EVENING') {
                            shiftValue = 'Avond';
                            shiftIndex = '3';
                        } else if (shiftType === 'NIGHT') {
                            shiftValue = 'Nacht';
                            shiftIndex = '4';
                        }
                        return (
                            <SelectItem key={index} value={shiftIndex}>
                                {shiftValue}
                            </SelectItem>
                        )
                    })}
                </Select>
            </div>
            {props.pdfUrl && (
                <>
                    <h3 className="pre-briefing-title-h3">
                        Evalueer aan de hand van je laatste shift:
                    </h3>
                    <div className="pre-briefing-report-view" onClick={handleThumbnailClick}>
                        <PDFThumbnail url={props.pdfUrl} />
                    </div></>)}
        </div>
    );
};

export default OverviewStep;