import { ReportMailinglist, ReportMailinglistCreate } from "../../models/ReportMailinglist";
import api from "../auth/api";
import { getSubsidiary } from "../auth/user-info";

export const fetchReportMaillingList = async (): Promise<ReportMailinglist[]> => {
    const subsidiary = getSubsidiary();
    if (!subsidiary) {
        throw new Error('Subsidiary is null');
    }
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/report_mailinglists/get_report_mailinglists_by_subsidiary/${subsidiary.id}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch report mailing list:', error);
        throw error;
    }
};

export const addReportMaillingList = async (reportMaillingList: ReportMailinglistCreate) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/report_mailinglists/`;
        const response = await api.post(url, reportMaillingList , config);
        return response.data as ReportMailinglist;
    } catch (error) {
        console.error('Failed to send report mailing list:', error);
        throw error;
    }
}

export const updateReportMaillingList = async (id: number, reportMaillingList: any) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/report_mailinglists/${id}/`;
        const response = await api.put(url, reportMaillingList, config);
        return response.data;
    } catch (error) {
        console.error('Failed to update report mailing list:', error);
        throw error;
    }
}

export const deleteReportMaillingList = async (id: number) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/report_mailinglists/${id}/`;
        const response = await api.delete(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to delete report mailing list:', error);
        throw error;
    }
};