import React from 'react';
import NavBarItem from '../NavBarItem/NavBarItem';
import './NavBar.css';
import logo from '../../../assets/img/hoppe-logo.png';
import NavBarUnderButton from './NavBarUnderButton/NavBarUnderButton';
import { getSubsidiary } from '../../../api/auth/user-info';

interface NavBarProps {
    activePage: string;
    isNavOpen: boolean;
    toggleNav: () => void;
}

const NavBar: React.FC<NavBarProps> = (props) => {

    const subsidiary = getSubsidiary();

    const toggleNav = () => {
        if (props.isNavOpen) {
            props.toggleNav();
        }
    }

    return (
        <div>
            <nav id="main-portal-nav" className={`${props.isNavOpen ? 'nav-open' : ''}`} >
                <div id="main-portal-nav-top-area">
                    <button id="main-portal-nav-top-logo-menu-butt" type='button' onClick={props.toggleNav}><span id="main-portal-nav-menu-icon"
                        className="material-symbols-outlined">menu</span></button>
                    <div id="main-portal-nav-top-logo-area">
                        {subsidiary.logo !== null && <div id="main-portal-nav-top-logo-inner-logo-area">
                            <img id="main-portal-nav-top-logo-img" src={subsidiary.logo.file_src} alt="logo" />
                        </div>}
                    </div>
                </div>
                <ul id="main-portal-menu-listing-ul">
                    <NavBarItem title="Dashboard" icon="dashboard" href="/portaal/dashboard" toggleNav={toggleNav} />
                    {/* <NavBarItem title="Planning" icon="calendar_month" href="/portaal/planning" /> */}
                    <NavBarItem title="Rapporten" icon="analytics" href="/portaal/rapporten" toggleNav={toggleNav} />
                    <NavBarItem title="Things" icon="storefront" href="/portaal/things" toggleNav={toggleNav} />
                    {/* <NavBarItem title="Medewerkers" icon="group" href="/portaal/medewerkers" /> */}
                    {/* <NavBarItem title="Rooster" icon="calendar_month" href="/portaal/rooster" /> */}
                    {/* <NavBarItem title="Takenlijsten" icon="task" href="/portaal/takenlijsten" /> */}
                    <NavBarItem title="Menu's" icon="receipt_long" href="/portaal/menu-upload" toggleNav={toggleNav} />
                    {/* <NavBarItem title="Facturen" icon="receipt_long" href="/portaal/facturen" /> */}
                </ul>
                <div id="main-portal-menu-under-bar-div">
                    {props.isNavOpen ? (
                        <div>
                            <NavBarUnderButton icon="settings" href="/portaal/instellingen" toggleNav={toggleNav} />
                            {/* <NavBarUnderButton icon="help" href="/portaal/help" toggleNav={toggleNav} /> */}
                        </div>
                    ) : null}



                    <NavBarUnderButton icon="left_panel_close" alignRight toggleNav={props.toggleNav} />
                </div>
            </nav>
        </div>
    );
};

export default NavBar;