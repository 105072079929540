import React, { useEffect } from 'react';
import PortaalPageLayout from '../../../components/Portaal/PageLayout/PageLayout';
import SettingsNavBar from '../../../components/Portaal/Settings/SettingsNavBar/SettingsNavBar';
import './SettingsView.css';
import AccountSettings from './InnerViews/AccountSettings/AccountSettings';
import SubsidiarySettings from './InnerViews/SubsidiarySettings/SubsidiarySettings';
import ModalLayout from '../../../components/Portaal/ModalLayout/ModalLayout';
import ReportReceivers from './InnerViews/ReportReceivers/ReportReceivers';
import SubscriptionSettings from './InnerViews/SubscriptionSettings/SubscriptionSettings';
import ChangePassword from './InnerViews/SettingsOverlayViews/ChangePassword/ChangePassword';
import DeleteAccount from './InnerViews/SettingsOverlayViews/DeleteAccount/DeleteAccount';
import ChangeLabels from './InnerViews/SettingsOverlayViews/ChangeLabels/ChangeLabels';
import AddMailerReport from './InnerViews/SettingsOverlayViews/AddMailerReport/AddMailerReport';
import EditMailerReport from './InnerViews/SettingsOverlayViews/EditMailerReport/EditMailerReport';
import { useLocation } from 'react-router-dom';
import InvoicesView from './InnerViews/InvoicesView/InvoicesView';
import PopUpLayout from '../../../components/Portaal/PopUpLayout/PopUpLayout';
import UnsubscribePopUp from './InnerViews/SettingsOverlayViews/UnsubscribePopUp/UnsubscribePopUp';
import { cancelSubscription } from '../../../api/subscriptions/subscriptionService';
import { Subscription } from '../../../models/Subscription';
import { getUserInfo } from '../../../api/auth/user-info';
import { fetchCustomerByParentCompany } from '../../../api/mollie_customer/mollie_customerServices';
import { ReportMailinglist } from '../../../models/ReportMailinglist';
import { fetchReportMaillingList } from '../../../api/report_mailinglist/reportmaillinglistService';
import usePageTracking from '../../../utils/usePageTracking';

interface SettingsViewProps {
    toggleNav: () => void;
    isNavOpen: boolean;
    setActiveSubscription: React.Dispatch<React.SetStateAction<Subscription | null | undefined>>;
    activeSubscription: Subscription | null | undefined;
    handleOpenBuyCreditsModal: () => void;
    openBuyCreditsModal: boolean;
    setOpenBuyCreditsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const SettingsView: React.FC<SettingsViewProps> = (props) => {
    usePageTracking();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedSetting = queryParams.get('m') ?? 'account';

    // const [selectedSetting, setSelectedSetting] = React.useState<string>('account');

    const [selectedEditOverlay, setSelectedEditOverlay] = React.useState<string>('');

    const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
    const [modalTitle, setModalTitle] = React.useState<string>('');

    const closeModal = () => {
        setSelectedEditOverlay('');
        setModalIsOpen(false);
    }

    const [selectedEditingObject, setSelectedEditingObject] = React.useState<any>({});

    const [selectedMenu, setSelectedMenu] = React.useState<Boolean>(false);

    const [popUpIsOpen, setPopUpIsOpen] = React.useState<boolean>(false);

    const [mollieCustomer, setMollieCustomer] = React.useState<any>();

    useEffect(() => {
        const loadMollieCustomer = async () => {
            try {
                const user = getUserInfo();
                const response = await fetchCustomerByParentCompany(user.parent_company_id);
                console.log('Mollie Customer:', response);
                setMollieCustomer(response);
            } catch (err) {
            } finally {
                console.log('Done fetching mollie customer');
            }
        }
        loadMollieCustomer();
    }, []);

    const handleCancelSubscription = async () => {
        // Cancel the subscription
        try {
            const response = await cancelSubscription(mollieCustomer.mollie_customer_id, mollieCustomer.subscription_id, mollieCustomer.mandate_id);
            console.log('Subscription cancelled:', response);
            props.setActiveSubscription(null);
            setPopUpIsOpen(false);
        } catch (err) {
            console.error('Failed to cancel subscription:', err);
        }
    };

    const [reportReceivers, setReportReceivers] = React.useState<ReportMailinglist[]>([]);

    useEffect(() => {
        const loadReportReceivers = async () => {
            try {
                const response = await fetchReportMaillingList();
                setReportReceivers(response);
                console.log(response);
            } catch (error) {
                console.error(error);
            } finally {
                console.log('Report receivers loaded');
            }
        };

        loadReportReceivers();
    }, []);

    const addReceiver = (receiver: ReportMailinglist) => {
        setReportReceivers(prevState => [...prevState, receiver]);
    }

    const editReceiver = (receiver: ReportMailinglist) => {
        setReportReceivers(prevState => {
            const index = prevState.findIndex(r => r.id === receiver.id);
            prevState[index] = receiver;
            return [...prevState];
        });
    }

    const deleteReceiver = (receiver: ReportMailinglist) => {
        setReportReceivers(prevState => {
            const index = prevState.findIndex(r => r.id === receiver.id);
            prevState.splice(index, 1);
            return [...prevState];
        });
    }

    return (
        <div>
            <PortaalPageLayout activePage='instellingen' isNavOpen={props.isNavOpen} toggleNav={props.toggleNav} handleOpenBuyCreditsModal={props.handleOpenBuyCreditsModal} openBuyCreditsModal={props.openBuyCreditsModal} setOpenBuyCreditsModal={props.setOpenBuyCreditsModal} activeSubscription={props.activeSubscription}>
                {modalIsOpen &&
                    <ModalLayout isOpen={modalIsOpen} onClose={closeModal} title={modalTitle}>
                        {selectedEditOverlay === 'wachtwoord' && <ChangePassword />}
                        {selectedEditOverlay === 'verwijderen' && <DeleteAccount />}
                        {selectedEditOverlay === 'labels' && <ChangeLabels onClose={closeModal} />}
                        {selectedEditOverlay === 'mailer' && <AddMailerReport onClose={closeModal} addReceiver={addReceiver} />}
                        {selectedEditOverlay === 'editmailer' && <EditMailerReport onClose={closeModal} selectedEditingObject={selectedEditingObject} editReceiver={editReceiver} />}
                    </ModalLayout>
                }

                {popUpIsOpen &&
                    <PopUpLayout title='Weet je het zeker?' onClose={() => setPopUpIsOpen(false)}>
                        <UnsubscribePopUp handleUnsubscribe={handleCancelSubscription} onClose={() => setPopUpIsOpen(false)} />
                    </PopUpLayout>}
                <div className="settings-inner-area">
                    <SettingsNavBar selectedSetting={selectedSetting} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />
                    <div className="settings-content-div">
                        {selectedSetting === 'account' && <AccountSettings setSelectedEditOverlay={setSelectedEditOverlay} setModalIsOpen={setModalIsOpen} setModalTitle={setModalTitle} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />}
                        {selectedSetting === 'subsidiary' && <SubsidiarySettings setSelectedEditOverlay={setSelectedEditOverlay} setModalIsOpen={setModalIsOpen} setModalTitle={setModalTitle} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />}
                        {selectedSetting === 'report-ontvangers' && <ReportReceivers setSelectedEditOverlay={setSelectedEditOverlay} setModalIsOpen={setModalIsOpen} setModalTitle={setModalTitle} setSelectedEditingObject={setSelectedEditingObject} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} reportReceivers={reportReceivers} deleteReceiver={deleteReceiver} />}
                        {selectedSetting === 'subscription' && <SubscriptionSettings selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} openCancelSubscriptionPopUp={() => setPopUpIsOpen(true)} activeSubscription={props.activeSubscription} />}
                        {selectedSetting === 'invoices' && <InvoicesView selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />}
                    </div>
                </div>
            </PortaalPageLayout>
        </div>
    );
};

export default SettingsView;