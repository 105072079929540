import React, { useState } from 'react';
import { Label } from '../../../../../models/Label';

interface SelectLabelsProps {
    labels: Label[];
    toggleLabelSelection: (id: number) => void;
    selectedLabels: Set<number>;
    errorSelectedLabels: string;
}

const SelectLabels: React.FC<SelectLabelsProps> = ({
    labels,
    toggleLabelSelection,
    selectedLabels,
    errorSelectedLabels
}) => {

    return (
        <div>
            <h1 className="add-subsidiary-form-title-h1">Selecteer jouw kenmerken</h1>
            <p className="add-subsidiary-form-txt-p">Om de juiste things te krijgen willen wij weten welke kenmerken jouw zaak heeft</p>
            {labels.map(label => (
                <div key={label.id} className="selectable-label-btn-wrapper">
                    <button
                        className={`selectable-label-btn ${selectedLabels.has(label.id) ? 'selected' : ''}`}
                        type='button'
                        onClick={() => toggleLabelSelection(label.id)}
                    >
                        {label.value}
                    </button>
                </div>
            ))}
            {errorSelectedLabels && <p className="error-msg">{errorSelectedLabels}</p>}
        </div>
    );
};

export default SelectLabels;