import React, { useEffect, useState } from 'react';
import PaymentViewLayout from '../../components/PaymentView/PaymentViewLayout/PaymentViewLayout';
import restaurantIMG from '../../assets/img/restaurant.jpg';
import './PayedPaymentView.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchProduct, fetchProductByIdentifier } from '../../api/products/productsService';
import { getTransactionByOrderID } from '../../api/transactions/transactionsService';
import PayedPaymentInfoView from './InnerViews/PayedPaymentInfoView/PayedPaymentInfoView';
import { Spinner } from '@nextui-org/react';
import { Helmet } from 'react-helmet-async';
import usePageTracking from '../../utils/usePageTracking';
import { getUserInfo, refreshSubsidiaryInfo } from '../../api/auth/user-info';
import { retrieveUserInfo } from '../../api/auth/user.auth';
import { Transaction } from '../../models/Transaction';
import { Product } from '../../models/Product';

const PayedPaymentView: React.FC = () => {
    usePageTracking();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const orderid = queryParams.get('id');
    console.log('Query params:', orderid);

    const [isLoading, setIsLoading] = useState(true);


    const [transaction, setTransaction] = useState<Transaction>();
    const [product, setProduct] = useState<Product>();
    useEffect(() => {
        if (!orderid) {
            navigate(-1);
            return;
        }
        const loadTransactionByOrderID = async () => {
            try {
                setIsLoading(true);
                const response = await getTransactionByOrderID(orderid as string);
                console.log('Transaction:', response);
                setTransaction(response);
                await refreshSubsidiaryInfo();


            } catch (err) {
                console.error('Failed to fetch transaction:', err);
            } finally {
                console.log('Done fetching transaction');
            }
        }
        loadTransactionByOrderID();
    }, [orderid, navigate]);

    useEffect(() => {
        if (!transaction) {
            return;
        }
        const loadProduct = async () => {
            try {
                setIsLoading(true);
                const response = await fetchProduct(transaction.product_id.toString());
                console.log('Product:', response);
                setProduct(response);
            } catch (err) {
                console.error('Failed to fetch product:', err);
            } finally {
                console.log('Done fetching product');
                setIsLoading(false);
            }
        }
        loadProduct();
    }, [transaction]);

    const amount = product ? (product.price * transaction?.amount_of_products!) : 0;
    const vat = amount * 0.21;
    const total = amount + vat;
    return (
        <div>
            <PaymentViewLayout>
                <Helmet>
                    <title>10-Things | Betaling gelukt</title>
                </Helmet>
                <div className="payment-left-area">
                    <img src={restaurantIMG} alt="restaurant" className="payment-img-left" />
                </div>
                <div className="payment-right-area">

                    {isLoading && <div className='loading-spinner-container'><Spinner color="primary" size='lg' /></div>}

                    {isLoading ? null : <PayedPaymentInfoView product={product!} transaction={transaction!} amount={amount} vat={vat} total={total} />}
                </div>
            </PaymentViewLayout >
        </div >
    );
};

export default PayedPaymentView;