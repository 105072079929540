import { Action } from "../../models/Action";
import { InspireMeOutput } from "../../models/InspireMe";
import backend from "../backend";

export const InspireMe = async (inspireme: any) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/inspire/`;
        const response = await backend.post(url, inspireme, config);
        return response.data as InspireMeOutput[];
    } catch (error) {
        console.error('Failed to fetch inspire me things:', error);
        throw error;
    }
}