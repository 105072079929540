import React from 'react';
import logo from '../../../../assets/img/website-10things-logo-v2-outlined.png';
import './AccountVerification.css';

interface AccountVerificationProps {
    verificationCode: string[];
    setVerificationCode: React.Dispatch<React.SetStateAction<string[]>>;
    inputsRef: React.MutableRefObject<(HTMLInputElement | null)[]>;
    handleChange: (index: number, value: string) => void;
    handleKeyDown: (index: number, event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleVerification: (event: React.FormEvent<HTMLFormElement>) => void;
    verificationError: string;
    handleResendConfirmationCode: () => void;
}

const AccountVerification: React.FC<AccountVerificationProps> = ({ verificationCode, setVerificationCode, inputsRef, handleChange, handleKeyDown, handleVerification, verificationError, handleResendConfirmationCode }) => {
    // Add your component logic here

    return (
        <div className='verify-account-content'>
            <nav className="register-top-nav">
                <ul className="register-top-nav-listing-ul">
                    <li className="register-top-left-area-li">
                    </li>
                    <li className="register-top-middle-area">
                        <img src={logo} alt="10-Things" className="register-logo-img" />
                    </li>
                    <li className="register-top-right-area-li">
                    </li>
                </ul>
            </nav>
            <div className="register-inner-wrapper-div">
                <div className="register-inner-container-div">
                    <form className="register-form" onSubmit={handleVerification}>
                        <h1 className='account-verify-title-h1'>Verifieer je account!</h1>
                        <p className="account-verify-txt-p">Je moet wel nog je account verifieren voordat je gebruik kan maken van 10 Things</p>
                        <div className="verification-wrapper-div">
                            <div className="verification-input-container">
                                {verificationCode.map((digit, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        maxLength={1}
                                        value={digit}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                        ref={(el) => (inputsRef.current[index] = el)}
                                        className="verification-input"
                                        inputMode="numeric"
                                    />
                                ))}
                            </div>
                            {verificationError && <p className="register-err-txt-p">{verificationError}</p>}
                        </div>
                        <button className="verification-resend-confirm-code-btn" onClick={handleResendConfirmationCode}>
                            Verstuur nogmaals
                        </button>
                        <button className="register-next-btn" type='submit'>Account verifieren</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AccountVerification;