import { Branch } from "../../models/Branch";
import api from "../auth/api";

export const fetchBranches = async (): Promise<Branch[]> => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/branches/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch branches:', error);
        throw error;
    }
};