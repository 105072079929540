import React from 'react';
import NoteItemDeBriefing from '../../NoteItemDeBriefing/NoteItemDeBriefing';
import { Note } from '../../../../../../models/Note';
import './OrangeNoteView.css';

interface OrangeNoteViewProps {
    note_type: number;
    orangeNoteTxt: string;
    setOrangeNoteTxt: (txt: string) => void;
}

const OrangeNoteView: React.FC<OrangeNoteViewProps> = ({ note_type, orangeNoteTxt, setOrangeNoteTxt }) => {
    return (
        <div className="orange-note-view">
            <h2 className='note-title-h2'>Wat kon beter?</h2>
            <NoteItemDeBriefing note_txt={orangeNoteTxt} setNoteTxt={setOrangeNoteTxt} note_type={note_type} />
        </div>
    );
};

export default OrangeNoteView;