import React from 'react';
import './Footer.css';
import logowhite from '../../../assets/img/website-10things-logo-v2-white.png';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <footer className="main-page-footer">
            <ul className="main-page-footer-listing-ul">`
                <div className="main-page-footer-wrapper">
                    <li className="main-page-footer-item-li">
                        <img src={logowhite} alt="10-Things" className="main-page-footer-logo-img" />
                        <p className="main-page-footer-txt-p"><b>10-Things</b></p>
                        <p className="main-page-footer-txt-p extra-space">Columbusplein 54-HS<br />1057 VC Amsterdam</p>
                        <p className="main-page-footer-txt-p"><span className="main-page-footer-var-span">T</span> +31 6 12 12 77 40</p>
                        <p className="main-page-footer-txt-p extra-space"><span className="main-page-footer-var-span">E</span> info@10-things.nl</p>
                        <p className="main-page-footer-txt-p"><span className="main-page-footer-var-span">KVK</span> 85393401</p>
                        <p className="main-page-footer-txt-p"><span className="main-page-footer-var-span">BTW</span> NL863608565B01</p>
                        <p className="main-page-footer-txt-p"><span className="main-page-footer-var-span">IBAN</span> NL 89 RABO 0378 0107 51</p>
                        <p className="main-page-footer-txt-p"><span className="main-page-footer-var-span">BIC</span> RABONL2U</p>
                    </li>
                    <li className="main-page-footer-item-li">
                        <h4 className="main-page-footer-item-title-h4">10-Things</h4>
                        <ul className="main-page-footer-links-listing">
                            <li className="main-page-footer-links-item-li">
                                <Link to="/#main-site-content" className="main-page-footer-link-p">
                                    <p className="main-page-footer-link-p">Home</p>
                                </Link>
                            </li>
                            <li className="main-page-footer-links-item-li">
                                <Link to="/#pricing" className="main-page-footer-link-p">
                                    <p className="main-page-footer-link-p">Prijzen overzicht</p>
                                </Link>
                            </li>
                            <li className="main-page-footer-links-item-li">
                                <Link to="/register" className="main-page-footer-link-p">
                                    <p className="main-page-footer-link-p">Probeer het gratis!</p>
                                </Link>
                            </li>
                            <li className="main-page-footer-links-item-li">
                                <Link to="/contact" className="main-page-footer-link-p">
                                    <p className="main-page-footer-link-p">Contact</p>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="main-page-footer-item-li">
                        <h4 className="main-page-footer-item-title-h4">Support</h4>
                        <ul className="main-page-footer-links-listing">
                            <li className="main-page-footer-links-item-li">
                                <Link to="/faq" className="main-page-footer-link-p">
                                    <p className="main-page-footer-link-p">FAQ</p>
                                </Link>
                            </li>
                            <li className="main-page-footer-links-item-li">
                                <Link to="/algemene-voorwaarden" className="main-page-footer-link-p">
                                    <p className="main-page-footer-link-p">Algemene voorwaarden</p>
                                </Link>
                            </li>
                            <li className="main-page-footer-links-item-li">
                                <Link to="/privacy-overeenkomst" className="main-page-footer-link-p">
                                    <p className="main-page-footer-link-p">Privacy overeenkomst</p>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </div>

                <li>
                    <ul className="main-page-under-footer-listing-ul">
                        <li className="main-page-under-footer-item-li">
                            <p className="main-page-under-footer-txt-p copyright">© 2024 10-Things alle rechten voorbehouden.</p>
                        </li>
                    </ul>
                </li>
            </ul>
        </footer>
    );
};

export default Footer;