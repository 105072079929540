import React, { useEffect, useRef, useState } from 'react';
import './InvoicesView.css';
import { Pagination, Skeleton, Tab, Tabs } from '@nextui-org/react';
import { fetchInvoicesByParentCompany } from '../../../../../api/invoices/invoicesService';
import { Helmet } from 'react-helmet-async';

interface InvoicesViewProps {
    // Add any props you need for the component here
    selectedMenu: Boolean;
    setSelectedMenu: React.Dispatch<React.SetStateAction<Boolean>>;
}

enum InvoiceStatusType {
    PENDING = 1,
    PAID = 2,
    OVERDUE = 3
}

const InvoicesView: React.FC<InvoicesViewProps> = (props) => {
    // Add your component logic here

    const handleCloseBtnClick = () => {
        props.setSelectedMenu(!props.selectedMenu);
    };

    const [invoices, setInvoices] = React.useState<any[]>([]);

    const [page, setPage] = React.useState<number>(1);
    const [limit, setLimit] = React.useState<number>(20);
    const [totalPages, setTotalPages] = React.useState<number>(0);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    useEffect(() => {
        const loadInvoices = async () => {
            try {
                const response = await fetchInvoicesByParentCompany(page, limit);
                setInvoices(response.invoices);
                setTotalPages(response.total_pages);
                setCurrentPage(response.current_page);
            } catch (error) {
                console.error(error);
            } finally {
                console.log('Invoices loaded');
            }
        };

        loadInvoices();
    }, [page, limit]);

    const [dropdownVisible, setDropdownVisible] = useState<{ [key: number]: boolean }>({});

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownVisible({});
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const toggleDropdown = (index: number) => {
        setDropdownVisible(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    return (
        <div className='subscriptions-container'>
            <Helmet>
                <title>10-Things | Portaal | Facturen</title>
            </Helmet>
            <div className="settings-top-bar">
                <h2 className="settings-title-h2">Facturen</h2>
                <button className="settings-mobile-close-btn" type='button' onClick={handleCloseBtnClick}>
                    <span className="material-symbols-rounded settings-mobile-close-icon-span">close</span>
                </button>
            </div>
            <table className="styled-table">
                <thead>
                    <tr>
                        <th className='invoice-ref-th'>Referentie</th>
                        <th className='invoice-date-th'>Datum</th>
                        <th className='invoice-duedate-th'>Vervaldatum</th>
                        <th className='invoice-amount-th'>Bedrag</th>
                        <th className='invoice-status-th'>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {invoices.map((invoice: any, index: number) => {
                        const amountInEuro = invoice.amount.toLocaleString('de-DE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        });

                        const status = invoice.status;

                        const statusBubble = (status: number) => {
                            const invoiceStatus = status as InvoiceStatusType;
                            switch (invoiceStatus) {
                                case InvoiceStatusType.PENDING:
                                    return <span className="invoice-status-bubble open">Openstaand</span>;
                                case InvoiceStatusType.PAID:
                                    return <span className="invoice-status-bubble payed">Betaald</span>;
                                case InvoiceStatusType.OVERDUE:
                                    return <span className="invoice-status-bubble due">Vervallen</span>;
                                default:
                                    return <span className="invoice-status-bubble unkown">Onbekend</span>;
                            }
                        }

                        const statusElement = statusBubble(status);

                        return (
                            <tr key={index}>
                                <td className='invoice-ref-td'><a className='downloadable-a-href' href={invoice.invoice_url}>{invoice.invoice_number}</a></td>
                                <td className='invoice-date-td'>{invoice.invoice_date}</td>
                                <td className='invoice-duedate-td'>{invoice.due_date}</td>
                                <td className='invoice-amount-td'>€ {amountInEuro}</td>
                                <td className='invoice-status-td'>{statusElement}</td>
                                <td className='invoice-options-td'>
                                    <button className="table-options-btn" type='button' onClick={() => toggleDropdown(index)}><span className="material-symbols-outlined table-options-icon">more_vert</span></button>
                                    {dropdownVisible[index] && (
                                        <div className="dropdown-menu" ref={dropdownRef}>
                                            <ul className="dropdown-menu-listing">
                                                <li className="dropdown-menu-item"><a className='downloadable-li-a-href' href={invoice.invoice_url}>Downloaden</a></li>
                                            </ul>
                                        </div>
                                    )}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="invoices-pagination-container">
                <Pagination showControls total={totalPages} initialPage={currentPage} page={currentPage} onChange={(page: number) => setCurrentPage} />
            </div>
        </div>
    );
};

export default InvoicesView;