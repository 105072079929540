import React from 'react';
import PortaalPageLayout from '../../../components/Portaal/PageLayout/PageLayout';
import { Subscription } from '../../../models/Subscription';

interface InvoicesViewProps {
    toggleNav: () => void;
    isNavOpen: boolean;
    handleOpenBuyCreditsModal: () => void;
    openBuyCreditsModal: boolean;
    setOpenBuyCreditsModal: React.Dispatch<React.SetStateAction<boolean>>;
    activeSubscription: Subscription | null | undefined;
}

const InvoicesView: React.FC<InvoicesViewProps> = ( props ) => {
    return (
        <div>
            <PortaalPageLayout activePage='facturen' isNavOpen={props.isNavOpen} toggleNav={props.toggleNav} handleOpenBuyCreditsModal={props.handleOpenBuyCreditsModal} openBuyCreditsModal={props.openBuyCreditsModal} setOpenBuyCreditsModal={props.setOpenBuyCreditsModal} activeSubscription={props.activeSubscription}>
                Test
            </PortaalPageLayout>
        </div>
    );
};

export default InvoicesView;