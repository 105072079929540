import { MenuDetail, MenuDetailUpdate } from "../../models/MenuDetail";
import api from "../auth/api";

export const updateMenuDetail = async (menuDetailID: number, menuDetail: MenuDetailUpdate) => { 
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const response = await api.put(`/menu_details/${menuDetailID}/`, menuDetail, config);
        return response.data;
    } catch (error) {
        console.error('Failed to update menu detail:', error);
    }
}
