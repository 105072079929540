import { UploadedLogoResponse, UploadLogoModel } from "../../models/UploadLogo";
import backend from "../backend";

export const UploadLogoDB = async (uploadlogo: UploadLogoModel): Promise<UploadedLogoResponse> => {
    const formData = new FormData();
    formData.append('user_id', uploadlogo.user_id.toString());
    formData.append('file', uploadlogo.file);

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        }
    };
    try {
        const url = `/upload-logo/`;
        const response = await backend.post(url, formData, config);
        return response.data;
    } catch (error) {
        console.error('Failed to upload logo:', error);
        throw error;
    }
}