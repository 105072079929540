import React, { useEffect, useState } from 'react';
import PaymentViewLayout from '../../components/PaymentView/PaymentViewLayout/PaymentViewLayout';
import './PaymentView.css';
import restaurantIMG from '../../assets/img/restaurant.jpg';
import logo from '../../assets/img/website-10things-logo.png';
import PaymentInfoView from './InnerViews/PaymentInfoView/PaymentInfoView';
import PaymentMethodView from './InnerViews/PaymentMethodView/PaymentMethodView';
import { createCustomer, fetchMethods } from '../../api/mollie/mollieServices';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchProductByIdentifier } from '../../api/products/productsService';
import { getSubsidiary, getUserInfo } from '../../api/auth/user-info';
import { createMollieCustomer, fetchCustomerByParentCompany } from '../../api/mollie_customer/mollie_customerServices';
import { useHistory } from '../../Providers/HistoryProvider';
import usePageTracking from '../../utils/usePageTracking';
import { Helmet } from 'react-helmet-async';
import { Product } from '../../models/Product';
import { ProductType } from '../../enums/ProductType';

interface PaymentViewProps {
    // Add any props you need for the PaymentView component
}

const generateInvoiceNumber = (): string => {
    // Generate two random numbers
    const part1 = Math.floor(Math.random() * 10000); // 0 to 9999
    const part2 = Math.floor(Math.random() * 10000); // 0 to 9999

    // Format numbers to have four digits
    const formattedPart1 = part1.toString().padStart(4, '0');
    const formattedPart2 = part2.toString().padStart(4, '0');

    // Concatenate with a hyphen
    return `${formattedPart1}-${formattedPart2}`;
};

const PaymentView: React.FC<PaymentViewProps> = () => {
    // Add your component logic here
    usePageTracking();
    const subsidiary = getSubsidiary();
    const userInfo = getUserInfo();

    console.log('UserInfo: ', userInfo);

    const parent_company_id = userInfo.parent_company_id;

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const p = queryParams.get('p');
    const productAmount = Number(queryParams.get('a')) || 1;
    console.log('Query params:', p);

    const [product, setProduct] = useState<Product>();

    useEffect(() => {
        if (!p) {
            navigate(-1);
            return;
        }

        const loadProductByIdentifier = async () => {
            try {
                const response = await fetchProductByIdentifier(p as string);
                console.log('Product:', response);
                setProduct(response);
            } catch (err) {
                console.error('Failed to fetch product:', err);
            } finally {
                console.log('Done fetching product');
            }
        }

        loadProductByIdentifier();

    }, [p, navigate]);

    const [customerID, setCustomerID] = useState<string>('');
    
    const amount = product ? (product.price * productAmount) : 0.00;
    const vat = amount * 0.21;
    const total = amount + vat;

    const transactionId = generateInvoiceNumber();

    const [step, setStep] = React.useState<number>(0);

    const handleClick = () => {

        const checkParentCompanyHasCustomer = async () => {

            const response = await fetchCustomerByParentCompany(parent_company_id as string);
            console.log('Customer:', response);

            if (response) {
                setCustomerID(response.mollie_customer_id);
                console.log('Customer exists');
            } else {
                const customer = await createCustomer({
                    'name': userInfo.firstname + ' ' + userInfo.lastname,
                    'email': userInfo.email,
                    'locale': 'nl_NL',
                });

                await createMollieCustomer({
                    'name': userInfo.firstname + ' ' + userInfo.lastname,
                    'email': customer.email,
                    'locale': customer.locale,
                    'mollie_customer_id': customer.id,
                    'parent_company_id': parent_company_id,
                });

                setCustomerID(customer.id);
            };
        }

        checkParentCompanyHasCustomer();

        setStep(step + 1);
    }

    const [methods, setMethods] = React.useState<any[]>([]);

    useEffect(() => {
        const loadMethods = async () => {
            try {
                const response = await fetchMethods();
                console.log('Methods:', response._embedded.methods);
                setMethods(response._embedded.methods);
            } catch (err) {
                console.error('Failed to fetch methods:', err);
            } finally {
                console.log('Done fetching methods');
            }
        }

        loadMethods();
    }, []);

    const history = useHistory();

    const goBack = () => {
        const previousUrl = history[history.length - 2]; // Get the previous URL
        if (previousUrl && previousUrl.includes('betaling-geannuleerd')) {
            navigate('/portaal/dashboard');
        } else {
            navigate(-1);
        }
    };

    return (
        <div>
            <Helmet>
                <title>10-Things | Start Abonnement</title>
            </Helmet>
            <PaymentViewLayout>
                <div className="payment-left-area">
                    <img src={restaurantIMG} alt="restaurant" className="payment-img-left" />
                </div>
                <div className="payment-right-area">
                    {step === 0 ? <button className="cancel-payment-btn" type='button' onClick={goBack}><span className="cancel-payment-icon material-symbols-rounded">arrow_back</span><span className="cancel-payment-txt">Ga terug</span></button> : null}
                    <div className="payment-wrapper">
                        <div className="payment-inner-content-div">
                            <img src={logo} alt="10Things logo" className="payment-logo-img" />
                            <div id="payment-pay-progress-area-div">
                                <ul id="payment-pay-progress-bar-ul">
                                    <li id="payment-pay-progress-step-li" className={`step0 ${step === 1 ? 'active' : ''}`}>
                                        <span id="payment-pay-step-txt-span">Informatie</span>
                                        <span id="payment-pay-step-icon-span" className={`step0 inprogress ${step === 1 ? 'active' : ''}`}><span id="step-icon" className="material-symbols-rounded">done</span></span>
                                    </li>
                                    <li id="payment-pay-progress-step-li" className={`step1 ${step > 1 ? 'active' : ''}`}>
                                        <span id="payment-pay-step-txt-span">Betalen</span>
                                        <span id="payment-pay-step-icon-span" className={`step1 ${step === 1 ? 'inprogress' : ''} ${step > 1 ? 'active' : ''}`}><span id="step-icon" className="material-symbols-rounded">done</span></span>
                                        <span id="payment-pay-step-txt-span">Bevestiging</span>
                                        <span id="payment-pay-step-icon-span" className="step2"><span id="step-icon" className="material-symbols-rounded">done</span></span>
                                    </li>
                                </ul>

                            </div>
                            <div className="payment-content-area">
                                {step === 0 && product ? <PaymentInfoView transactionId={transactionId} product={product} productAmount={productAmount} amount={amount} vat={vat} total={total} /> : null}
                                {step === 1 && product ? <PaymentMethodView methods={methods} transactionId={transactionId} amount={total} customerID={customerID} productID={product.id} product={product} productAmount={productAmount} /> : null}
                                {product?.product_type === ProductType.SUBSCRIPTION && <div className="payment-confirm-txt-area">
                                    <p className="payment-confirm-txt-p">Om het proefabonnement te kunnen starten moet u eerst <b>€ 0,01</b> cent overmaken. Na afloop van uw proefabonnement wordt de betaling automatisch van uw rekening afgeschreven. Het abonnement kunt u op ieder moment zelf wijzigen of stopzetten via de instellingen in de app.</p>
                                </div>}
                                <div className="payment-controls-area">
                                    {step === 0 ? <button className="payment-next-btn" type='button' onClick={handleClick}>
                                        {product?.product_type === ProductType.SUBSCRIPTION ? 'Start proefabonnement' : 'Betaal'}
                                    </button> : null}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </PaymentViewLayout>
        </div>
    );
};

export default PaymentView;