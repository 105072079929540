import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GoogleAnalytics  from "react-ga4";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    GoogleAnalytics.send({ hitType: "pageview", page: location.pathname, title: document.title });
  }, [location]);
};

export default usePageTracking;
