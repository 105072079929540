import React from 'react';
import './LoginVerification.css';

interface LoginVerificationProps {
    verificationCode: string[];
    inputsRef: React.MutableRefObject<(HTMLInputElement | null)[]>;
    handleChange: (index: number, value: string) => void;
    handleKeyDown: (index: number, event: React.KeyboardEvent<HTMLInputElement>) => void;
    verificationError: string;
    handleResendConfirmationCode: () => void;
    handleVerification: (event: React.FormEvent<HTMLFormElement>) => void;
    isLoading: boolean;
}

const LoginVerification: React.FC<LoginVerificationProps> = ({ verificationCode, inputsRef, handleChange, handleKeyDown, verificationError, handleResendConfirmationCode, handleVerification, isLoading }) => {

    return (
        <form className="register-form" onSubmit={handleVerification}>
            <h1 className='account-login-verify-title-h1'>Verifieer je account!</h1>
            <p className="account-login-verify-txt-p">Je moet wel nog je account verifieren voordat je gebruik kan maken van 10-Things</p>
            <div className="verification-account-wrapper-div">
                <div className="verification-account-input-container">
                    {verificationCode.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            maxLength={1}
                            value={digit}
                            onChange={(e) => handleChange(index, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            ref={(el) => (inputsRef.current[index] = el)}
                            className="verification-account-input"
                            inputMode="numeric"
                        />
                    ))}
                </div>
                {verificationError && <p className="register-err-txt-p">{verificationError}</p>}
            </div>
            <button className="verification-account-btn" type='submit'>
                Verifieer
            </button>
            <button className="verification-account-resend-confirm-code-btn" type='button' onClick={handleResendConfirmationCode}>
                Verstuur nogmaals
            </button>
        </form>
    );
};

export default LoginVerification;