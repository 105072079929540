import React from 'react';
import NoteItemDeBriefing from '../../NoteItemDeBriefing/NoteItemDeBriefing';
import { Note } from '../../../../../../models/Note';
import './YellowNoteView.css';

interface YellowNoteViewProps {
    note_type: number;
    yellowNoteTxt: string;
    setYellowNoteTxt: (txt: string) => void;
}

const YellowNoteView: React.FC<YellowNoteViewProps> = ({ note_type, yellowNoteTxt, setYellowNoteTxt }) => {
    return (
        <div className="yellow-note-view">
            <h2 className='note-title-h2'>Welke bijzonderheden?</h2>
            <NoteItemDeBriefing note_txt={yellowNoteTxt} setNoteTxt={setYellowNoteTxt} note_type={note_type} />
        </div>
    );
};

export default YellowNoteView;