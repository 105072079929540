import React, { useState } from 'react';
import './Verification.css';

interface VerificationProps {
    verificationCode: string[];
    setVerificationCode: React.Dispatch<React.SetStateAction<string[]>>;
    inputsRef: React.MutableRefObject<(HTMLInputElement | null)[]>;
    handleChange: (index: number, value: string) => void;
    handleKeyDown: (index: number, event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleVerification: (event: React.FormEvent<HTMLFormElement>) => void;
    verificationError: string;
    handleResendConfirmationCode: () => void;
}

const Verification: React.FC<VerificationProps> = ({ verificationCode, setVerificationCode, inputsRef, handleChange, handleKeyDown, handleVerification, verificationError, handleResendConfirmationCode }) => {

       const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text');
        if (/^\d+$/.test(pasteData)) {
            const newDigits = pasteData.split('').slice(0, verificationCode.length);
            setVerificationCode(newDigits);
            newDigits.forEach((digit, index) => {
                if (inputsRef.current[index]) {
                    inputsRef.current[index]!.value = digit;
                }
            });
            // Focus the last input field
            inputsRef.current[newDigits.length - 1]?.focus();
        }
    };

    return (
        <div>
            <h2 className="forgot-password-title">Verificatie code</h2>
            <div className="forgot-password-form-input-bar">
                <div className="forgot-password-verification-bar">
                    {verificationCode.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            maxLength={1}
                            value={digit}
                            onChange={(e) => handleChange(index, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            onPaste={handlePaste}
                            ref={(el) => (inputsRef.current[index] = el)}
                            className="verification-input"
                            inputMode="numeric"
                        />
                    ))}
                </div>
                {verificationError && <div className="forgot-password-error-field">
                    <p className="forgot-password-error-txt">{verificationError}</p>
                </div>}
            </div>
            <div className="forgot-password-form-input-bar">
                <button type="submit" className="forgot-password-button">
                    <div className="forgot-password-txt-div">Verstuur verificatiecode</div>
                </button>
                <p className="forgot-password-under-btn-txt-p" onClick={handleResendConfirmationCode}>Verstuur nogmaals</p>
            </div>
        </div>
    );
};

export default Verification;