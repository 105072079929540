import React, { useState } from 'react';
import './PaymentViewLayout.css';

interface PaymentViewLayoutProps {
    children: React.ReactNode;
}

const PaymentViewLayout: React.FC<PaymentViewLayoutProps> = ( props ) => {

    return (
        <div id='payment-pagelayout'>
            <main id='payment-content'>
                {props.children}
            </main>
        </div>
    );
};

export default PaymentViewLayout;