import React, { useState } from 'react';
import './SideCalendarView.css';

interface SideCalendarProps {
    visibleMonths: number;
    minStartDate: Date;
}

const SideCalendar: React.FC<SideCalendarProps> = ({ visibleMonths, minStartDate }) => {
    const [selectedDates, setSelectedDates] = useState<string[]>([]);
    const [currentMonth, setCurrentMonth] = useState<Date>(minStartDate);

    const handleDateClick = (date: Date) => {
        const dateString = date.toISOString();
            if (selectedDates.includes(dateString)) {
                setSelectedDates(selectedDates.filter((d) => d !== dateString));
            } else {
                setSelectedDates([...selectedDates, dateString]);
            }
    };

    const handleDateChange = (direction: number) => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + direction, 1));
    };

    const generateMonths = () => {
        const months: Date[] = [];
        let currentDate = new Date(currentMonth);

        for (let i = 0; i < visibleMonths; i++) {
            months.push(currentDate);
            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        }

        return months;
    };

    return (
        <div className='sidecalendar-months-container-div'>
            <button className='sidecalendar-month-backward-btn' onClick={() => handleDateChange(-visibleMonths)}>
                <span className="sidecalendar-month-icon-span material-symbols-outlined">chevron_left</span>
            </button>
            <button className='sidecalendar-month-forward-btn' onClick={() => handleDateChange(visibleMonths)}>
                <span className="sidecalendar-month-icon-span material-symbols-outlined">chevron_right</span>
            </button>
            {generateMonths().map((month) => (
                <div key={month.toISOString()} className='sidecalendar-month-area-div'>
                    <h2 className='sidecalendar-month-year-title-h2'>{month.toLocaleString('default', { month: 'long', year: 'numeric' })}</h2>
                    <div className='sidecalendar-day-labels'>
                        <span>Ma</span>
                        <span>Di</span>
                        <span>Wo</span>
                        <span>Do</span>
                        <span>Vr</span>
                        <span>Za</span>
                        <span>Zo</span>
                    </div>
                    <div className='sidecalendar-month-inner-content'>
                        <div className="sidecalendar-calendar-grid">
                            {Array.from({ length: new Date(month.getFullYear(), month.getMonth(), 1).getDay() - 1 }).map((_, index) => (
                                <div key={index} className="sidecalendar-date-placeholder"></div>
                            ))}
                            {Array.from({ length: new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate() }).map((_, index) => {
                                const date = new Date(month.getFullYear(), month.getMonth(), index + 1);
                                const dateString = date.toISOString();
                                const isSelected = selectedDates.includes(dateString);
                                const isBeforeMinStartDate = date < minStartDate;

                                return (
                                    <button
                                        key={dateString}
                                        onClick={() => !isBeforeMinStartDate && handleDateClick(date)}
                                        className={`sidecalendar-date-button ${isSelected ? 'selected' : ''}`}
                                    >
                                        {date.getDate()}
                                    </button>
                                );
                            })}

                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SideCalendar;