import React, { useEffect, useState } from 'react';
import PaymentViewLayout from '../../components/PaymentView/PaymentViewLayout/PaymentViewLayout';
import restaurantIMG from '../../assets/img/restaurant.jpg';
import './PaymentCanceledView.css';
import logo from '../../assets/img/website-10things-logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getTransactionByOrderID } from '../../api/transactions/transactionsService';
import { fetchProduct } from '../../api/products/productsService';
import { Product } from '../../models/Product';
import { Spinner } from '@nextui-org/react';
import { Helmet } from 'react-helmet-async';
import usePageTracking from '../../utils/usePageTracking';

const PaymentCanceledView: React.FC = () => {
    usePageTracking();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    console.log('Query params:', id);

    const [isLoading, setIsLoading] = useState(true);

    const [transaction, setTransaction] = useState<any>();
    const [product, setProduct] = useState<Product>();

    useEffect(() => {
        if (!id) {
            navigate('/login');
            return;
        }

        const loadTransactionByOrderID = async () => {
            try {
                setIsLoading(true);
                const response = await getTransactionByOrderID(id as string);
                console.log('Transaction:', response);
                setTransaction(response);
            } catch (err) {
                console.error('Failed to fetch transaction:', err);
            } finally {
                console.log('Done fetching transaction');
            }
        }
        loadTransactionByOrderID();
    }, [id, navigate]);

    useEffect(() => {
        if (!transaction) {
            return;
        }
        const loadProduct = async () => {
            try {
                setIsLoading(true);
                const response = await fetchProduct(transaction.product_id as string);
                console.log('Product:', response);
                setProduct(response);
            } catch (err) {
                console.error('Failed to fetch product:', err);
            } finally {
                console.log('Done fetching product');
                setIsLoading(false);
            }
        }
        loadProduct();
    }, [transaction]);

    return (
        <div>
            <Helmet>
                <title>10-Things | Betaling Geannuleerd</title>
            </Helmet>
            <PaymentViewLayout>
                <div className="payment-left-area">
                    <img src={restaurantIMG} alt="restaurant" className="payment-img-left" />
                </div>
                <div className="payment-right-area">
                    <div className="payment-wrapper">
                        <div className="payment-inner-content-div">

                            {isLoading && <div className='loading-spinner-container'><Spinner color="primary" size='lg' /></div>}

                            {isLoading ? null : <><img src={logo} alt="10Things logo" className="payment-logo-img" />
                                <svg className="checkmarkfailed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                    <circle className="checkmarkfailed__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path className="checkmark__check" d="M15 15 L35 35 M35 15 L15 35" stroke="#fff" stroke-width="3" stroke-linecap="round" />
                                </svg>

                                <h2 id="betaling-pay-content-title-h2">Er is helaas iets mis gegaan!</h2>
                                <br />
                                <p id="payment-confirmed-txt-p">Helaas hebben wij uw betaling niet kunnen ontvangen. Het is mogelijk dat er een fout is opgetreden tijdens het betalingsproces.<br /><br /> Als de betaling wel is afgeschreven maar niet verwerkt, verzoeken wij u vriendelijk contact met ons op te nemen. Wij helpen u graag om dit probleem zo snel mogelijk op te lossen.<br /><br />Heeft u nog vragen? Neem dan gerust contact met ons op.</p>
                                <Link to={`/betalen?p=${product?.product_identifier}`} >
                                    <button id="payment-confirmed-butt" type='button'>
                                        <span id="payment-confirmed-butt-txt-span">Probeer opnieuw</span>
                                        <span id="payment-confirmed-butt-icon-span" className="material-symbols-rounded">refresh</span>
                                    </button>
                                </Link></>}

                        </div>
                    </div>
                </div>
            </PaymentViewLayout>
        </div>
    );
};

export default PaymentCanceledView;