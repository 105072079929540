import React from 'react';
import './CheckEmail.css';
import { Link } from 'react-router-dom';

interface CheckEmailProps {
    email: string;
    setEmail: (email: string) => void;
    emailError: string;
}

const CheckEmail: React.FC<CheckEmailProps> = ({ email, setEmail, emailError }) => {
    return (
        <div className="check-email-inner-view">
            <h2 className="forgot-password-title">Wachtwoord vergeten?</h2>
            <div className="forgot-password-form-input-bar">
                <label htmlFor="forgot-password-email-input" className="forgot-password-label">E-mail</label>
                <input type="text" className="forgot-password-input" id="forgot-password-email-input" value={email} onChange={(e) => setEmail(e.target.value)} />
                {emailError && <div className="forgot-password-error-field">
                    <p className="forgot-password-error-txt">{emailError}</p>
                </div>}
            </div>
            <div className="forgot-password-form-input-bar">
                <button type="submit" className="forgot-password-button">
                    <div className="forgot-password-txt-div">Reset wachtwoord</div>
                </button>
                <Link to="/login">
                    <p className="forgot-password-under-btn-txt-p">Weet je je wachtwoord wel? Log in</p>
                </Link>
            </div>


        </div>
    );
};

export default CheckEmail;