import React from 'react';
import './CreditsBar.css';
import { CreditsIcon } from '../../../../assets/icons/CreditsIcon/CreditsIcon';
import { getSubsidiary } from '../../../../api/auth/user-info';
import { Subsidiary } from '../../../../models/Subsidiary';

interface CreditsBarProps {
    handleOpenBuyCreditsModal?: () => void;
}

const CreditsBar: React.FC<CreditsBarProps> = ({handleOpenBuyCreditsModal}) => {

    const getCredits = () => {
        const subsidiary = getSubsidiary();
        return subsidiary.credits;
    }

    return (
        <div className="credits-bar" onClick={handleOpenBuyCreditsModal}>
            <CreditsIcon className='credits-icon' />
            <span className="credits-value">{getCredits()}</span>
        </div>
    );
};

export default CreditsBar;