import api from "../auth/api";

export const addTransaction = async (transaction: any) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/transactions/`;
        const response = await api.post(url, transaction, config);
        return response.data;
    } catch (error) {
        console.error('Failed to add transaction:', error);
        throw error;
    }
};

export const getTransactionByOrderID = async (orderId: string) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/transactions/order/${orderId}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch transaction:', error);
        throw error;
    }
};