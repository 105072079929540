import api from "../auth/api";
import { getUserInfo } from "../auth/user-info";

export const fetchSubscriptionByParentCompany = async () => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const user = getUserInfo();
        const url = `/subscriptions/get_subscription_by_parent_company/${user.parent_company_id}`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch subscription:', error);
        throw error;
    }
}

export const fetchSubscriptionBySubsidiary = async () => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };
    try {
        const user = getUserInfo();
        const url = `/subscriptions/get_subscription_by_subsidiary/${user.subsidiary_id}`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch subscription:', error);
        throw error;
    }
}

export const fetchActiveSubscriptionByParentCompany = async () => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const user = getUserInfo();
        const url = `/subscriptions/get_active_subscription_by_parent_company/${user.parent_company_id}`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch subscription:', error);
        throw error;
    }
}

export const fetchActiveSubscriptionBySubsidiary = async () => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const user = getUserInfo();
        const url = `/subscriptions/get_active_subscription_by_subsidiary/${user.subsidiary_id}`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch subscription:', error);
        throw error;
    }
}

export const cancelSubscription = async (customer_id: string, subscription_id: string, mandate_id: string)  => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/mollie/cancel_subscription/${customer_id}/${subscription_id}/${mandate_id}/`;
        const response = await api.delete(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to cancel subscription:', error);
        throw error;
    }
}
