import { Shifts } from "../../enums/Shifts";
import { Action, CreateAction } from "../../models/Action";
import api from "../auth/api";
import { getSubsidiary } from "../auth/user-info";

/**
 * Fetches actions by date.
 * @param {string} date - The date to fetch actions for.
 * @returns {Promise<any>} - A promise that resolves to the fetched actions.
 * @throws {Error} - If the fetch operation fails.
 */
export const fetchActionsByDate = async (date: string) => {
    const subsidiary = getSubsidiary();
    if (!subsidiary) {
        throw new Error('Subsidiary is null');
    }
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };
    try {
        const url = `/actions/get_actions_by_subsidiary_and_date/${subsidiary.id}/${date}/`;
        const response = await api.get(url, config);
        return response.data as Action[];
    } catch (error) {
        console.error('Failed to fetch actions:', error);
        throw error;
    }
};

export const fetchActionsBySubsidiaryDateShiftType = async (date: string, shiftType: string) => {
    const subsidiary = getSubsidiary();
    if (!subsidiary) {
        throw new Error('Subsidiary is null');
    }
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };
    try {
        const url = `/actions/action_by_subsidiary_date_shifttype/${subsidiary.id}/${date}/${shiftType}/`;
        const response = await api.get(url, config);
        console.log('Response:', response.data);
        return response.data as Action[];
    } catch (error) {
        console.error('Failed to fetch actions:', error);
        throw error;
    }
}

/**
 * Plans actions by sending a POST request to the server.
 * @param actions - An array of actions to be planned.
 * @returns A Promise that resolves to the response data from the server.
 * @throws If there is an error planning the actions.
 */
export const planActions = async (actions: CreateAction) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/actions/`;
        const response = await api.post(url, actions, config);
        console.log('Response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Failed to plan actions:', error);
        throw error;
    }
}

export const updateAction = async (action: any) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };
    try {
        const url = `/actions/${action.id}/`;
        const response = await api.put(url, action, config);
        return response.data;
    } catch (error) {
        console.error('Failed to update action:', error);
        throw error;
    }
}

export const deleteDBAction = async (actionID: number) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };
    try {
        const url = `/actions/${actionID}/`;
        const response = await api.delete(url, config);
        console.log('Deleting Action:', response.data);
        return response.data;
    } catch (error) {
        console.error('Failed to delete action:', error);
        throw error;
    }
}
