import React, { useEffect, useState } from 'react';
import './LogoUploadModal.css';
import { useDropzone } from 'react-dropzone';
import { UploadLogoModel } from '../../../../models/UploadLogo';
import { getUserInfo, updateSubsidiaryInfo } from '../../../../api/auth/user-info';
import { UploadLogoDB } from '../../../../backend/UploadLogo/UploadLogoService';
import { patchSubsidiaryDB } from '../../../../api/subsidiary/subsidiaryService';

interface LogoUploadModalProps {
    onClose: () => void;
    setHasLogo: (hasLogo: boolean) => void;
    setLogo: (logo: string) => void;
}

const LogoUploadModal: React.FC<LogoUploadModalProps> = (props) => {
    const [file, setFile] = useState<any>(null);
    const [preview, setPreview] = useState<string | null>(null);

    const onDrop = (acceptedFiles: any) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setFile(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
        },
        multiple: false,
        noClick: true,
        noKeyboard: true
    });

    useEffect(() => {
        // Revoke the data uri when component unmounts
        return () => {
            if (preview) {
                URL.revokeObjectURL(preview);
            }
        };
    }, [preview]);

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const handleSubmit = async () => {
        // Handle the file upload
        if (!file) {
            return;
        }
        try {
            const user = getUserInfo();
            const uploadLogo: UploadLogoModel = {
                user_id: user.id,
                file: file
            }

            const response = await UploadLogoDB(uploadLogo);
            console.log('Uploaded logo:', response);
            props.setHasLogo(true);
            props.setLogo(response.location);
            const patchSubsidiary = await patchSubsidiaryDB({ logo_id: response.id });
            console.log('Patched subsidiary', patchSubsidiary);        
            updateSubsidiaryInfo({ logo_id: patchSubsidiary.logo_id, logo: patchSubsidiary.logo, last_update_at: patchSubsidiary.last_update_at, updated_by: patchSubsidiary.updated_by });    
        } catch (e) {
            console.error('Failed to upload logo:', e);
        } finally {
            props.onClose();
        }
    };


    return (
        <div className='logo-upload-modal-overlay' onClick={props.onClose}>
            <div className="logo-upload-modal-wrapper">
                <div className="logo-upload-modal-container" onClick={stopPropagation}>
                    <div className="logo-upload-title-area">
                        <h1 className="logo-upload-title-h1">Upload Logo</h1>
                    </div>
                    <div className="logo-upload-modal-content-area">
                        <div className="logo-upload-modal-upload-container">
                            <div className="logo-upload-modal-inner-upload-div">
                                <div {...getRootProps()} className={`logo-upload-dropzone ${isDragActive ? 'active' : ''}`}>
                                    <input {...getInputProps()} />
                                    {
                                        file ? (
                                            <div className="logo-upload-preview">
                                                <img src={preview ?? ''} alt="Preview" className="logo-upload-preview-img" />
                                            </div>
                                        ) : (
                                            isDragActive ?
                                                <div className="logo-upload-drop-area">
                                                    <p className="logo-upload-drop-txt-p">Drop it like it's hot</p>
                                                </div> :
                                                <div className="logo-upload-add-file-inner-area">
                                                    <span className="logo-upload-file-icon-span material-symbols-outlined">upload</span>
                                                    <p className="logo-upload-add-file-txt-p">Sleep je logo hier of <a onClick={open} className='files-browse-a'>blader hier</a></p>
                                                </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="logo-upload-modal-controls-area">
                            <button className="logo-upload-modal-upload-btn" type='button' onClick={handleSubmit}>Upload</button>
                            <button className="logo-upload-modal-cancel-btn" type='button' onClick={props.onClose}>Annuleren</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogoUploadModal;