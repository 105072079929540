import api from "../auth/api";

export const fetchMethods = async () => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/mollie/payment_methods/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch payment methods:', error);
        throw error;
    }
};

export const createPayment = async (paymentData: any) => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    try {
        const url = `/mollie/create_payment/`;
        const response = await api.post(url, paymentData, config);
        return response.data;
    } catch (error) {
        console.error('Failed to create payment:', error);
        throw error;
    }
};

export const createCustomer = async (customerData: any) => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    try {
        const url = `/mollie/create_customer/`;
        const response = await api.post(url, customerData, config);
        return response.data;
    } catch (error) {
        console.error('Failed to create customer:', error);
        throw error;
    }
};