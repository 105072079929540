import React from 'react';
import './ConfirmStep.css';


interface ConfirmStepProps {
    // Add any props you need for the component here
}

const ConfirmStep: React.FC<ConfirmStepProps> = (props) => {
    return (
        <div></div>
    );
};

export default ConfirmStep;