import React, { useState } from 'react';
import './Calendar.css';

interface CalendarProps {
    visibleMonths: number;
    minStartDate: Date;
    isMultiSelect?: boolean;
    selectedDates: string[];
    setSelectedDates: (dates: string[]) => void;
}

const Calendar: React.FC<CalendarProps> = ({ visibleMonths, minStartDate, isMultiSelect = false, selectedDates, setSelectedDates }) => {

    const toLocalDateString = (date: Date): string => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // getMonth() is zero-indexed
        const day = date.getDate();
        return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    };

    const [currentMonth, setCurrentMonth] = useState<Date>(minStartDate);

    const handleDateClick = (date: Date) => {
        const dateString = toLocalDateString(date); // Use the new helper function
        if (isMultiSelect) {
            if (selectedDates.includes(dateString)) {
                setSelectedDates(selectedDates.filter((d) => d !== dateString));
            } else {
                setSelectedDates([...selectedDates, dateString]);
            }
        } else {
            setSelectedDates([dateString]);
        }

        // console.log(selectedDates);
    };

    const handleDateChange = (direction: number) => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + direction, 1));
    };

    const generateMonths = () => {
        const months: Date[] = [];
        let currentDate = new Date(currentMonth);

        for (let i = 0; i < visibleMonths; i++) {
            months.push(currentDate);
            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        }

        return months;
    };

    return (
        <div className='months-container-div'>
            <button className='month-backward-btn' onClick={() => handleDateChange(-visibleMonths)} type='button'>
                <span className="month-icon-span material-symbols-outlined">chevron_left</span>
            </button>
            <button className='month-forward-btn' onClick={() => handleDateChange(visibleMonths)} type='button'>
                <span className="month-icon-span material-symbols-outlined">chevron_right</span>
            </button>
            {generateMonths().map((month) => (
                <div key={month.toISOString()} className='month-area-div'>
                    <h2 className='month-year-title-h2'>{month.toLocaleString('default', { month: 'long', year: 'numeric' })}</h2>
                    <div className='day-labels'>
                        <span>Ma</span>
                        <span>Di</span>
                        <span>Wo</span>
                        <span>Do</span>
                        <span>Vr</span>
                        <span>Za</span>
                        <span>Zo</span>
                    </div>
                    <div className='month-inner-content'>
                        <div className="calendar-grid">
                            {Array.from({ length: new Date(month.getFullYear(), month.getMonth(), 1).getDay() - 1 }).map((_, index) => (
                                <div key={index} className="date-placeholder"></div>
                            ))}
                            {Array.from({ length: new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate() }).map((_, index) => {
                                const date = new Date(month.getFullYear(), month.getMonth(), index + 1);
                                const dateString = toLocalDateString(date); // Use the new helper function
                                const isSelected = selectedDates.includes(dateString);
                                const isBeforeMinStartDate = date < minStartDate;

                                return (
                                    <button
                                        key={dateString} // Use the new helper function for key
                                        onClick={() => !isBeforeMinStartDate && handleDateClick(date)}
                                        className={`date-button ${isBeforeMinStartDate ? 'deactivated' : ''} ${isSelected ? 'selected' : ''}`}
                                        type='button'
                                    >
                                        {date.getDate()}
                                    </button>
                                );
                            })}

                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Calendar;