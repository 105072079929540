import { Shift } from "../../models/Shift";
import { CreateVip, UpdateVip, Vip } from "../../models/Vip";
import api from "../auth/api";
import { getSubsidiary } from "../auth/user-info";

export const fetchVip = async (id: string): Promise<Vip> => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    };
    

    try {
        const url = `/vips/${id}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch vip:', error);
        throw error;
    }
};

export const fetchVips = async (): Promise<Vip[]> => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = '/vips';
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch vips:', error);
        throw error;
    }
};

export const fetchVipsByDateShiftType = async (date: string, shiftType: Shift): Promise<Vip[]> => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    const subsidiary = getSubsidiary();

    try {
        const url = `/vips/by_subsidiary_date_shifttype/${subsidiary.id}/${date}/${shiftType}`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch vips:', error);
        throw error;
    }
}

export const addVipDB = async (vip: CreateVip): Promise<Vip> => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };
    try {
        const url = `/vips/`;
        const response = await api.post(url, vip, config);
        return response.data;
    } catch (error) {
        console.error('Failed to add vip:', error);
        throw error;
    }
};

export const deleteVipDB = async (id: number): Promise<void> => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };
    try {
        const url = `/vips/${id}/`;
        const response = await api.delete(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to delete vip:', error);
        throw error;
    }
};

export const updateVipDB = async (id: number, vip: UpdateVip): Promise<Vip> => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };
    try {
        const url = `/vips/${id}/`;
        const response = await api.put(url, vip, config);
        return response.data;
    } catch (error) {
        console.error('Failed to update vip:', error);
        throw error;
    }
};