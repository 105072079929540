import { Input, Textarea } from '@nextui-org/react';
import React, { useState } from 'react';
import './AddThingModal.css';
import { addThing } from '../../../../api/things/thingsService';
import { getSubsidiary } from '../../../../api/auth/user-info';

interface AddThingModalProps {
    thingtype: { id: number; plural: string, singular: string, color: string, };
    closeAddThingModal: () => void;
    refreshThings: () => void;
}

const AddThingModal: React.FC<AddThingModalProps> = (props) => {
    const [title, setTitle] = useState('');

    const [titleError, setTitleError] = useState(false);
    const [titleErrorMSG, setTitleErrorMSG] = useState('');

    const [description, setDescription] = useState('');

    const [descriptionError, setDescriptionError] = useState(false);
    const [descriptionErrorMSG, setDescriptionErrorMSG] = useState('');

    const handleThingTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleThingDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        let formError = false;
        
        if (title === '') {
            setTitleError(true);
            setTitleErrorMSG('Titel mag niet leeg zijn');
            formError = true;
        }

        if (description === '') {
            setDescriptionError(true);
            setDescriptionErrorMSG('Beschrijving mag niet leeg zijn');
            formError = true;
        }

        if (!formError) {
            const subsidiary = getSubsidiary();
            await addThing({
                subsidiary_id: subsidiary.id,
                type_id: props.thingtype.id,
                title: title,
                description: description,
                from_template: false
            });
            props.refreshThings();
            props.closeAddThingModal();
        }

    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Input className='title-input' type="title" label="Titel" value={title} variant='bordered' isInvalid={titleError} errorMessage={titleErrorMSG} onChange={handleThingTitleChange}/>
                <Textarea
                    className='description-input'
                    variant='bordered'
                    label="Beschrijving"
                    value={description}
                    isInvalid={descriptionError} 
                    errorMessage={descriptionErrorMSG}
                    onChange={handleThingDescriptionChange}
                />
                <div className="thingtype-showcase-div">
                    <span className="thingtype-showcase-color-span" style={{ backgroundColor: props.thingtype.color }}>

                    </span>
                    <p className="thingtype-showcase-type-txt-p">{props.thingtype.singular}</p>
                </div>
                <div className="addthing-controls-div">
                    <button className='addthing-submit-control-btn' type="submit">Add Thing</button>
                    <button onClick={props.closeAddThingModal} className='addthing-cancel-control-btn' type="button">Annuleren</button>
                </div>
            </form>
        </div>
    );
};

export default AddThingModal;