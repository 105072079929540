import React, { useState } from 'react';
import './ChangePassword.css';

const ChangePassword: React.FC = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleChangePassword = () => {
        // Implement your logic to change the password here
    };

    return (
        <div>
            <form onSubmit={handleChangePassword}>
                <div className='password-input-bar'>
                    <label htmlFor="currentPassword" className='password-input-label'>Huidig wachtwoord</label>
                    <input
                        type="password"
                        id="currentPassword"
                        className='password-input'
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        autoComplete="current-password"
                    />
                </div>
                <div className='password-input-bar'>
                    <label htmlFor="newPassword" className='password-input-label'>Nieuw wachtwoord</label>
                    <input
                        type="password"
                        id="newPassword"
                        className='password-input'
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        autoComplete="new-password"
                    />
                </div>
                <div className='password-input-bar'>
                    <label htmlFor="confirmPassword" className='password-input-label'>Bevestig wachtwoord</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        className='password-input'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        autoComplete="new-password"
                    />
                </div>
                <button className="password-submit-btn" type="submit">Wijzig wachtwoord</button>
            </form>
        </div>
    );
};

export default ChangePassword;