import React, { useState } from 'react';
import './AddVIPBriefingModal.css';
import { Input, Slider, Textarea } from '@nextui-org/react';
import { Vip, VipBriefing } from '../../../../../models/Vip';

interface AddVIPBriefingModalProps {
    onClose: () => void;
    addVip: (vip: VipBriefing) => void;
    shift: string;
}

const AddVIPBriefingModal: React.FC<AddVIPBriefingModalProps> = (props) => {

    console.log(props.shift);

    const [name, setName] = useState<string>('');
    const [amountOfGuests, setAmountOfGuests] = useState<string>('1');
    const [details, setDetails] = useState<string>('');
    const [tableNumber, setTableNumber] = useState<string>('');

    const [nameError, setNameError] = useState<string>('');
    const [amountOfGuestsError, setAmountOfGuestsError] = useState<string>('');
    const [tableNumberError, setTableNumberError] = useState<string>('');

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const handleSubmit = () => {
        let error = false;

        if (name.trim() === '') {
            setNameError('Naam is verplicht');
            error = true;
        } else {
            setNameError('');
        }

        if (amountOfGuests.trim() === '') {
            setAmountOfGuestsError('Aantal gasten is verplicht');
            error = true;
        } else {
            setAmountOfGuestsError('');
        }

        if (tableNumber.trim() === '') {
            setTableNumberError('Tafelnummer is verplicht');
            error = true;
        } else {
            setTableNumberError('');
        }

        if (error) {
            return;
        }
        const formatteddate = new Date().toISOString().split('T')[0];
        props.addVip({
            name: name,
            amount_of_guests: parseInt(amountOfGuests, 10),
            date: formatteddate,
            tablenumber: tableNumber,
            comments: details,
            subsidiary_id: 1,
            is_active: true,
        });

        props.onClose();
    };


    return (
        <div className='add-vip-modal-overlay' onClick={props.onClose}>
            <div className="add-vip-modal-wrapper">
                <div className="add-vip-modal-container" onClick={stopPropagation}>
                    <div className="add-vip-modal-top-area">
                        <div className="add-vip-modal-top-inner-title-area">
                            <h2 className="add-vip-modal-title">Voeg VIP toe aan de briefing</h2>
                        </div>
                        <div className="add-vip-modal-top-inner-close-area">
                            <button className="add-vip-modal-close-btn" onClick={props.onClose} type='button'>
                                <span className="material-symbols-outlined">close</span>
                            </button>
                        </div>
                    </div>
                    <div className="add-vip-modal-content">
                        <div className="add-vip-modal-input-div">
                            <Input className='add-vip-modal-input' type="text" variant="bordered" value={name} onChange={(e) => setName(e.target.value)} label="Naam" errorMessage={nameError} isInvalid={nameError !== ''} />
                        </div>
                        <div className="add-vip-modal-input-div">
                            <Input
                                className='add-vip-modal-input'
                                type="number"
                                value={amountOfGuests}
                                onChange={(e) => {
                                    setAmountOfGuests(e.target.value);
                                }}
                                variant="bordered"
                                label="Aantal gasten"
                                errorMessage={amountOfGuestsError} isInvalid={amountOfGuestsError !== ''}
                            />
                        </div>
                        <div className="add-vip-modal-input-div">
                            <Input value={tableNumber} onChange={(e) => setTableNumber(e.target.value)} className='add-vip-modal-input' type='text' variant="bordered" label="Tafelnummer" errorMessage={tableNumberError} isInvalid={tableNumberError !== ''}/>
                        </div>
                        <div className="add-vip-modal-input-div">
                            <Textarea className='add-vip-modal-input' variant="bordered" label="Bijzonderheden" minRows={10} onChange={(e) => setDetails(e.target.value)} />
                        </div>
                        <div className="add-vip-modal-controls-area">
                            <button className="add-vip-modal-add-btn" type='button' onClick={handleSubmit}>Voeg toe</button>
                            <button className="add-vip-modal-cancel-btn" type='button' onClick={props.onClose}>Annuleren</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddVIPBriefingModal;