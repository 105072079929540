import { Input, Textarea } from '@nextui-org/react';
import React, { useState } from 'react';
import './PlanThingModal.css';
import Calendar from '../../../Calendar/Calendar';
import { getSubsidiary } from '../../../../api/auth/user-info';
import { planActions } from '../../../../api/actions/actionsService';
import { CreateAction } from '../../../../models/Action';

interface PlanThingModalProps {
    thingtype: { id: number; plural: string, singular: string, color: string, };
    thing: { id: number; title: string; description: string; };
    closePlanThingModal: () => void;
}

const PlanThingModal: React.FC<PlanThingModalProps> = (props) => {
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const subsidiary = getSubsidiary();

        selectedDates.forEach((date) => {
            var actions: CreateAction[] = [];
            const newDate = new Date(date).toISOString().slice(0, 10);
            var action = {
                subsidiary_id: subsidiary.id,
                thing_type_id: props.thingtype.id,
                thing_id: props.thing.id,
                title: props.thing.title,
                date: newDate,
                description: props.thing.description,
                day_type: '',
                specific_day: '',
                action_link_uuid: '',
                active_on_each: 0,
                comments: '',
                custom_frequency: '',
                repeat_every: 0,
                repeat_type: '',
                start_date: newDate,
                end_date: newDate,
                sort: 0,
                monthly_days: {},
                weekly_days: {},
                yearly_months: {},
                is_planned: true,
                is_pinned: true,
                completed_on: undefined,
                from_template: false,
            };
            planActions(action);
            actions.push(action);
        });

        props.closePlanThingModal();
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    const [selectedDates, setSelectedDates] = useState<string[]>([]);

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Input className='planthing-title-input' type="title" label="Titel" value={props.thing.title} variant='bordered' readOnly={true} />
                <Textarea
                    readOnly={true}
                    className='planthing-description-input'
                    variant='bordered'
                    label="Beschrijving"
                    value={props.thing.description}
                />
                <div className="planthing-thingtype-showcase-div">
                    <span className="planthing-thingtype-showcase-color-span" style={{ backgroundColor: props.thingtype.color }}>

                    </span>
                    <p className="planthing-thingtype-showcase-type-txt-p">{props.thingtype.singular}</p>
                </div>

                <div className="planthing-datespicker-bar">
                    <Calendar visibleMonths={2} minStartDate={currentDate} isMultiSelect={true} selectedDates={selectedDates} setSelectedDates={setSelectedDates} />
                </div>

                <div className="planthing-controls-div">
                    <button className='planthing-submit-control-btn' type="submit">Plan Thing</button>
                    <button onClick={props.closePlanThingModal} className='planthing-cancel-control-btn' type="button">Annuleren</button>
                </div>
            </form>
        </div>
    );
};

export default PlanThingModal;