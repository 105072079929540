import api from "../auth/api";
import { getUserInfo } from "../auth/user-info";

export const fetchInvoicesByParentCompany = async (page: number, limit: number) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const user = getUserInfo();
        const parent_company_id = user.parent_company_id;
        const skip = (page - 1) * limit;
        const url = `/invoices/by_parent_company_id/${parent_company_id}/?skip=${skip}&limit=${limit}`;
        const response = await api.get(url, config);

        const { total_count, invoices } = response.data;
        const total_pages = Math.ceil(total_count / limit);
        const current_page = page;

        return {
            total_pages,
            current_page,
            invoices
        };
    } catch (error) {
        console.error('Failed to fetch invoices:', error);
        throw error;
    }
}