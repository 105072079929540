// FAQItem.tsx
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './FAQItem.css';

interface FAQItemProps {
    question: string;
    answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="faq-question-bar" onClick={toggleOpen}>
            <div className="faq-question-area">
                <div className="faq-question-icon-area">
                    <motion.span
                        className="faq-question-icon-open-span material-symbols-outlined"
                        animate={{ rotate: isOpen ? 135 : 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        add
                    </motion.span>
                </div>
                <div className="faq-question-txt-area">
                    <h3 className="faq-question-title-h3">{question}</h3>
                </div>
            </div>
            <motion.div
                className="faq-anwser-area"
                initial={{ height: 0, opacity: 0, display: 'none' }}
                animate={{
                    height: isOpen ? 'auto' : 0,
                    opacity: isOpen ? 1 : 0,
                    display: isOpen ? 'block' : 'none'
                }}
                transition={{ duration: 0.1 }}
            >
                <p className="faq-anwser-txt-p">{answer}</p>
            </motion.div>
        </div>
    );
};

export default FAQItem;