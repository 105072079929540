import React, { useEffect } from 'react';
import './ThingsListPreBriefing.css';
import { Action, ActionPreBriefing } from '../../../../../models/Action';
import { ThingType } from '../../../../../models/ThingType';
import ActionItemPreBriefing from './ActionItemPreBriefing/ActionItemPreBriefing';

interface ThingsListPreBriefingProps {
    thingtypes: ThingType[];
    actions: any[];
    activeThingType: ThingType;
    handleThingTypeClick: (index: number) => void;
    handleThingClick: (index: number) => void;
}

const ThingsListPreBriefing: React.FC<ThingsListPreBriefingProps> = (props) => {
    useEffect(() => {
        console.log('Actions prop updated:', props.actions);
    }, [props.actions]);

    const handeClick = (index: number) => {
        console.log('Clicked on thing type:', index);
        props.handleThingTypeClick(index);
    }

    const handleThingClick = (index: number) => {
        console.log('Clicked on thing:', index);
        props.handleThingClick(index);
    }

    return (
        <div id='things-list-pre-briefing-container'>
            {props.thingtypes.map((thingtype, index) => (
                <div key={thingtype.id}
                    className={`thinglist-pre-briefing-type-column ${thingtype.singular.toLowerCase()} ${props.activeThingType.id === thingtype.id && 'active'}`} onClick={() => handeClick(index)}>
                    <div className="thinglist-pre-briefing-inner-container">
                        <h1 className='thingtype-title-h1'>{thingtype.plural}</h1>
                    </div>
                    <ul className="thinglist-pre-briefing-listing-ul">
                        {props.actions.filter((action, index) => action.thing_type_id === thingtype.id && (action.completed_on === undefined || action.completed_on === null)).map((action, index) => (
                            <ActionItemPreBriefing key={index} index={index} action={action} backgroundColor={thingtype.color!} onClick={handleThingClick} />)) }
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default ThingsListPreBriefing;