import { motion } from 'framer-motion';
import React from 'react';
import appdashboard from '../../../../assets/img/tilted-left-10things.png';
import rapportimg from '../../../../assets/img/pdf-rapport-10-things.png';
import apprapport from '../../../../assets/img/rapport-10things-static.png';
import './BriefingSection.css';

interface BriefingSectionProps {
}

const BriefingSection: React.FC<BriefingSectionProps> = (props) => {
    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                delayChildren: 0.4,
                staggerChildren: 0.4
            }
        }
    };

    const textchild = {
        hidden: { y: 100, opacity: 0 },
        show: { y: 0, opacity: 1, duration: 1, transition: { ease: "backInOut", when: "beforeChildren", } },
    };

    const stackImg = {
        hidden: { opacity: 0, scale: 0.2 },
        show: { opacity: 1, scale: 1 },
    };

    return (
        <div className="home-briefing-stack-container" id='briefing'>
            <div className="home-briefing-title-container">
                <motion.h1 className="home-briefing-title-h1" variants={textchild} initial='hidden' whileInView='show' viewport={{ once: true }}>De werkwijze van 10-Things</motion.h1>
            </div>
            <motion.div className="home-briefing-stack-card is-first" id='voorbereiding' variants={container} initial='hidden' whileInView='show' viewport={{ once: true }}>
                <div className="home-briefing-card-text-area">
                    <motion.p className="home-briefing-card-small-top-txt-p" variants={textchild}>Voorbereiding</motion.p>
                    <motion.h1 className="home-briefing-card-title-h1" variants={textchild}>Een goede voorbereiding is het halve werk </motion.h1>
                    <motion.p className="home-briefing-card-txt-p" variants={textchild}>Met 10-Things, maak je het voorbereiden van je briefing efficiënt en doelgericht. Bepaal je focuspunten, selecteer je VIP’s en zorg dat je team perfect voorbereid aan de shift begint. <br /><br /> 10-Things helpt je bij het maken van een gestructureerd plan!  </motion.p>
                </div>
                <div className="home-briefing-mockup-area">
                    {/* TODO: 3d filmpje uitleg inserten */}
                    <motion.img src={apprapport} alt="1-Things Rapport" className="home-briefing-mockup-img" variants={stackImg} />
                </div>
            </motion.div>
            <div className="home-briefing-stack-card is-extra-space">

            </div>
            <motion.div className="home-briefing-stack-card is-second" id='plan' variants={container} initial='hidden' whileInView='show' viewport={{ once: true }}>
                <div className="home-briefing-card-text-area">
                    <motion.p className="home-briefing-card-small-top-txt-p" variants={textchild}>Het plan van de dag</motion.p>
                    <motion.h1 className="home-briefing-card-title-h1" variants={textchild}>Alles wat je nodig hebt voor je dagelijkse briefing </motion.h1>
                    <motion.p className="home-briefing-card-txt-p" variants={textchild}>Met 10-Things, dé briefing app voor de horeca, wordt het geven van een briefing eenvoudig. Het dashboard dient ter ondersteuning voor je team tijdens de shift.<br /><br /> Zorg ervoor dat het dashboard goed zichtbaar is, bijvoorbeeld in de keuken of achter de bar!  </motion.p>
                </div>
                <div className="home-briefing-mockup-area">
                    {/* TODO: insert foto van briefing dashboard */}
                    <motion.img src={appdashboard} alt="1-Things Rapport" className="home-briefing-mockup-img" variants={stackImg} />
                </div>
            </motion.div>
            <div className="home-briefing-stack-card is-extra-space-third">

            </div>
            <motion.div className="home-briefing-stack-card is-third" id='evalueren' variants={container} initial='hidden' whileInView='show' viewport={{ once: true }} >
                <div className="home-briefing-card-text-area">
                    <motion.p className="home-briefing-card-small-top-txt-p" variants={textchild}>Evalueren</motion.p>
                    <motion.h1 className="home-briefing-card-title-h1" variants={textchild}>Het effect van een goede evaluatie aan het eind van de dag </motion.h1>
                    <motion.p className="home-briefing-card-txt-p" variants={textchild}>Met 10-Things zorg je ervoor dat je de de-briefing consequent doet. Om verbeterpunten te identificeren en gericht actie te ondernemen voor continue verbetering. <br /><br /> Breng je team naar het volgende niveau, elke dag opnieuw! </motion.p>
                </div>
                <div className="home-briefing-mockup-area">
                    {/* TODO: insert filmpje debriefing */}
                    <motion.img src={rapportimg} alt="1-Things Rapport" className="home-briefing-mockup-img" variants={stackImg} />
                </div>
            </motion.div>
        </div>
    );
};

export default BriefingSection;