import React from 'react';
import './GreenNoteView.css';
import NoteItemDeBriefing from '../../NoteItemDeBriefing/NoteItemDeBriefing';
import { Note } from '../../../../../../models/Note';

interface GreenNoteViewProps {
    note_type: number;
    greenNoteTxt: string;
    setGreenNoteTxt: (txt: string) => void;
}

const GreenNoteView: React.FC<GreenNoteViewProps> = ({ note_type, greenNoteTxt, setGreenNoteTxt }) => {
    return (
        <div className="green-note-view">
            <h2 className='note-title-h2'>Wat ging goed?</h2>
            <NoteItemDeBriefing note_txt={greenNoteTxt} setNoteTxt={setGreenNoteTxt} note_type={note_type} />
        </div>
    );
};

export default GreenNoteView;