import React, { useEffect, useState } from 'react';
import './MenuUploadModal.css';
import { Select, SelectItem } from '@nextui-org/react';
import { useDropzone } from 'react-dropzone';
import * as pdfjsLib from 'pdfjs-dist';
import { fetchMenuTypes } from '../../../../api/MenuType/MenuTypeServices';
import { MenuType } from '../../../../models/MenuType';
import { Menu, MenuCreate } from '../../../../models/Menu';
import { Currency } from '../../../../enums/Currency';
import { getSubsidiary, getUserInfo } from '../../../../api/auth/user-info';
import { createMenu } from '../../../../api/menu/menuService';
import { uploadMenu } from '../../../../backend/UploadMenu/UploadMenuService';
import { UploadMenuFile } from '../../../../models/UploadMenu';

pdfjsLib.GlobalWorkerOptions.workerSrc = `/assets/pdfLib/pdf.worker.min.mjs`;

interface MenuUploadModalProps {
    onClose: () => void;
    addMenu: (menu: Menu) => void;
}

const MenuUploadModal: React.FC<MenuUploadModalProps> = (props) => {

    const [menuTypes, setMenuTypes] = useState<MenuType[]>([]);
    const [selectedMenuType, setSelectedMenuType] = useState<string>('');

    const changeSelectedMenuType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        console.log('Selected Menu Type:', Number(value));
        setSelectedMenuType(value);
    }

    useEffect(() => {
        const loadMenuTypes = async () => {
            try {
                const response = await fetchMenuTypes();
                setMenuTypes(response);
            } catch (err) {
                console.error('Failed to fetch menu types:', err);
            }
        }
        loadMenuTypes();
    }, []);

    const stopPropagation = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const [file, setFile] = useState<any>(null);
    const [pdfDataUrl, setPdfDataUrl] = useState<string | null>(null);

    const onDrop = (acceptedFiles: any) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setFile(file);
            const reader = new FileReader();
            reader.onload = async () => {
                const typedArray = new Uint8Array(reader.result as ArrayBuffer);
                const pdf = await pdfjsLib.getDocument(typedArray).promise;
                const page = await pdf.getPage(1);
                const viewport = page.getViewport({ scale: 1 });
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                if (context) {
                    await page.render({ canvasContext: context, viewport: viewport }).promise;
                }
                setPdfDataUrl(canvas.toDataURL());
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
        },
        multiple: false,
        noClick: true,
        noKeyboard: true
    });

    const handleSubmit = async () => {
        try {

            const subsidiary = getSubsidiary();
            const user = getUserInfo();
            const menuCreate: MenuCreate = {
                subsidiary_id: subsidiary.id,
                menu_type_id: Number(selectedMenuType),
                currency: Currency.EUROS,
                is_complete: false,
            };

            console.log({
                subsidiary_id: subsidiary.id,
                menu_type_id: Number(selectedMenuType),
                currency: Currency.EUROS,
                is_complete: false,
            });

            const addMenu = await createMenu(menuCreate);
            console.log(addMenu);

            props.addMenu(addMenu);

            const uploadingMenu: UploadMenuFile = {
                subsidiary_id: subsidiary.id,
                user_id: user.id,
                menu_id: addMenu.id,
                file: file // Send the file directly
            };

            console.log('Uploading menu:', uploadingMenu);

            const response = await uploadMenu(uploadingMenu);

            console.log('Upload response:', response);

            if (response) {
                console.log('Menu uploaded successfully');

            } else {
                console.error('Failed to upload menu');
            }

        // const reader = new FileReader();

        // reader.onload = async () => {
        //     try {
        //         console.log('File reading started');
        //         const blob = new Blob([reader.result as ArrayBuffer], { type: file.type });

        //         console.log('File converted to Blob');

        //         const subsidiary = getSubsidiary();
        //         const user = getUserInfo();
        //         const menuCreate: MenuCreate = {
        //             subsidiary_id: subsidiary.id,
        //             menu_type_id: Number(selectedMenuType),
        //             currency: Currency.EUROS,
        //             is_complete: false,
        //         };

        //         console.log({
        //             subsidiary_id: subsidiary.id,
        //             menu_type_id: Number(selectedMenuType),
        //             currency: Currency.EUROS,
        //             is_complete: false,
        //         });

        //         const addMenu = await createMenu(menuCreate);
        //         console.log(addMenu);

        //         const uploadingMenu: UploadMenu = {
        //             subsidiary_id: subsidiary.id,
        //             user_id: user.id,
        //             menu_id: addMenu.id,
        //             file: blob
        //         };

        //         console.log('Uploading menu:', uploadingMenu);

        //         const response = await uploadMenu(uploadingMenu);

        //         console.log('Upload response:', response);

        //         if (response) {
        //             console.log('Menu uploaded successfully');
        //         } else {
        //             console.error('Failed to upload menu');
        //         }
        //     } catch (error) {
        //         console.error('Error during file upload:', error);
        //     }
        // };

        // reader.onerror = (error) => {
        //     console.error('Error reading file:', error);
        // };

        // reader.readAsArrayBuffer(file);
    } catch (error) {
        // Handle the error here or add an empty statement
        console.error('Failed to upload menu');
    } finally {
        props.onClose();
    }
};

return (
    <div className='menuupload-modal-overlay' onClick={props.onClose}>
        <div className="menuupload-modal-wrapper">
            <div className="menuupload-modal-container" onClick={stopPropagation}>
                <div className="menuupload-modal-top-bar-area">
                    <div className="menuupload-modal-title-area-div">
                        <h3 className="menuupload-modal-title-h3">
                            Menu uploaden
                        </h3>
                    </div>
                    <div className="menuupload-modal-close-area-div">
                        <button className="menuupload-modal-close-btn" onClick={props.onClose} type='button'>
                            <span className="menuupload-modal-close-icon-span material-symbols-outlined">close</span>
                        </button>
                    </div>
                </div>
                <div className="menuupload-modal-content-area">
                    <div className="menuupload-modal-upload-area">

                        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                            <input {...getInputProps()} />
                            {
                                file ? (
                                    <div>
                                        <button onClick={() => setFile(null)} className="menuupload-close-button" type='button'>
                                            <span className="menuupload-close-icon-span material-symbols-outlined">close</span>
                                        </button>
                                        {pdfDataUrl && <img src={pdfDataUrl} alt="PDF Thumbnail" width="100%" />}
                                    </div>
                                ) : (
                                    isDragActive ?
                                        <div className="menu-upload-drop-area">
                                            <p className="menu-upload-drop-txt-p">Drop it like it's hot</p>
                                        </div> :
                                        <div className="menu-upload-add-file-inner-area">
                                            <span className="menu-upload-file-icon-span material-symbols-outlined">upload</span>
                                            <p className="menu-upload-add-file-txt-p">Sleep je bestanden hier of <a onClick={open} className='files-browse-a'>blader hier</a></p>
                                        </div>

                                )
                            }
                        </div>
                    </div>
                    <Select
                        variant="bordered"
                        label="Selecteer een menutype"
                        className="max-w select-menutype"
                        value={selectedMenuType ?? ''}
                        onChange={changeSelectedMenuType}
                    >
                        {menuTypes.map((menuType) => (
                            <SelectItem key={menuType.id} value={menuType.id}>
                                {menuType.name}
                            </SelectItem>
                        ))}
                    </Select>

                    <div className="menuupload-modal-controls-area">
                        <button className="menuupload-modal-submit-btn" type='button' onClick={handleSubmit}>
                            Uploaden
                        </button>
                        <button className="menuupload-modal-cancel-btn" type='button' onClick={props.onClose}>
                            Annuleren
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
};

export default MenuUploadModal;