import { UpdateThing } from "../../models/Thing";
import api from "../auth/api";
import { getSubsidiary } from "../auth/user-info";

export const fetchThingsByThingType = async (thingTypeID: number | null) => {
    const subsidiary = getSubsidiary();
    if (!subsidiary) {
        throw new Error('Subsidiary is null');
    }
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/things/get_things_by_subsidiary_and_thing_type/${subsidiary.id}/${thingTypeID}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch things:', error);
        throw error;
    }
};

export const fetchThings = async () => {
    const subsidiary = getSubsidiary();
    if (!subsidiary) {
        throw new Error('Subsidiary is null');
    }
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/things/get_things_by_subsidiary/${subsidiary.id}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch things:', error);
        throw error;
    }
};

export const fetchThingTypes = async () => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/thing_types/`;
        const response = await api.get(url, config);
        console.log(response);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch thing types:', error);
        throw error;
    }
};

export const addThing = async (thing: any) => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };
    try {
        const url = `/things/`;
        const response = await api.post(url, thing, config);
        return response.data;
    } catch (error) {
        console.error('Failed to add thing:', error);
        throw error;
    }
};

export const removeThing = async (thingID: number) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/things/${thingID}/`;
        const response = await api.delete(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to remove thing:', error);
        throw error;
    }
};

export const updateThing = async (thingid: number, thing: UpdateThing) => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    try {
        const url = `/things/${thingid}/`;
        const response = await api.put(url, thing, config);
        return response.data;
    } catch (error) {
        console.error('Failed to update thing:', error);
        throw error;
    }
}
