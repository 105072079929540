import { CreateNote, Note, UpdateNote } from "../../models/Note";
import api from "../auth/api";
import { getSubsidiary } from "../auth/user-info";

export const fetchNotes = async () => {
    const subsidiary_id = getSubsidiary().id;
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/notes/get_notes_by_subsidiary/${subsidiary_id}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch notes:', error);
        throw error;
    }
}

export const updateNoteDB = async (note_id: number, note: UpdateNote) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/notes/${note_id}`;
        const response = await api.put(url, note, config);
        return response.data;
    } catch (error) {
        console.error('Failed to update note:', error);
        throw error;
    }
}

export const deleteNote = async (note_id: number) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/notes/${note_id}/`;
        const response = await api.delete(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to delete note:', error);
        throw error;
    }
}

export const createNote = async (note: CreateNote): Promise<Note> => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/notes/`;
        const response = await api.post(url, note, config);
        return response.data;
    } catch (error) {
        console.error('Failed to create note:', error);
        throw error;
    }
}