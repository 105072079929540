import React, { useEffect, useState } from 'react';
import './BuyCreditsModal.css';
import { Product } from '../../../models/Product';
import { fetchProductByIdentifier } from '../../../api/products/productsService';
import CreditBuyer from './CreditBuyer/CreditBuyer';

interface BuyCreditsModalProps {
    onClose: () => void;
}

const BuyCreditsModal: React.FC<BuyCreditsModalProps> = ({ onClose }) => {

    const [product, setProduct] = useState<Product>();

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    useEffect(() => {
        const loadProduct = async () => {
            try {
                const response = await fetchProductByIdentifier('10THCR');
                setProduct(response);
            } catch (err) {
                console.error('Failed to fetch product:', err);
            } finally {
                console.log('Done fetching product');
            }
        };
        loadProduct();
    }, []);

    return (
        <div className='buycredits-overlay-div' onClick={onClose}>
            <div className="buycredits-wrapper-div">
                <div className="buycredits-inner-div" onClick={stopPropagation}>
                    <div className="buycredits-top-area">
                        <button className="buycredits-close-btn" onClick={onClose}>
                            <span className="material-symbols-outlined">close</span>
                        </button>
                    </div>
                    <div className="buycredits-content-area">
                        <h2 className="buycredits-title">Koop credits voor 10-Things</h2>
                        <p className="buycredits-txt-p">Elk rapport en briefing kost 1 credit. Bij het afsluiten van een abonnement kan je onebeperk rapporten en briefings aanmakent.</p>
                        <div className="creditbuyer-wrapper">
                            { product && <CreditBuyer product={product} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuyCreditsModal;