import React, { useEffect, useState } from 'react';
import './PreBriefingModal.css';
import OverviewStep from './InnerViews/OverviewStep/OverviewStep';
import ThingsStep from './InnerViews/ThingsStep/ThingsStep';
import ConfirmStep from './InnerViews/ConfirmStep/ConfirmStep';
import PDFViewerModal from '../../PDFViewerModal/PDFViewerModal';
import NoticesStep from './InnerViews/NoticesStep/NoticesStep';
import FocusPointsStep from './InnerViews/FocusPointsStep/FocusPointsStep';
import { fetchThingTypes } from '../../../../api/things/thingsService';
import { fetchActionsByDate, fetchActionsBySubsidiaryDateShiftType, planActions } from '../../../../api/actions/actionsService';
import { fetchGeneralLabels, fetchLabelsByBranch } from '../../../../api/labels/labelsService';
import { getSubsidiary } from '../../../../api/auth/user-info';
import { Action, CreateAction } from '../../../../models/Action';
import { Shifts } from '../../../../enums/Shifts';
import { CreateVip, Vip, VipBriefing } from '../../../../models/Vip';
import { addEventDB, fetchEvents } from '../../../../api/events/eventsService';
import { CreateEvent } from '../../../../models/Event';
import { CreateShift, Shift } from '../../../../models/Shift';
import { addShift, generateBriefingShift } from '../../../../api/shift/shiftService';
import { addVipDB, fetchVipsByDateShiftType } from '../../../../api/vip/VipService';
import { NoteTypes } from '../../../../enums/NoteTypes';
import { createNote } from '../../../../api/notes/notesService';
import { ModalOption } from '../../../../models/ModalOption';
import OptionsModal from '../../OptionsModal/OptionsModal';
import DeletePopup from '../../DeletePopup/DeletePopup';
import { fetchRecentDayReport } from '../../../../api/day_reports/day_reportsService';
import { DayReport } from '../../../../models/DayReport';
import { ThingType } from '../../../../models/ThingType';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';

interface PreBriefingModalProps {
    onClose: () => void;
    refreshShift: () => void;
}

const PreBriefingModal: React.FC<PreBriefingModalProps> = (props) => {

    const [recentReport, setRecentReport] = useState<DayReport>();

    useEffect(() => {
        const loadRecentReport = async () => {
            try {
                const report = await fetchRecentDayReport();
                console.log('Report:', report);
                setRecentReport(report);
                setPdfUrl(report?.day_report_src || '');
            } catch (error) {
                console.error('Failed to fetch recent report:', error);
            } finally {
                console.log('Done fetching recent report');
            }
        }

        loadRecentReport();

    }, []);

    const [pdfUrl, setPdfUrl] = useState<string>(recentReport?.day_report_src || '');
    const [pdfModalOpen, setPdfModalOpen] = useState<boolean>(false);

    const [manager, setManager] = useState<string>('');
    const [shift, setShift] = useState<string>('');

    const [managerError, setManagerError] = useState<string>('');
    const [shiftError, setShiftError] = useState<string>('');

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };
    const [date, setDate] = React.useState<Date>(new Date());
    const formattedDate = date.toISOString().slice(0, 10);
    const [actions, setActions] = React.useState<any[]>([]);

    useEffect(() => {
        const loadActions = async () => {
            try {
                const actions = await fetchActionsBySubsidiaryDateShiftType(formattedDate, Shifts[Number(shift)]);
                setActions(actions);
            } catch (err) {
                console.error('Failed to fetch actions:', err);
            } finally {
                console.log('Done fetching actions');
            }
        };
        if (shift !== '') {
            loadActions();
        }
    }, [shift]);

    const [step, setStep] = useState<number>(1);

    const prevStep = () => {
        setStep(step - 1);
    };

    const nextStep = () => {
        if (validateStep(step)) {
            setStep(step + 1);
        }
    };

    const handleClosePDFModal = () => {
        setPdfModalOpen(false);
    };

    const OverViewStepProps = {
        setPdfModalOpen,
        manager,
        setManager,
        managerError,
        shift,
        setShift,
        shiftError,
        pdfUrl
    };

    const [thingTypes, setThingTypes] = React.useState<any[]>([]);

    useEffect(() => {
        const loadThingTypes = async () => {
            try {
                const thingTypes = await fetchThingTypes();
                setThingTypes(thingTypes);
                console.log('thingTypes:', thingTypes);
            } catch (err) {
                console.error('Failed to fetch thing types:', err);
            } finally {
                console.log('Done fetching thing types');
            }
        };

        loadThingTypes();
    }, []);

    const [selectedLabelIds, setSelectedLabelIds] = useState<number[]>([]);
    const [selectedThingIdsError, setSelectedThingIdsError] = useState<string>('');
    const subsidiary = getSubsidiary();

    const selectedLabelsArr: any = JSON.parse(subsidiary.selected_labels);
    const [labels, setLabels] = React.useState<any[]>([]);
    useEffect(() => {
        const loadLabels = async () => {
            try {
                const [generalLabels, labelsByBranch] = await Promise.all([
                    fetchGeneralLabels(),
                    fetchLabelsByBranch(subsidiary.branch_id),
                ]);

                const selectedLabelsArr: any = JSON.parse(subsidiary.selected_labels);

                const filteredLabels = [...generalLabels, ...labelsByBranch].filter(label =>
                    selectedLabelsArr.includes(label.id)
                );

                setLabels(filteredLabels);
            } catch (error) {
                console.error('Error fetching general labels:', error);
            } finally {
                console.log('Done fetching general labels');
            }
        };

        loadLabels();
    }, [subsidiary.branch_id]);

    const [vips, setVips] = useState<VipBriefing[]>([]);
    const [addedVips, setAddedVips] = useState<VipBriefing[]>([]);

    const addVip = (vip: VipBriefing) => {
        console.log(vip);
        setAddedVips([...addedVips, vip]);
        setVips([...vips, vip]);
        console.log(vips);
    }

    const deleteVip = (vip: VipBriefing) => {
        console.log(vip);

        const vipsIndex = vips.findIndex(v => v === vip);
        if (vipsIndex !== -1) {
            const newVips = [...vips];
            newVips.splice(vipsIndex, 1);
            setVips(newVips);
        }

        const addedVipsIndex = addedVips.findIndex(v => v === vip);
        if (addedVipsIndex !== -1) {
            const newAddedVips = [...addedVips];
            newAddedVips.splice(addedVipsIndex, 1);
            setAddedVips(newAddedVips);
        }
    }

    // Events pre-briefing
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const [events, setEvents] = useState<CreateEvent[]>([]);
    const [addedEvents, setAddedEvents] = useState<CreateEvent[]>([]);

    const addEvent = async (event: CreateEvent) => {
        console.log('Event:', event);
        setAddedEvents([...addedEvents, event]);
        setEvents([...events, event]);
    }

    useEffect(() => {
        const loadEvents = async () => {
            try {
                const events = await fetchEvents();
                setEvents(events);
            } catch (error) {
                console.error('Failed to fetch events:', error);
            } finally {
                console.log('Events loaded');
            }
        }
        loadEvents();
    }, []);

    const [todayEvents, setTodayEvents] = useState<CreateEvent[]>([]);

    useEffect(() => {
        const currentEvents = events.filter((event) => {
            const eventDate = new Date(event.date!);
            eventDate.setHours(0, 0, 0, 0);
            return eventDate.getTime() === currentDate.getTime();
        }).sort((a, b) => new Date(a.date!).getTime() - new Date(b.date!).getTime())
            .slice(0, 100);

        setTodayEvents(currentEvents);
    }, [events]);


    const validateStep = (currentStep: number): boolean => {
        switch (currentStep) {
            case 1:
                let noError = true;
                if (manager.trim() === '') {
                    setManagerError('Naam leidinggevende is verplicht');
                    noError = false;
                } else {
                    setManagerError('');
                }

                if (shift.trim() === '') {
                    setShiftError('Shift is verplicht');
                    noError = false;
                } else {
                    setShiftError('');
                }

                return noError; // Example: always passing validation for step 1
            case 2:
                // Add validation logic for step 2
                return true; // Example: always passing validation for step 2
            case 3:
                // Focuspoints

                if (selectedLabelIds.length === 0) {
                    setSelectedThingIdsError('Selecteer minimaal 1 focuspunt');
                    return false;
                }
                return true;
            case 4:
                // Add validation logic for step 2
                return true;
            case 5:
                // Add validation logic for step 2
                return true;
            // Add more cases for additional steps
            default:
                return false; // Default to failing validation if step is unknown
        }
    };
    const [shiftResponse, setShiftResponse] = useState<Shift>();

    const handleSubmit = async () => {
        // props.onClose();

        setLoading(true);

        console.log('Submitted actions', actions);

        const actionsWithID = actions.filter(action => action.id !== 0);
        const actionsWithoutID = actions.filter(action => action.id === 0);

        const actionsResponse: Action[] = [];

        console.log(shift);

        const correctShiftValue = Shifts[Number(shift)];
        const correctShift = Shifts[correctShiftValue.toUpperCase() as keyof typeof Shifts];

        console.log(correctShift);

        const subsidiary = getSubsidiary();

        try {
            const shift: CreateShift = {
                shift_type: correctShift,
                date: formattedDate,
                subsidiary_id: subsidiary.id,
                manager: manager,
                is_active: true,
            };
            const addShiftResponse = await addShift(shift);
            console.log('Shift response:', addShiftResponse);
            actionsWithoutID.forEach(async action => {
                const newAction: CreateAction = {
                    thing_type_id: action.thing_type_id,
                    thing_id: action.thing_id,
                    title: action.title,
                    description: action.description,
                    date: action.date,
                    is_planned: action.is_planned,
                    is_pinned: action.is_pinned,
                    from_template: action.from_template,
                    subsidiary_id: action.subsidiary_id,
                    shift_type: correctShift,
                    shift_id: addShiftResponse.id,
                };

                const response = await planActions(newAction);
                console.log('Response:', response);
                actionsResponse.push(response);
            });

            addedEvents.forEach(async event => {
                const response = await addEventDB(event);
                console.log('Response:', response);
            }
            );

            addedVips.forEach(async vip => {
                const addingVip: CreateVip = {
                    name: vip.name,
                    amount_of_guests: vip.amount_of_guests,
                    comments: vip.comments,
                    tablenumber: vip.tablenumber,
                    subsidiary_id: subsidiary.id,
                    date: formattedDate,
                    is_active: true,
                    shift_id: addShiftResponse.id,
                };
                const response = await addVipDB(addingVip);
                console.log('Response:', response);
            });

            for (const key of Object.keys(NoteTypes)) {
                const value = NoteTypes[key as keyof typeof NoteTypes];
                console.log(`Key: ${key}, Value: ${value}`);
                try {
                    const responseAddingNote = await createNote({
                        note_txt: '',
                        note_type: value,
                        subsidiary_id: subsidiary.id,
                        shift_id: addShiftResponse.id,
                    });

                    console.log('Response:', responseAddingNote);
                } catch (error) {
                    console.error('Failed to add note:', error);
                } finally {
                    console.log('Done adding note');
                }
            }

            await generateBriefingShift(addShiftResponse.id);
        } catch (error) {
            setLoading(false);
            console.error('Failed to add shift:', error);
        } finally {
            setLoading(false);
            console.log('Done adding shift and briefing');
        }
        props.refreshShift();
        // props.addActionsDashboard(actionsResponse);
        props.onClose();
    };

    const [openVipOptions, setOpenVipOptions] = useState<boolean>(false);
    const [vipOptionsX, setVipOptionsX] = useState<number>(0);
    const [vipOptionsY, setVipOptionsY] = useState<number>(0);
    const [selectedVipOptions, setSelectedVipOptions] = useState<VipBriefing | Vip>();

    const handleVipDelete = () => {
        console.log('Delete vip:', selectedVipOptions);
        setOpenVipOptions(false);
        setOpenDeletePopup(true);
        setDeletePopupTitle('Verwijder VIP');
        setDeletePopupMessage('Weet je zeker dat je deze VIP wilt verwijderen?');
        setDeletePopupAction(() => {
            if (selectedVipOptions) {
                if ('amount_of_guests' in selectedVipOptions) {
                    deleteVip(selectedVipOptions);
                } else {
                    console.error('Vip is not a briefing vip');
                }
            }
            setOpenDeletePopup(false);
        });
    }

    const options: ModalOption[] = [
        {
            label: 'Verwijder',
            icon: 'delete',
            action: handleVipDelete,
            class: 'delete'
        }
    ];

    const onVipOptionsClick = (event: React.MouseEvent, vip: VipBriefing | Vip) => {
        event.stopPropagation();
        const rect = (event.target as HTMLElement).getBoundingClientRect();
        setVipOptionsX(rect.left);
        setVipOptionsY(rect.bottom);
        setOpenVipOptions(true);
        setSelectedVipOptions(vip);
    };

    const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
    const [deletePopupTitle, setDeletePopupTitle] = useState<string>('');
    const [deletePopupMessage, setDeletePopupMessage] = useState<string>('');
    const [deletePopupAction, setDeletePopupAction] = useState<() => void>(() => { });

    const [activeThingIndex, setActiveThingIndex] = React.useState<number>(0);

    const [loading, setLoading] = React.useState<boolean>(false);

    return (
        <div className="pre-briefing-modal-overlay">
            {loading && <LoadingOverlay />}
            {pdfModalOpen && <PDFViewerModal url={pdfUrl} onClose={handleClosePDFModal} onClick={stopPropagation} />}
            <div className="pre-briefing-modal-wrapper">
                <div className="pre-briefing-modal-container" onClick={stopPropagation}>
                    {openDeletePopup && <DeletePopup title={deletePopupTitle} text={deletePopupMessage} onDelete={deletePopupAction} onClose={() => setOpenDeletePopup(false)} />}
                    {openVipOptions && <OptionsModal options={options} x={vipOptionsX} y={vipOptionsY} onClose={() => setOpenVipOptions(false)} />}
                    <div className="pre-briefing-top-bar">
                        <div className="pre-briefing-right-area">
                            <h2 className="pre-briefing-title">Pre-briefing</h2>
                        </div>
                        <div className="pre-briefing-middle-area">
                            {/* <RowSteps
                                className="max-w-md"
                                defaultStep={2}
                                steps={[
                                    {
                                        title: "Create",
                                    },
                                    {
                                        title: "Review",
                                    },
                                    {
                                        title: "Publish",
                                    },
                                ]}
                            /> */}
                        </div>
                        <div className="pre-briefing-left-area">
                            <button className="pre-briefing-close-btn" onClick={props.onClose}> 
                                <span className="material-symbols-rounded close-icon" translate='no'>close</span>
                            </button>
                        </div>
                    </div>
                    <div className="pre-briefing-content-area">
                        {step === 1 && <OverviewStep {...OverViewStepProps} />}
                        {step === 2 && <NoticesStep shift={shift} vips={vips} addVip={addVip} todayEvents={todayEvents} addEvent={addEvent} onVipOptionsClick={onVipOptionsClick} />}
                        {step === 3 && <FocusPointsStep selectedLabelIds={selectedLabelIds} setSelectedLabelIds={setSelectedLabelIds} selectedThingIdsError={selectedThingIdsError} labels={labels} />}
                        {step === 4 && <ThingsStep thingTypes={thingTypes} selectedLabelIds={selectedLabelIds} actions={actions} setActions={setActions} activeThingIndex={activeThingIndex} setActiveThingIndex={setActiveThingIndex} />}
                        {step === 5 && <ConfirmStep />}
                        <div className="pre-briefing-controls-area">
                            <div className="form-control-buttons">
                                {actions.length == 10 && <button className='half-submit-buttons' onClick={handleSubmit} type='button'>Voltooien</button>}
                                {actions.length < 10 && step > 1 && <button className='half-submit-buttons' onClick={prevStep} type='button'><span className="material-symbols-rounded submit-icon" translate='no'>arrow_back</span></button>}
                                {actions.length < 10 && step < 4 && <button className='half-submit-buttons' onClick={nextStep} type='button'><span className="material-symbols-rounded submit-icon" translate='no'>arrow_forward</span></button>}
                                {/* {step === 5 && <button className='half-submit-buttons' onClick={handleSubmit} type='button'>Voltooien</button>} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreBriefingModal;