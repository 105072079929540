import { CreateDayReport, DayReport } from "../../models/DayReport";
import { CreateReportNote, ReportNote } from "../../models/ReportNote";
import { decreateCredits } from "../../utils/creditUtils";
import api from "../auth/api";
import { getSubsidiary } from "../auth/user-info";

export const fetchReportKPIs = async () => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/report_kpis/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch report kpis:', error);
        throw error;
    }
}

export const checkDayReport = async (date: string) => {
    const subsidiary_id = getSubsidiary().id;
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/day_reports/get_day_reports_by_subsidiary_and_date/${subsidiary_id}/${date}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to check day report:', error);
        throw error;
    }
};

export const addReport = async (report: CreateDayReport) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/day_reports/`;
        const response = await api.post(url, report , config);
        return response.data;
    } catch (error) {
        console.error('Failed to send report:', error);
        throw error;
    }
}

export const addReportNotes = async (note: CreateReportNote) => {
    const config = {	
        headers: {	
            'Accept': 'application/json',	
        }	
    };	
    try {	
        const url = `/report_notes/`;
        const response = await api.post(url, note, config);	
        return response.data;	
    } catch (error) {	
        console.error('Failed to send report notes:', error);	
        throw error;	
    }	
}


export const addReportThings = async (thing: any) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/report_things/`;
        const response = await api.post(url, thing, config);
        return response.data;
    } catch (error) {
        console.error('Failed to send report things:', error);
        throw error;
    }
}

export const generateReport = async (id: number) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/day_reports/${id}/generate_pdf/`;
        const response = await api.get(url, config);
        await decreateCredits();
        return response.data;
    } catch (error) {
        console.error('Failed to generate report:', error);
        throw error;
    }
}

export const fetchRecentDayReport = async () => {
    const subsidiary_id = getSubsidiary().id;
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/day_reports/recent_day_report_by_subsidiary/${subsidiary_id}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch recent day report:', error);
        throw error;
    }
}

export const fetchDayReportsBySubsidiary = async () => {
    const subsidiary_id = getSubsidiary().id;
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/day_reports/get_day_reports_by_subsidiary/${subsidiary_id}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch day reports by subsidiary:', error);
        throw error;
    }
}